/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { InputCreateCustomEarningSettingDto } from '../models/input-create-custom-earning-setting-dto';
import { InputCreateMerchantDto } from '../models/input-create-merchant-dto';
import { InputCreateRoleDto } from '../models/input-create-role-dto';
import { InputDeleteMerchantDto } from '../models/input-delete-merchant-dto';
import { InputMerchantWithdrawalChangeStatusForAdminDto } from '../models/input-merchant-withdrawal-change-status-for-admin-dto';
import { InputUpdateMerchantDto } from '../models/input-update-merchant-dto';
import { InputUpdateMerchantEarningSettingDto } from '../models/input-update-merchant-earning-setting-dto';
import { InputUpdateRoleDto } from '../models/input-update-role-dto';
import { InputUpdateRoleWithPermissionsDto } from '../models/input-update-role-with-permissions-dto';
import { OutPutGetCustomBrandEarningSettingDto } from '../models/out-put-get-custom-brand-earning-setting-dto';
import { OutPutGetCustomEarningSettingDto } from '../models/out-put-get-custom-earning-setting-dto';
import { OutputAllMenuPagesPermissionDto } from '../models/output-all-menu-pages-permission-dto';
import { OutputAllRolesDto } from '../models/output-all-roles-dto';
import { OutputGetAllBrandsDto } from '../models/output-get-all-brands-dto';
import { OutputGetAllCompanysDto } from '../models/output-get-all-companys-dto';
import { OutputGetAllMerchantForTableDto } from '../models/output-get-all-merchant-for-table-dto';
import { OutputMerchantEarningSettingDto } from '../models/output-merchant-earning-setting-dto';
import { OutputMerchantWithdrawalRequestForAdminDto } from '../models/output-merchant-withdrawal-request-for-admin-dto';

@Injectable({
  providedIn: 'root',
})
export class MerchantManagementService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation merchantManagementGetAllMerchant
   */
  static readonly MerchantManagementGetAllMerchantPath = '/api/Merchant/MerchantManagement/GetAllMerchant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementGetAllMerchant$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetAllMerchant$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllMerchantForTableDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantManagementService.MerchantManagementGetAllMerchantPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllMerchantForTableDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantManagementGetAllMerchant$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetAllMerchant$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllMerchantForTableDto>> {

    return this.merchantManagementGetAllMerchant$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllMerchantForTableDto>>) => r.body as Array<OutputGetAllMerchantForTableDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementGetAllMerchant()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetAllMerchant$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllMerchantForTableDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantManagementService.MerchantManagementGetAllMerchantPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllMerchantForTableDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantManagementGetAllMerchant$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetAllMerchant(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllMerchantForTableDto>> {

    return this.merchantManagementGetAllMerchant$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllMerchantForTableDto>>) => r.body as Array<OutputGetAllMerchantForTableDto>)
    );
  }

  /**
   * Path part for operation merchantManagementGetMerchantCompany
   */
  static readonly MerchantManagementGetMerchantCompanyPath = '/api/Merchant/MerchantManagement/GetMerchantCompany';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementGetMerchantCompany$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetMerchantCompany$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetAllCompanysDto>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantManagementService.MerchantManagementGetMerchantCompanyPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetAllCompanysDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantManagementGetMerchantCompany$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetMerchantCompany$Plain(params?: {
    context?: HttpContext
  }
): Observable<OutputGetAllCompanysDto> {

    return this.merchantManagementGetMerchantCompany$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetAllCompanysDto>) => r.body as OutputGetAllCompanysDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementGetMerchantCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetMerchantCompany$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetAllCompanysDto>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantManagementService.MerchantManagementGetMerchantCompanyPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetAllCompanysDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantManagementGetMerchantCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetMerchantCompany(params?: {
    context?: HttpContext
  }
): Observable<OutputGetAllCompanysDto> {

    return this.merchantManagementGetMerchantCompany$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetAllCompanysDto>) => r.body as OutputGetAllCompanysDto)
    );
  }

  /**
   * Path part for operation merchantManagementAddMerchant
   */
  static readonly MerchantManagementAddMerchantPath = '/api/Merchant/MerchantManagement/AddMerchant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementAddMerchant$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementAddMerchant$Plain$Response(params?: {
    context?: HttpContext
    body?: InputCreateMerchantDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantManagementService.MerchantManagementAddMerchantPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantManagementAddMerchant$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementAddMerchant$Plain(params?: {
    context?: HttpContext
    body?: InputCreateMerchantDto
  }
): Observable<boolean> {

    return this.merchantManagementAddMerchant$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementAddMerchant()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementAddMerchant$Response(params?: {
    context?: HttpContext
    body?: InputCreateMerchantDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantManagementService.MerchantManagementAddMerchantPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantManagementAddMerchant$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementAddMerchant(params?: {
    context?: HttpContext
    body?: InputCreateMerchantDto
  }
): Observable<boolean> {

    return this.merchantManagementAddMerchant$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation merchantManagementUpdateMerchant
   */
  static readonly MerchantManagementUpdateMerchantPath = '/api/Merchant/MerchantManagement/UpdateMerchant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementUpdateMerchant$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementUpdateMerchant$Plain$Response(params?: {
    context?: HttpContext
    body?: InputUpdateMerchantDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantManagementService.MerchantManagementUpdateMerchantPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantManagementUpdateMerchant$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementUpdateMerchant$Plain(params?: {
    context?: HttpContext
    body?: InputUpdateMerchantDto
  }
): Observable<boolean> {

    return this.merchantManagementUpdateMerchant$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementUpdateMerchant()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementUpdateMerchant$Response(params?: {
    context?: HttpContext
    body?: InputUpdateMerchantDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantManagementService.MerchantManagementUpdateMerchantPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantManagementUpdateMerchant$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementUpdateMerchant(params?: {
    context?: HttpContext
    body?: InputUpdateMerchantDto
  }
): Observable<boolean> {

    return this.merchantManagementUpdateMerchant$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation merchantManagementDeleteMerchant
   */
  static readonly MerchantManagementDeleteMerchantPath = '/api/Merchant/MerchantManagement/DeleteMerchant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementDeleteMerchant$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementDeleteMerchant$Plain$Response(params?: {
    context?: HttpContext
    body?: InputDeleteMerchantDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantManagementService.MerchantManagementDeleteMerchantPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantManagementDeleteMerchant$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementDeleteMerchant$Plain(params?: {
    context?: HttpContext
    body?: InputDeleteMerchantDto
  }
): Observable<boolean> {

    return this.merchantManagementDeleteMerchant$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementDeleteMerchant()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementDeleteMerchant$Response(params?: {
    context?: HttpContext
    body?: InputDeleteMerchantDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantManagementService.MerchantManagementDeleteMerchantPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantManagementDeleteMerchant$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementDeleteMerchant(params?: {
    context?: HttpContext
    body?: InputDeleteMerchantDto
  }
): Observable<boolean> {

    return this.merchantManagementDeleteMerchant$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation merchantManagementGetAllRolesForMerchant
   */
  static readonly MerchantManagementGetAllRolesForMerchantPath = '/api/Merchant/MerchantManagement/GetAllRolesForMerchant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementGetAllRolesForMerchant$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetAllRolesForMerchant$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputAllRolesDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantManagementService.MerchantManagementGetAllRolesForMerchantPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputAllRolesDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantManagementGetAllRolesForMerchant$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetAllRolesForMerchant$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputAllRolesDto>> {

    return this.merchantManagementGetAllRolesForMerchant$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputAllRolesDto>>) => r.body as Array<OutputAllRolesDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementGetAllRolesForMerchant()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetAllRolesForMerchant$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputAllRolesDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantManagementService.MerchantManagementGetAllRolesForMerchantPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputAllRolesDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantManagementGetAllRolesForMerchant$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetAllRolesForMerchant(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputAllRolesDto>> {

    return this.merchantManagementGetAllRolesForMerchant$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputAllRolesDto>>) => r.body as Array<OutputAllRolesDto>)
    );
  }

  /**
   * Path part for operation merchantManagementCreateRoleForMerchant
   */
  static readonly MerchantManagementCreateRoleForMerchantPath = '/api/Merchant/MerchantManagement/CreateRoleForMerchant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementCreateRoleForMerchant()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementCreateRoleForMerchant$Response(params?: {
    context?: HttpContext
    body?: InputCreateRoleDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantManagementService.MerchantManagementCreateRoleForMerchantPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantManagementCreateRoleForMerchant$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementCreateRoleForMerchant(params?: {
    context?: HttpContext
    body?: InputCreateRoleDto
  }
): Observable<void> {

    return this.merchantManagementCreateRoleForMerchant$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation merchantManagementDeleteRoleForMerchant
   */
  static readonly MerchantManagementDeleteRoleForMerchantPath = '/api/Merchant/MerchantManagement/DeleteRoleForMerchant';

  /**
   * DeleteRole.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementDeleteRoleForMerchant$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementDeleteRoleForMerchant$Plain$Response(params?: {
    roleId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantManagementService.MerchantManagementDeleteRoleForMerchantPath, 'post');
    if (params) {
      rb.query('roleId', params.roleId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * DeleteRole.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantManagementDeleteRoleForMerchant$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementDeleteRoleForMerchant$Plain(params?: {
    roleId?: number;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.merchantManagementDeleteRoleForMerchant$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * DeleteRole.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementDeleteRoleForMerchant()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementDeleteRoleForMerchant$Response(params?: {
    roleId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantManagementService.MerchantManagementDeleteRoleForMerchantPath, 'post');
    if (params) {
      rb.query('roleId', params.roleId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * DeleteRole.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantManagementDeleteRoleForMerchant$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementDeleteRoleForMerchant(params?: {
    roleId?: number;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.merchantManagementDeleteRoleForMerchant$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation merchantManagementUpdateRoleForMerchant
   */
  static readonly MerchantManagementUpdateRoleForMerchantPath = '/api/Merchant/MerchantManagement/UpdateRoleForMerchant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementUpdateRoleForMerchant()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementUpdateRoleForMerchant$Response(params?: {
    context?: HttpContext
    body?: InputUpdateRoleDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantManagementService.MerchantManagementUpdateRoleForMerchantPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantManagementUpdateRoleForMerchant$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementUpdateRoleForMerchant(params?: {
    context?: HttpContext
    body?: InputUpdateRoleDto
  }
): Observable<void> {

    return this.merchantManagementUpdateRoleForMerchant$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation merchantManagementUpdateRoleWithPermissionsForMerchant
   */
  static readonly MerchantManagementUpdateRoleWithPermissionsForMerchantPath = '/api/Merchant/MerchantManagement/UpdateRoleWithPermissionsForMerchant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementUpdateRoleWithPermissionsForMerchant()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementUpdateRoleWithPermissionsForMerchant$Response(params?: {
    context?: HttpContext
    body?: InputUpdateRoleWithPermissionsDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantManagementService.MerchantManagementUpdateRoleWithPermissionsForMerchantPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantManagementUpdateRoleWithPermissionsForMerchant$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementUpdateRoleWithPermissionsForMerchant(params?: {
    context?: HttpContext
    body?: InputUpdateRoleWithPermissionsDto
  }
): Observable<void> {

    return this.merchantManagementUpdateRoleWithPermissionsForMerchant$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation merchantManagementGetMenusPagesPermissionForMerchantByRoleId
   */
  static readonly MerchantManagementGetMenusPagesPermissionForMerchantByRoleIdPath = '/api/Merchant/MerchantManagement/GetMenusPagesPermissionForMerchantByRoleId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementGetMenusPagesPermissionForMerchantByRoleId$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetMenusPagesPermissionForMerchantByRoleId$Plain$Response(params?: {
    roleId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputAllMenuPagesPermissionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantManagementService.MerchantManagementGetMenusPagesPermissionForMerchantByRoleIdPath, 'get');
    if (params) {
      rb.query('roleId', params.roleId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputAllMenuPagesPermissionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantManagementGetMenusPagesPermissionForMerchantByRoleId$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetMenusPagesPermissionForMerchantByRoleId$Plain(params?: {
    roleId?: number;
    context?: HttpContext
  }
): Observable<Array<OutputAllMenuPagesPermissionDto>> {

    return this.merchantManagementGetMenusPagesPermissionForMerchantByRoleId$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputAllMenuPagesPermissionDto>>) => r.body as Array<OutputAllMenuPagesPermissionDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementGetMenusPagesPermissionForMerchantByRoleId()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetMenusPagesPermissionForMerchantByRoleId$Response(params?: {
    roleId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputAllMenuPagesPermissionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantManagementService.MerchantManagementGetMenusPagesPermissionForMerchantByRoleIdPath, 'get');
    if (params) {
      rb.query('roleId', params.roleId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputAllMenuPagesPermissionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantManagementGetMenusPagesPermissionForMerchantByRoleId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetMenusPagesPermissionForMerchantByRoleId(params?: {
    roleId?: number;
    context?: HttpContext
  }
): Observable<Array<OutputAllMenuPagesPermissionDto>> {

    return this.merchantManagementGetMenusPagesPermissionForMerchantByRoleId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputAllMenuPagesPermissionDto>>) => r.body as Array<OutputAllMenuPagesPermissionDto>)
    );
  }

  /**
   * Path part for operation merchantManagementGetMenusPagesPermissionsForMerchant
   */
  static readonly MerchantManagementGetMenusPagesPermissionsForMerchantPath = '/api/Merchant/MerchantManagement/GetMenusPagesPermissionsForMerchant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementGetMenusPagesPermissionsForMerchant$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetMenusPagesPermissionsForMerchant$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputAllMenuPagesPermissionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantManagementService.MerchantManagementGetMenusPagesPermissionsForMerchantPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputAllMenuPagesPermissionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantManagementGetMenusPagesPermissionsForMerchant$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetMenusPagesPermissionsForMerchant$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputAllMenuPagesPermissionDto>> {

    return this.merchantManagementGetMenusPagesPermissionsForMerchant$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputAllMenuPagesPermissionDto>>) => r.body as Array<OutputAllMenuPagesPermissionDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementGetMenusPagesPermissionsForMerchant()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetMenusPagesPermissionsForMerchant$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputAllMenuPagesPermissionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantManagementService.MerchantManagementGetMenusPagesPermissionsForMerchantPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputAllMenuPagesPermissionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantManagementGetMenusPagesPermissionsForMerchant$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetMenusPagesPermissionsForMerchant(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputAllMenuPagesPermissionDto>> {

    return this.merchantManagementGetMenusPagesPermissionsForMerchant$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputAllMenuPagesPermissionDto>>) => r.body as Array<OutputAllMenuPagesPermissionDto>)
    );
  }

  /**
   * Path part for operation merchantManagementUpdateMerchantEarningSetting
   */
  static readonly MerchantManagementUpdateMerchantEarningSettingPath = '/api/admin/MerchantManagement/UpdateMerchantEarningSetting';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementUpdateMerchantEarningSetting$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementUpdateMerchantEarningSetting$Plain$Response(params?: {
    context?: HttpContext
    body?: Array<InputUpdateMerchantEarningSettingDto>
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantManagementService.MerchantManagementUpdateMerchantEarningSettingPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantManagementUpdateMerchantEarningSetting$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementUpdateMerchantEarningSetting$Plain(params?: {
    context?: HttpContext
    body?: Array<InputUpdateMerchantEarningSettingDto>
  }
): Observable<boolean> {

    return this.merchantManagementUpdateMerchantEarningSetting$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementUpdateMerchantEarningSetting()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementUpdateMerchantEarningSetting$Response(params?: {
    context?: HttpContext
    body?: Array<InputUpdateMerchantEarningSettingDto>
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantManagementService.MerchantManagementUpdateMerchantEarningSettingPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantManagementUpdateMerchantEarningSetting$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementUpdateMerchantEarningSetting(params?: {
    context?: HttpContext
    body?: Array<InputUpdateMerchantEarningSettingDto>
  }
): Observable<boolean> {

    return this.merchantManagementUpdateMerchantEarningSetting$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation merchantManagementGetAllMerchantEarningSetting
   */
  static readonly MerchantManagementGetAllMerchantEarningSettingPath = '/api/admin/MerchantManagement/GetAllMerchantEarningSetting';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementGetAllMerchantEarningSetting$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetAllMerchantEarningSetting$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputMerchantEarningSettingDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantManagementService.MerchantManagementGetAllMerchantEarningSettingPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputMerchantEarningSettingDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantManagementGetAllMerchantEarningSetting$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetAllMerchantEarningSetting$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputMerchantEarningSettingDto>> {

    return this.merchantManagementGetAllMerchantEarningSetting$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputMerchantEarningSettingDto>>) => r.body as Array<OutputMerchantEarningSettingDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementGetAllMerchantEarningSetting()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetAllMerchantEarningSetting$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputMerchantEarningSettingDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantManagementService.MerchantManagementGetAllMerchantEarningSettingPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputMerchantEarningSettingDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantManagementGetAllMerchantEarningSetting$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetAllMerchantEarningSetting(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputMerchantEarningSettingDto>> {

    return this.merchantManagementGetAllMerchantEarningSetting$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputMerchantEarningSettingDto>>) => r.body as Array<OutputMerchantEarningSettingDto>)
    );
  }

  /**
   * Path part for operation merchantManagementGetAllCompaniesBrand
   */
  static readonly MerchantManagementGetAllCompaniesBrandPath = '/api/admin/MerchantManagement/GetAllCompaniesBrand';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementGetAllCompaniesBrand$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetAllCompaniesBrand$Plain$Response(params?: {
    companyId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllBrandsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantManagementService.MerchantManagementGetAllCompaniesBrandPath, 'get');
    if (params) {
      rb.query('companyId', params.companyId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllBrandsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantManagementGetAllCompaniesBrand$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetAllCompaniesBrand$Plain(params?: {
    companyId?: number;
    context?: HttpContext
  }
): Observable<Array<OutputGetAllBrandsDto>> {

    return this.merchantManagementGetAllCompaniesBrand$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllBrandsDto>>) => r.body as Array<OutputGetAllBrandsDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementGetAllCompaniesBrand()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetAllCompaniesBrand$Response(params?: {
    companyId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllBrandsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantManagementService.MerchantManagementGetAllCompaniesBrandPath, 'get');
    if (params) {
      rb.query('companyId', params.companyId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllBrandsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantManagementGetAllCompaniesBrand$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetAllCompaniesBrand(params?: {
    companyId?: number;
    context?: HttpContext
  }
): Observable<Array<OutputGetAllBrandsDto>> {

    return this.merchantManagementGetAllCompaniesBrand$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllBrandsDto>>) => r.body as Array<OutputGetAllBrandsDto>)
    );
  }

  /**
   * Path part for operation merchantManagementGetAllMerchantWithdrawalrequestsForAdmin
   */
  static readonly MerchantManagementGetAllMerchantWithdrawalrequestsForAdminPath = '/api/admin/MerchantManagement/GetAllMerchantWithdrawalrequestsForAdmin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementGetAllMerchantWithdrawalrequestsForAdmin$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetAllMerchantWithdrawalrequestsForAdmin$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputMerchantWithdrawalRequestForAdminDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantManagementService.MerchantManagementGetAllMerchantWithdrawalrequestsForAdminPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputMerchantWithdrawalRequestForAdminDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantManagementGetAllMerchantWithdrawalrequestsForAdmin$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetAllMerchantWithdrawalrequestsForAdmin$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputMerchantWithdrawalRequestForAdminDto>> {

    return this.merchantManagementGetAllMerchantWithdrawalrequestsForAdmin$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputMerchantWithdrawalRequestForAdminDto>>) => r.body as Array<OutputMerchantWithdrawalRequestForAdminDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementGetAllMerchantWithdrawalrequestsForAdmin()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetAllMerchantWithdrawalrequestsForAdmin$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputMerchantWithdrawalRequestForAdminDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantManagementService.MerchantManagementGetAllMerchantWithdrawalrequestsForAdminPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputMerchantWithdrawalRequestForAdminDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantManagementGetAllMerchantWithdrawalrequestsForAdmin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetAllMerchantWithdrawalrequestsForAdmin(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputMerchantWithdrawalRequestForAdminDto>> {

    return this.merchantManagementGetAllMerchantWithdrawalrequestsForAdmin$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputMerchantWithdrawalRequestForAdminDto>>) => r.body as Array<OutputMerchantWithdrawalRequestForAdminDto>)
    );
  }

  /**
   * Path part for operation merchantManagementGetBrandsCustomEarning
   */
  static readonly MerchantManagementGetBrandsCustomEarningPath = '/api/admin/MerchantManagement/GetBrandsCustomEarning';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementGetBrandsCustomEarning$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetBrandsCustomEarning$Plain$Response(params?: {
    companyId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutPutGetCustomBrandEarningSettingDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantManagementService.MerchantManagementGetBrandsCustomEarningPath, 'get');
    if (params) {
      rb.query('companyId', params.companyId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutPutGetCustomBrandEarningSettingDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantManagementGetBrandsCustomEarning$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetBrandsCustomEarning$Plain(params?: {
    companyId?: number;
    context?: HttpContext
  }
): Observable<Array<OutPutGetCustomBrandEarningSettingDto>> {

    return this.merchantManagementGetBrandsCustomEarning$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutPutGetCustomBrandEarningSettingDto>>) => r.body as Array<OutPutGetCustomBrandEarningSettingDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementGetBrandsCustomEarning()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetBrandsCustomEarning$Response(params?: {
    companyId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutPutGetCustomBrandEarningSettingDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantManagementService.MerchantManagementGetBrandsCustomEarningPath, 'get');
    if (params) {
      rb.query('companyId', params.companyId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutPutGetCustomBrandEarningSettingDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantManagementGetBrandsCustomEarning$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetBrandsCustomEarning(params?: {
    companyId?: number;
    context?: HttpContext
  }
): Observable<Array<OutPutGetCustomBrandEarningSettingDto>> {

    return this.merchantManagementGetBrandsCustomEarning$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutPutGetCustomBrandEarningSettingDto>>) => r.body as Array<OutPutGetCustomBrandEarningSettingDto>)
    );
  }

  /**
   * Path part for operation merchantManagementGetCompanyCustomEarning
   */
  static readonly MerchantManagementGetCompanyCustomEarningPath = '/api/admin/MerchantManagement/GetCompanyCustomEarning';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementGetCompanyCustomEarning$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetCompanyCustomEarning$Plain$Response(params?: {
    companyId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutPutGetCustomEarningSettingDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantManagementService.MerchantManagementGetCompanyCustomEarningPath, 'get');
    if (params) {
      rb.query('companyId', params.companyId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutPutGetCustomEarningSettingDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantManagementGetCompanyCustomEarning$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetCompanyCustomEarning$Plain(params?: {
    companyId?: number;
    context?: HttpContext
  }
): Observable<Array<OutPutGetCustomEarningSettingDto>> {

    return this.merchantManagementGetCompanyCustomEarning$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutPutGetCustomEarningSettingDto>>) => r.body as Array<OutPutGetCustomEarningSettingDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementGetCompanyCustomEarning()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetCompanyCustomEarning$Response(params?: {
    companyId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutPutGetCustomEarningSettingDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantManagementService.MerchantManagementGetCompanyCustomEarningPath, 'get');
    if (params) {
      rb.query('companyId', params.companyId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutPutGetCustomEarningSettingDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantManagementGetCompanyCustomEarning$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantManagementGetCompanyCustomEarning(params?: {
    companyId?: number;
    context?: HttpContext
  }
): Observable<Array<OutPutGetCustomEarningSettingDto>> {

    return this.merchantManagementGetCompanyCustomEarning$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutPutGetCustomEarningSettingDto>>) => r.body as Array<OutPutGetCustomEarningSettingDto>)
    );
  }

  /**
   * Path part for operation merchantManagementUpdateMerchantWithdrawalStatusByAdmin
   */
  static readonly MerchantManagementUpdateMerchantWithdrawalStatusByAdminPath = '/api/admin/MerchantManagement/UpdateMerchantWithdrawalStatusByAdmin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementUpdateMerchantWithdrawalStatusByAdmin$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementUpdateMerchantWithdrawalStatusByAdmin$Plain$Response(params?: {
    context?: HttpContext
    body?: InputMerchantWithdrawalChangeStatusForAdminDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantManagementService.MerchantManagementUpdateMerchantWithdrawalStatusByAdminPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantManagementUpdateMerchantWithdrawalStatusByAdmin$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementUpdateMerchantWithdrawalStatusByAdmin$Plain(params?: {
    context?: HttpContext
    body?: InputMerchantWithdrawalChangeStatusForAdminDto
  }
): Observable<boolean> {

    return this.merchantManagementUpdateMerchantWithdrawalStatusByAdmin$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementUpdateMerchantWithdrawalStatusByAdmin()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementUpdateMerchantWithdrawalStatusByAdmin$Response(params?: {
    context?: HttpContext
    body?: InputMerchantWithdrawalChangeStatusForAdminDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantManagementService.MerchantManagementUpdateMerchantWithdrawalStatusByAdminPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantManagementUpdateMerchantWithdrawalStatusByAdmin$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementUpdateMerchantWithdrawalStatusByAdmin(params?: {
    context?: HttpContext
    body?: InputMerchantWithdrawalChangeStatusForAdminDto
  }
): Observable<boolean> {

    return this.merchantManagementUpdateMerchantWithdrawalStatusByAdmin$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation merchantManagementAddCustomComapnyEarningSetting
   */
  static readonly MerchantManagementAddCustomComapnyEarningSettingPath = '/api/admin/MerchantManagement/AddCustomComapnyEarningSetting';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementAddCustomComapnyEarningSetting$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementAddCustomComapnyEarningSetting$Plain$Response(params?: {
    context?: HttpContext
    body?: InputCreateCustomEarningSettingDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantManagementService.MerchantManagementAddCustomComapnyEarningSettingPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantManagementAddCustomComapnyEarningSetting$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementAddCustomComapnyEarningSetting$Plain(params?: {
    context?: HttpContext
    body?: InputCreateCustomEarningSettingDto
  }
): Observable<boolean> {

    return this.merchantManagementAddCustomComapnyEarningSetting$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantManagementAddCustomComapnyEarningSetting()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementAddCustomComapnyEarningSetting$Response(params?: {
    context?: HttpContext
    body?: InputCreateCustomEarningSettingDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantManagementService.MerchantManagementAddCustomComapnyEarningSettingPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantManagementAddCustomComapnyEarningSetting$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantManagementAddCustomComapnyEarningSetting(params?: {
    context?: HttpContext
    body?: InputCreateCustomEarningSettingDto
  }
): Observable<boolean> {

    return this.merchantManagementAddCustomComapnyEarningSetting$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
