/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { InputCreateBrandDto } from '../models/input-create-brand-dto';
import { InputCreateCategoryDto } from '../models/input-create-category-dto';
import { InputCreateColorCategoryDto } from '../models/input-create-color-category-dto';
import { InputCreateColorDto } from '../models/input-create-color-dto';
import { InputCreateFilterFeatureContentDto } from '../models/input-create-filter-feature-content-dto';
import { InputCreateFilterFeatureDto } from '../models/input-create-filter-feature-dto';
import { InputCreateGenderLinkDto } from '../models/input-create-gender-link-dto';
import { InputCreateItemDto } from '../models/input-create-item-dto';
import { InputCreateMaterialDto } from '../models/input-create-material-dto';
import { InputCreateSeasonDto } from '../models/input-create-season-dto';
import { InputCreateSizeCategoryDto } from '../models/input-create-size-category-dto';
import { InputCreateSizeDto } from '../models/input-create-size-dto';
import { InputCreateSizeNameDto } from '../models/input-create-size-name-dto';
import { InputCreateStyleDto } from '../models/input-create-style-dto';
import { InputCreateSubCategoryDto } from '../models/input-create-sub-category-dto';
import { InputDeleteBrandDto } from '../models/input-delete-brand-dto';
import { InputDeleteBulkItemDto } from '../models/input-delete-bulk-item-dto';
import { InputDeleteCategoryDto } from '../models/input-delete-category-dto';
import { InputDeleteColorCategoryDto } from '../models/input-delete-color-category-dto';
import { InputDeleteColorDto } from '../models/input-delete-color-dto';
import { InputDeleteItemDto } from '../models/input-delete-item-dto';
import { InputDeleteMaterialDto } from '../models/input-delete-material-dto';
import { InputDeleteSeasonDto } from '../models/input-delete-season-dto';
import { InputDeleteSizeCategoryDto } from '../models/input-delete-size-category-dto';
import { InputDeleteSizeDto } from '../models/input-delete-size-dto';
import { InputDeleteSizeNameDto } from '../models/input-delete-size-name-dto';
import { InputDeleteSubCategoryDto } from '../models/input-delete-sub-category-dto';
import { InputGetItemsAdminDto } from '../models/input-get-items-admin-dto';
import { InputUpdateBrandDto } from '../models/input-update-brand-dto';
import { InputUpdateCategoryDto } from '../models/input-update-category-dto';
import { InputUpdateColorCategoryDto } from '../models/input-update-color-category-dto';
import { InputUpdateColorDto } from '../models/input-update-color-dto';
import { InputUpdateDataRequestByIdDto } from '../models/input-update-data-request-by-id-dto';
import { InputUpdateFilterFeatureContentDto } from '../models/input-update-filter-feature-content-dto';
import { InputUpdateFilterFeatureDto } from '../models/input-update-filter-feature-dto';
import { InputUpdateGenderLinkDto } from '../models/input-update-gender-link-dto';
import { InputUpdateItemDto } from '../models/input-update-item-dto';
import { InputUpdateMaterialDto } from '../models/input-update-material-dto';
import { InputUpdateSeasonDto } from '../models/input-update-season-dto';
import { InputUpdateSizeCategoryDto } from '../models/input-update-size-category-dto';
import { InputUpdateSizeDto } from '../models/input-update-size-dto';
import { InputUpdateSizeNameDto } from '../models/input-update-size-name-dto';
import { InputUpdateStyleDto } from '../models/input-update-style-dto';
import { InputUpdateSubCategoryDto } from '../models/input-update-sub-category-dto';
import { OutputGetAllBrandsDto } from '../models/output-get-all-brands-dto';
import { OutputGetAllCategoryDto } from '../models/output-get-all-category-dto';
import { OutputGetAllColorCategoryDto } from '../models/output-get-all-color-category-dto';
import { OutputGetAllColorDto } from '../models/output-get-all-color-dto';
import { OutputGetAllDataRequestsForAdminDto } from '../models/output-get-all-data-requests-for-admin-dto';
import { OutputGetAllFeatureWithContent } from '../models/output-get-all-feature-with-content';
import { OutputGetAllFilterFeatureContentDto } from '../models/output-get-all-filter-feature-content-dto';
import { OutputGetAllFilterFeatureDto } from '../models/output-get-all-filter-feature-dto';
import { OutputGetAllGenderLinkDto } from '../models/output-get-all-gender-link-dto';
import { OutputGetAllItemForDropDownDto } from '../models/output-get-all-item-for-drop-down-dto';
import { OutputGetAllItemForDropDownWithImageDto } from '../models/output-get-all-item-for-drop-down-with-image-dto';
import { OutputGetAllItemForTableDtoListOutputPagedResponseDto } from '../models/output-get-all-item-for-table-dto-list-output-paged-response-dto';
import { OutputGetAllMaterialDto } from '../models/output-get-all-material-dto';
import { OutputGetAllSeasonDto } from '../models/output-get-all-season-dto';
import { OutputGetAllSizeCategoryDto } from '../models/output-get-all-size-category-dto';
import { OutputGetAllSizeDto } from '../models/output-get-all-size-dto';
import { OutputGetAllSizeNameDto } from '../models/output-get-all-size-name-dto';
import { OutputGetAllStylesDto } from '../models/output-get-all-styles-dto';
import { OutputGetAllSubCategoryDto } from '../models/output-get-all-sub-category-dto';
import { OutputGetAllSubCategoryForDrowpDownListDto } from '../models/output-get-all-sub-category-for-drowp-down-list-dto';

@Injectable({
  providedIn: 'root',
})
export class ItemManagementService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation itemManagementsyncAllItemsFromShopify
   */
  static readonly ItemManagementsyncAllItemsFromShopifyPath = '/api/admin/ItemManagement/syncAllItemsFromShopify';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementsyncAllItemsFromShopify$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementsyncAllItemsFromShopify$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementsyncAllItemsFromShopifyPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementsyncAllItemsFromShopify$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementsyncAllItemsFromShopify$Plain(params?: {
    context?: HttpContext
  }
): Observable<boolean> {

    return this.itemManagementsyncAllItemsFromShopify$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementsyncAllItemsFromShopify()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementsyncAllItemsFromShopify$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementsyncAllItemsFromShopifyPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementsyncAllItemsFromShopify$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementsyncAllItemsFromShopify(params?: {
    context?: HttpContext
  }
): Observable<boolean> {

    return this.itemManagementsyncAllItemsFromShopify$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation itemManagementCreateBrand
   */
  static readonly ItemManagementCreateBrandPath = '/api/admin/ItemManagement/CreateBrand';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateBrand$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateBrand$Plain$Response(params?: {
    context?: HttpContext
    body?: InputCreateBrandDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementCreateBrandPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateBrand$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateBrand$Plain(params?: {
    context?: HttpContext
    body?: InputCreateBrandDto
  }
): Observable<boolean> {

    return this.itemManagementCreateBrand$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateBrand()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateBrand$Response(params?: {
    context?: HttpContext
    body?: InputCreateBrandDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementCreateBrandPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateBrand$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateBrand(params?: {
    context?: HttpContext
    body?: InputCreateBrandDto
  }
): Observable<boolean> {

    return this.itemManagementCreateBrand$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation itemManagementDeleteBrand
   */
  static readonly ItemManagementDeleteBrandPath = '/api/admin/ItemManagement/DeleteBrand';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteBrand$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteBrand$Plain$Response(params?: {
    context?: HttpContext
    body?: InputDeleteBrandDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementDeleteBrandPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteBrand$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteBrand$Plain(params?: {
    context?: HttpContext
    body?: InputDeleteBrandDto
  }
): Observable<boolean> {

    return this.itemManagementDeleteBrand$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteBrand()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteBrand$Response(params?: {
    context?: HttpContext
    body?: InputDeleteBrandDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementDeleteBrandPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteBrand$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteBrand(params?: {
    context?: HttpContext
    body?: InputDeleteBrandDto
  }
): Observable<boolean> {

    return this.itemManagementDeleteBrand$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation itemManagementUpdateBrand
   */
  static readonly ItemManagementUpdateBrandPath = '/api/admin/ItemManagement/UpdateBrand';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateBrand$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateBrand$Plain$Response(params?: {
    context?: HttpContext
    body?: InputUpdateBrandDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementUpdateBrandPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateBrand$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateBrand$Plain(params?: {
    context?: HttpContext
    body?: InputUpdateBrandDto
  }
): Observable<boolean> {

    return this.itemManagementUpdateBrand$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateBrand()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateBrand$Response(params?: {
    context?: HttpContext
    body?: InputUpdateBrandDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementUpdateBrandPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateBrand$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateBrand(params?: {
    context?: HttpContext
    body?: InputUpdateBrandDto
  }
): Observable<boolean> {

    return this.itemManagementUpdateBrand$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation itemManagementGetAllBrand
   */
  static readonly ItemManagementGetAllBrandPath = '/api/admin/ItemManagement/GetAllBrand';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllBrand$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllBrand$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllBrandsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementGetAllBrandPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllBrandsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllBrand$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllBrand$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllBrandsDto>> {

    return this.itemManagementGetAllBrand$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllBrandsDto>>) => r.body as Array<OutputGetAllBrandsDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllBrand()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllBrand$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllBrandsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementGetAllBrandPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllBrandsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllBrand$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllBrand(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllBrandsDto>> {

    return this.itemManagementGetAllBrand$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllBrandsDto>>) => r.body as Array<OutputGetAllBrandsDto>)
    );
  }

  /**
   * Path part for operation itemManagementCreateCategory
   */
  static readonly ItemManagementCreateCategoryPath = '/api/admin/ItemManagement/CreateCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateCategory$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateCategory$Plain$Response(params?: {
    context?: HttpContext
    body?: InputCreateCategoryDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementCreateCategoryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateCategory$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateCategory$Plain(params?: {
    context?: HttpContext
    body?: InputCreateCategoryDto
  }
): Observable<boolean> {

    return this.itemManagementCreateCategory$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateCategory()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateCategory$Response(params?: {
    context?: HttpContext
    body?: InputCreateCategoryDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementCreateCategoryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateCategory$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateCategory(params?: {
    context?: HttpContext
    body?: InputCreateCategoryDto
  }
): Observable<boolean> {

    return this.itemManagementCreateCategory$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation itemManagementGetAllCategory
   */
  static readonly ItemManagementGetAllCategoryPath = '/api/admin/ItemManagement/GetAllCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllCategory$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllCategory$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllCategoryDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementGetAllCategoryPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllCategoryDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllCategory$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllCategory$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllCategoryDto>> {

    return this.itemManagementGetAllCategory$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllCategoryDto>>) => r.body as Array<OutputGetAllCategoryDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllCategory()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllCategory$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllCategoryDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementGetAllCategoryPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllCategoryDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllCategory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllCategory(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllCategoryDto>> {

    return this.itemManagementGetAllCategory$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllCategoryDto>>) => r.body as Array<OutputGetAllCategoryDto>)
    );
  }

  /**
   * Path part for operation itemManagementUpdateCategory
   */
  static readonly ItemManagementUpdateCategoryPath = '/api/admin/ItemManagement/UpdateCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateCategory$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateCategory$Plain$Response(params?: {
    context?: HttpContext
    body?: InputUpdateCategoryDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementUpdateCategoryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateCategory$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateCategory$Plain(params?: {
    context?: HttpContext
    body?: InputUpdateCategoryDto
  }
): Observable<boolean> {

    return this.itemManagementUpdateCategory$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateCategory()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateCategory$Response(params?: {
    context?: HttpContext
    body?: InputUpdateCategoryDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementUpdateCategoryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateCategory$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateCategory(params?: {
    context?: HttpContext
    body?: InputUpdateCategoryDto
  }
): Observable<boolean> {

    return this.itemManagementUpdateCategory$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation itemManagementDeleteCategory
   */
  static readonly ItemManagementDeleteCategoryPath = '/api/admin/ItemManagement/DeleteCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteCategory()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteCategory$Response(params?: {
    context?: HttpContext
    body?: InputDeleteCategoryDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementDeleteCategoryPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteCategory$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteCategory(params?: {
    context?: HttpContext
    body?: InputDeleteCategoryDto
  }
): Observable<void> {

    return this.itemManagementDeleteCategory$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation itemManagementCreateSubCategory
   */
  static readonly ItemManagementCreateSubCategoryPath = '/api/admin/ItemManagement/CreateSubCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateSubCategory$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateSubCategory$Plain$Response(params?: {
    context?: HttpContext
    body?: InputCreateSubCategoryDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementCreateSubCategoryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateSubCategory$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateSubCategory$Plain(params?: {
    context?: HttpContext
    body?: InputCreateSubCategoryDto
  }
): Observable<boolean> {

    return this.itemManagementCreateSubCategory$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateSubCategory()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateSubCategory$Response(params?: {
    context?: HttpContext
    body?: InputCreateSubCategoryDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementCreateSubCategoryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateSubCategory$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateSubCategory(params?: {
    context?: HttpContext
    body?: InputCreateSubCategoryDto
  }
): Observable<boolean> {

    return this.itemManagementCreateSubCategory$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation itemManagementGetAllSubCategory
   */
  static readonly ItemManagementGetAllSubCategoryPath = '/api/admin/ItemManagement/GetAllSubCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllSubCategory$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSubCategory$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllSubCategoryDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementGetAllSubCategoryPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllSubCategoryDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllSubCategory$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSubCategory$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllSubCategoryDto>> {

    return this.itemManagementGetAllSubCategory$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllSubCategoryDto>>) => r.body as Array<OutputGetAllSubCategoryDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllSubCategory()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSubCategory$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllSubCategoryDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementGetAllSubCategoryPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllSubCategoryDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllSubCategory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSubCategory(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllSubCategoryDto>> {

    return this.itemManagementGetAllSubCategory$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllSubCategoryDto>>) => r.body as Array<OutputGetAllSubCategoryDto>)
    );
  }

  /**
   * Path part for operation itemManagementGetAllSubCategoryByCategoryId
   */
  static readonly ItemManagementGetAllSubCategoryByCategoryIdPath = '/api/admin/ItemManagement/GetAllSubCategoryByCategoryId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllSubCategoryByCategoryId$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSubCategoryByCategoryId$Plain$Response(params?: {
    CategoryId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllSubCategoryDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementGetAllSubCategoryByCategoryIdPath, 'get');
    if (params) {
      rb.query('CategoryId', params.CategoryId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllSubCategoryDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllSubCategoryByCategoryId$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSubCategoryByCategoryId$Plain(params?: {
    CategoryId?: number;
    context?: HttpContext
  }
): Observable<Array<OutputGetAllSubCategoryDto>> {

    return this.itemManagementGetAllSubCategoryByCategoryId$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllSubCategoryDto>>) => r.body as Array<OutputGetAllSubCategoryDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllSubCategoryByCategoryId()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSubCategoryByCategoryId$Response(params?: {
    CategoryId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllSubCategoryDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementGetAllSubCategoryByCategoryIdPath, 'get');
    if (params) {
      rb.query('CategoryId', params.CategoryId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllSubCategoryDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllSubCategoryByCategoryId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSubCategoryByCategoryId(params?: {
    CategoryId?: number;
    context?: HttpContext
  }
): Observable<Array<OutputGetAllSubCategoryDto>> {

    return this.itemManagementGetAllSubCategoryByCategoryId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllSubCategoryDto>>) => r.body as Array<OutputGetAllSubCategoryDto>)
    );
  }

  /**
   * Path part for operation itemManagementUpdateSubCategory
   */
  static readonly ItemManagementUpdateSubCategoryPath = '/api/admin/ItemManagement/UpdateSubCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateSubCategory$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateSubCategory$Plain$Response(params?: {
    context?: HttpContext
    body?: InputUpdateSubCategoryDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementUpdateSubCategoryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateSubCategory$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateSubCategory$Plain(params?: {
    context?: HttpContext
    body?: InputUpdateSubCategoryDto
  }
): Observable<boolean> {

    return this.itemManagementUpdateSubCategory$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateSubCategory()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateSubCategory$Response(params?: {
    context?: HttpContext
    body?: InputUpdateSubCategoryDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementUpdateSubCategoryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateSubCategory$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateSubCategory(params?: {
    context?: HttpContext
    body?: InputUpdateSubCategoryDto
  }
): Observable<boolean> {

    return this.itemManagementUpdateSubCategory$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation itemManagementDeleteSubCategory
   */
  static readonly ItemManagementDeleteSubCategoryPath = '/api/admin/ItemManagement/DeleteSubCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteSubCategory()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteSubCategory$Response(params?: {
    context?: HttpContext
    body?: InputDeleteSubCategoryDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementDeleteSubCategoryPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteSubCategory$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteSubCategory(params?: {
    context?: HttpContext
    body?: InputDeleteSubCategoryDto
  }
): Observable<void> {

    return this.itemManagementDeleteSubCategory$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation itemManagementGetSubCategoryByGenderForDrowpdown
   */
  static readonly ItemManagementGetSubCategoryByGenderForDrowpdownPath = '/api/admin/ItemManagement/GetSubCategoryByGenderForDrowpdown';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetSubCategoryByGenderForDrowpdown$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetSubCategoryByGenderForDrowpdown$Plain$Response(params?: {
    Gender?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllSubCategoryForDrowpDownListDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementGetSubCategoryByGenderForDrowpdownPath, 'get');
    if (params) {
      rb.query('Gender', params.Gender, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllSubCategoryForDrowpDownListDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetSubCategoryByGenderForDrowpdown$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetSubCategoryByGenderForDrowpdown$Plain(params?: {
    Gender?: string;
    context?: HttpContext
  }
): Observable<Array<OutputGetAllSubCategoryForDrowpDownListDto>> {

    return this.itemManagementGetSubCategoryByGenderForDrowpdown$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllSubCategoryForDrowpDownListDto>>) => r.body as Array<OutputGetAllSubCategoryForDrowpDownListDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetSubCategoryByGenderForDrowpdown()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetSubCategoryByGenderForDrowpdown$Response(params?: {
    Gender?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllSubCategoryForDrowpDownListDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementGetSubCategoryByGenderForDrowpdownPath, 'get');
    if (params) {
      rb.query('Gender', params.Gender, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllSubCategoryForDrowpDownListDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetSubCategoryByGenderForDrowpdown$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetSubCategoryByGenderForDrowpdown(params?: {
    Gender?: string;
    context?: HttpContext
  }
): Observable<Array<OutputGetAllSubCategoryForDrowpDownListDto>> {

    return this.itemManagementGetSubCategoryByGenderForDrowpdown$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllSubCategoryForDrowpDownListDto>>) => r.body as Array<OutputGetAllSubCategoryForDrowpDownListDto>)
    );
  }

  /**
   * Path part for operation itemManagementCreateItem
   */
  static readonly ItemManagementCreateItemPath = '/api/admin/ItemManagement/CreateItem';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateItem$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateItem$Plain$Response(params?: {
    context?: HttpContext
    body?: InputCreateItemDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementCreateItemPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateItem$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateItem$Plain(params?: {
    context?: HttpContext
    body?: InputCreateItemDto
  }
): Observable<boolean> {

    return this.itemManagementCreateItem$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateItem()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateItem$Response(params?: {
    context?: HttpContext
    body?: InputCreateItemDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementCreateItemPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateItem$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateItem(params?: {
    context?: HttpContext
    body?: InputCreateItemDto
  }
): Observable<boolean> {

    return this.itemManagementCreateItem$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation itemManagementUpdateItem
   */
  static readonly ItemManagementUpdateItemPath = '/api/admin/ItemManagement/UpdateItem';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateItem$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateItem$Plain$Response(params?: {
    context?: HttpContext
    body?: InputUpdateItemDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementUpdateItemPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateItem$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateItem$Plain(params?: {
    context?: HttpContext
    body?: InputUpdateItemDto
  }
): Observable<boolean> {

    return this.itemManagementUpdateItem$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateItem()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateItem$Response(params?: {
    context?: HttpContext
    body?: InputUpdateItemDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementUpdateItemPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateItem$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateItem(params?: {
    context?: HttpContext
    body?: InputUpdateItemDto
  }
): Observable<boolean> {

    return this.itemManagementUpdateItem$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation itemManagementGetAllPagedItemsForAdmin
   */
  static readonly ItemManagementGetAllPagedItemsForAdminPath = '/api/admin/ItemManagement/GetAllPagedItemsForAdmin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllPagedItemsForAdmin$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementGetAllPagedItemsForAdmin$Plain$Response(params?: {
    context?: HttpContext
    body?: InputGetItemsAdminDto
  }
): Observable<StrictHttpResponse<OutputGetAllItemForTableDtoListOutputPagedResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementGetAllPagedItemsForAdminPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetAllItemForTableDtoListOutputPagedResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllPagedItemsForAdmin$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementGetAllPagedItemsForAdmin$Plain(params?: {
    context?: HttpContext
    body?: InputGetItemsAdminDto
  }
): Observable<OutputGetAllItemForTableDtoListOutputPagedResponseDto> {

    return this.itemManagementGetAllPagedItemsForAdmin$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetAllItemForTableDtoListOutputPagedResponseDto>) => r.body as OutputGetAllItemForTableDtoListOutputPagedResponseDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllPagedItemsForAdmin()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementGetAllPagedItemsForAdmin$Response(params?: {
    context?: HttpContext
    body?: InputGetItemsAdminDto
  }
): Observable<StrictHttpResponse<OutputGetAllItemForTableDtoListOutputPagedResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementGetAllPagedItemsForAdminPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetAllItemForTableDtoListOutputPagedResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllPagedItemsForAdmin$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementGetAllPagedItemsForAdmin(params?: {
    context?: HttpContext
    body?: InputGetItemsAdminDto
  }
): Observable<OutputGetAllItemForTableDtoListOutputPagedResponseDto> {

    return this.itemManagementGetAllPagedItemsForAdmin$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetAllItemForTableDtoListOutputPagedResponseDto>) => r.body as OutputGetAllItemForTableDtoListOutputPagedResponseDto)
    );
  }

  /**
   * Path part for operation itemManagementDeleteItem
   */
  static readonly ItemManagementDeleteItemPath = '/api/admin/ItemManagement/DeleteItem';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteItem$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteItem$Plain$Response(params?: {
    context?: HttpContext
    body?: InputDeleteItemDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementDeleteItemPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteItem$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteItem$Plain(params?: {
    context?: HttpContext
    body?: InputDeleteItemDto
  }
): Observable<boolean> {

    return this.itemManagementDeleteItem$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteItem()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteItem$Response(params?: {
    context?: HttpContext
    body?: InputDeleteItemDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementDeleteItemPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteItem$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteItem(params?: {
    context?: HttpContext
    body?: InputDeleteItemDto
  }
): Observable<boolean> {

    return this.itemManagementDeleteItem$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation itemManagementDeleteBulkItem
   */
  static readonly ItemManagementDeleteBulkItemPath = '/api/admin/ItemManagement/DeleteBulkItem';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteBulkItem$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteBulkItem$Plain$Response(params?: {
    context?: HttpContext
    body?: InputDeleteBulkItemDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementDeleteBulkItemPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteBulkItem$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteBulkItem$Plain(params?: {
    context?: HttpContext
    body?: InputDeleteBulkItemDto
  }
): Observable<boolean> {

    return this.itemManagementDeleteBulkItem$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteBulkItem()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteBulkItem$Response(params?: {
    context?: HttpContext
    body?: InputDeleteBulkItemDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementDeleteBulkItemPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteBulkItem$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteBulkItem(params?: {
    context?: HttpContext
    body?: InputDeleteBulkItemDto
  }
): Observable<boolean> {

    return this.itemManagementDeleteBulkItem$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation itemManagementGetAllItemsForDropDown
   */
  static readonly ItemManagementGetAllItemsForDropDownPath = '/api/admin/ItemManagement/GetAllItemsForDropDown';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllItemsForDropDown$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllItemsForDropDown$Plain$Response(params?: {
    subCategoryId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllItemForDropDownDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementGetAllItemsForDropDownPath, 'get');
    if (params) {
      rb.query('subCategoryId', params.subCategoryId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllItemForDropDownDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllItemsForDropDown$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllItemsForDropDown$Plain(params?: {
    subCategoryId?: number;
    context?: HttpContext
  }
): Observable<Array<OutputGetAllItemForDropDownDto>> {

    return this.itemManagementGetAllItemsForDropDown$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllItemForDropDownDto>>) => r.body as Array<OutputGetAllItemForDropDownDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllItemsForDropDown()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllItemsForDropDown$Response(params?: {
    subCategoryId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllItemForDropDownDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementGetAllItemsForDropDownPath, 'get');
    if (params) {
      rb.query('subCategoryId', params.subCategoryId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllItemForDropDownDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllItemsForDropDown$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllItemsForDropDown(params?: {
    subCategoryId?: number;
    context?: HttpContext
  }
): Observable<Array<OutputGetAllItemForDropDownDto>> {

    return this.itemManagementGetAllItemsForDropDown$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllItemForDropDownDto>>) => r.body as Array<OutputGetAllItemForDropDownDto>)
    );
  }

  /**
   * Path part for operation itemManagementGetAllItemsSearchForDropDownWithImage
   */
  static readonly ItemManagementGetAllItemsSearchForDropDownWithImagePath = '/api/admin/ItemManagement/GetAllItemsSearchForDropDownWithImage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllItemsSearchForDropDownWithImage$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllItemsSearchForDropDownWithImage$Plain$Response(params?: {
    search?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllItemForDropDownWithImageDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementGetAllItemsSearchForDropDownWithImagePath, 'get');
    if (params) {
      rb.query('search', params.search, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllItemForDropDownWithImageDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllItemsSearchForDropDownWithImage$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllItemsSearchForDropDownWithImage$Plain(params?: {
    search?: string;
    context?: HttpContext
  }
): Observable<Array<OutputGetAllItemForDropDownWithImageDto>> {

    return this.itemManagementGetAllItemsSearchForDropDownWithImage$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllItemForDropDownWithImageDto>>) => r.body as Array<OutputGetAllItemForDropDownWithImageDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllItemsSearchForDropDownWithImage()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllItemsSearchForDropDownWithImage$Response(params?: {
    search?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllItemForDropDownWithImageDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementGetAllItemsSearchForDropDownWithImagePath, 'get');
    if (params) {
      rb.query('search', params.search, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllItemForDropDownWithImageDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllItemsSearchForDropDownWithImage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllItemsSearchForDropDownWithImage(params?: {
    search?: string;
    context?: HttpContext
  }
): Observable<Array<OutputGetAllItemForDropDownWithImageDto>> {

    return this.itemManagementGetAllItemsSearchForDropDownWithImage$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllItemForDropDownWithImageDto>>) => r.body as Array<OutputGetAllItemForDropDownWithImageDto>)
    );
  }

  /**
   * Path part for operation itemManagementCreateColor
   */
  static readonly ItemManagementCreateColorPath = '/api/admin/ItemManagement/CreateColor';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateColor$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateColor$Plain$Response(params?: {
    context?: HttpContext
    body?: InputCreateColorDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementCreateColorPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateColor$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateColor$Plain(params?: {
    context?: HttpContext
    body?: InputCreateColorDto
  }
): Observable<boolean> {

    return this.itemManagementCreateColor$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateColor()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateColor$Response(params?: {
    context?: HttpContext
    body?: InputCreateColorDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementCreateColorPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateColor$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateColor(params?: {
    context?: HttpContext
    body?: InputCreateColorDto
  }
): Observable<boolean> {

    return this.itemManagementCreateColor$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation itemManagementDeleteColor
   */
  static readonly ItemManagementDeleteColorPath = '/api/admin/ItemManagement/DeleteColor';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteColor$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteColor$Plain$Response(params?: {
    context?: HttpContext
    body?: InputDeleteColorDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementDeleteColorPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteColor$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteColor$Plain(params?: {
    context?: HttpContext
    body?: InputDeleteColorDto
  }
): Observable<boolean> {

    return this.itemManagementDeleteColor$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteColor()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteColor$Response(params?: {
    context?: HttpContext
    body?: InputDeleteColorDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementDeleteColorPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteColor$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteColor(params?: {
    context?: HttpContext
    body?: InputDeleteColorDto
  }
): Observable<boolean> {

    return this.itemManagementDeleteColor$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation itemManagementUpdateColor
   */
  static readonly ItemManagementUpdateColorPath = '/api/admin/ItemManagement/UpdateColor';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateColor$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateColor$Plain$Response(params?: {
    context?: HttpContext
    body?: InputUpdateColorDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementUpdateColorPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateColor$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateColor$Plain(params?: {
    context?: HttpContext
    body?: InputUpdateColorDto
  }
): Observable<boolean> {

    return this.itemManagementUpdateColor$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateColor()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateColor$Response(params?: {
    context?: HttpContext
    body?: InputUpdateColorDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementUpdateColorPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateColor$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateColor(params?: {
    context?: HttpContext
    body?: InputUpdateColorDto
  }
): Observable<boolean> {

    return this.itemManagementUpdateColor$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation itemManagementGetAllColor
   */
  static readonly ItemManagementGetAllColorPath = '/api/admin/ItemManagement/GetAllColor';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllColor$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllColor$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllColorDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementGetAllColorPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllColorDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllColor$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllColor$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllColorDto>> {

    return this.itemManagementGetAllColor$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllColorDto>>) => r.body as Array<OutputGetAllColorDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllColor()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllColor$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllColorDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementGetAllColorPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllColorDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllColor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllColor(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllColorDto>> {

    return this.itemManagementGetAllColor$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllColorDto>>) => r.body as Array<OutputGetAllColorDto>)
    );
  }

  /**
   * Path part for operation itemManagementCreateColorCategory
   */
  static readonly ItemManagementCreateColorCategoryPath = '/api/admin/ItemManagement/CreateColorCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateColorCategory$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateColorCategory$Plain$Response(params?: {
    context?: HttpContext
    body?: InputCreateColorCategoryDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementCreateColorCategoryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateColorCategory$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateColorCategory$Plain(params?: {
    context?: HttpContext
    body?: InputCreateColorCategoryDto
  }
): Observable<boolean> {

    return this.itemManagementCreateColorCategory$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateColorCategory()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateColorCategory$Response(params?: {
    context?: HttpContext
    body?: InputCreateColorCategoryDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementCreateColorCategoryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateColorCategory$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateColorCategory(params?: {
    context?: HttpContext
    body?: InputCreateColorCategoryDto
  }
): Observable<boolean> {

    return this.itemManagementCreateColorCategory$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation itemManagementDeleteColorCategory
   */
  static readonly ItemManagementDeleteColorCategoryPath = '/api/admin/ItemManagement/DeleteColorCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteColorCategory$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteColorCategory$Plain$Response(params?: {
    context?: HttpContext
    body?: InputDeleteColorCategoryDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementDeleteColorCategoryPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteColorCategory$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteColorCategory$Plain(params?: {
    context?: HttpContext
    body?: InputDeleteColorCategoryDto
  }
): Observable<boolean> {

    return this.itemManagementDeleteColorCategory$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteColorCategory()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteColorCategory$Response(params?: {
    context?: HttpContext
    body?: InputDeleteColorCategoryDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementDeleteColorCategoryPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteColorCategory$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteColorCategory(params?: {
    context?: HttpContext
    body?: InputDeleteColorCategoryDto
  }
): Observable<boolean> {

    return this.itemManagementDeleteColorCategory$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation itemManagementUpdateColorCategory
   */
  static readonly ItemManagementUpdateColorCategoryPath = '/api/admin/ItemManagement/UpdateColorCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateColorCategory$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateColorCategory$Plain$Response(params?: {
    context?: HttpContext
    body?: InputUpdateColorCategoryDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementUpdateColorCategoryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateColorCategory$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateColorCategory$Plain(params?: {
    context?: HttpContext
    body?: InputUpdateColorCategoryDto
  }
): Observable<boolean> {

    return this.itemManagementUpdateColorCategory$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateColorCategory()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateColorCategory$Response(params?: {
    context?: HttpContext
    body?: InputUpdateColorCategoryDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementUpdateColorCategoryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateColorCategory$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateColorCategory(params?: {
    context?: HttpContext
    body?: InputUpdateColorCategoryDto
  }
): Observable<boolean> {

    return this.itemManagementUpdateColorCategory$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation itemManagementGetAllColorCategory
   */
  static readonly ItemManagementGetAllColorCategoryPath = '/api/admin/ItemManagement/GetAllColorCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllColorCategory$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllColorCategory$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllColorCategoryDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementGetAllColorCategoryPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllColorCategoryDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllColorCategory$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllColorCategory$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllColorCategoryDto>> {

    return this.itemManagementGetAllColorCategory$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllColorCategoryDto>>) => r.body as Array<OutputGetAllColorCategoryDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllColorCategory()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllColorCategory$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllColorCategoryDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementGetAllColorCategoryPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllColorCategoryDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllColorCategory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllColorCategory(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllColorCategoryDto>> {

    return this.itemManagementGetAllColorCategory$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllColorCategoryDto>>) => r.body as Array<OutputGetAllColorCategoryDto>)
    );
  }

  /**
   * Path part for operation itemManagementCreateSeason
   */
  static readonly ItemManagementCreateSeasonPath = '/api/admin/ItemManagement/CreateSeason';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateSeason$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateSeason$Plain$Response(params?: {
    context?: HttpContext
    body?: InputCreateSeasonDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementCreateSeasonPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateSeason$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateSeason$Plain(params?: {
    context?: HttpContext
    body?: InputCreateSeasonDto
  }
): Observable<boolean> {

    return this.itemManagementCreateSeason$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateSeason()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateSeason$Response(params?: {
    context?: HttpContext
    body?: InputCreateSeasonDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementCreateSeasonPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateSeason$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateSeason(params?: {
    context?: HttpContext
    body?: InputCreateSeasonDto
  }
): Observable<boolean> {

    return this.itemManagementCreateSeason$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation itemManagementDeleteSeason
   */
  static readonly ItemManagementDeleteSeasonPath = '/api/admin/ItemManagement/DeleteSeason';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteSeason$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteSeason$Plain$Response(params?: {
    context?: HttpContext
    body?: InputDeleteSeasonDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementDeleteSeasonPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteSeason$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteSeason$Plain(params?: {
    context?: HttpContext
    body?: InputDeleteSeasonDto
  }
): Observable<boolean> {

    return this.itemManagementDeleteSeason$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteSeason()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteSeason$Response(params?: {
    context?: HttpContext
    body?: InputDeleteSeasonDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementDeleteSeasonPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteSeason$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteSeason(params?: {
    context?: HttpContext
    body?: InputDeleteSeasonDto
  }
): Observable<boolean> {

    return this.itemManagementDeleteSeason$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation itemManagementUpdateSeason
   */
  static readonly ItemManagementUpdateSeasonPath = '/api/admin/ItemManagement/UpdateSeason';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateSeason$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateSeason$Plain$Response(params?: {
    context?: HttpContext
    body?: InputUpdateSeasonDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementUpdateSeasonPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateSeason$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateSeason$Plain(params?: {
    context?: HttpContext
    body?: InputUpdateSeasonDto
  }
): Observable<boolean> {

    return this.itemManagementUpdateSeason$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateSeason()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateSeason$Response(params?: {
    context?: HttpContext
    body?: InputUpdateSeasonDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementUpdateSeasonPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateSeason$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateSeason(params?: {
    context?: HttpContext
    body?: InputUpdateSeasonDto
  }
): Observable<boolean> {

    return this.itemManagementUpdateSeason$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation itemManagementGetAllSeason
   */
  static readonly ItemManagementGetAllSeasonPath = '/api/admin/ItemManagement/GetAllSeason';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllSeason$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSeason$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllSeasonDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementGetAllSeasonPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllSeasonDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllSeason$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSeason$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllSeasonDto>> {

    return this.itemManagementGetAllSeason$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllSeasonDto>>) => r.body as Array<OutputGetAllSeasonDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllSeason()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSeason$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllSeasonDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementGetAllSeasonPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllSeasonDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllSeason$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSeason(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllSeasonDto>> {

    return this.itemManagementGetAllSeason$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllSeasonDto>>) => r.body as Array<OutputGetAllSeasonDto>)
    );
  }

  /**
   * Path part for operation itemManagementCreateMaterial
   */
  static readonly ItemManagementCreateMaterialPath = '/api/admin/ItemManagement/CreateMaterial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateMaterial$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateMaterial$Plain$Response(params?: {
    context?: HttpContext
    body?: InputCreateMaterialDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementCreateMaterialPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateMaterial$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateMaterial$Plain(params?: {
    context?: HttpContext
    body?: InputCreateMaterialDto
  }
): Observable<boolean> {

    return this.itemManagementCreateMaterial$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateMaterial()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateMaterial$Response(params?: {
    context?: HttpContext
    body?: InputCreateMaterialDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementCreateMaterialPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateMaterial$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateMaterial(params?: {
    context?: HttpContext
    body?: InputCreateMaterialDto
  }
): Observable<boolean> {

    return this.itemManagementCreateMaterial$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation itemManagementDeleteMaterial
   */
  static readonly ItemManagementDeleteMaterialPath = '/api/admin/ItemManagement/DeleteMaterial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteMaterial$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteMaterial$Plain$Response(params?: {
    context?: HttpContext
    body?: InputDeleteMaterialDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementDeleteMaterialPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteMaterial$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteMaterial$Plain(params?: {
    context?: HttpContext
    body?: InputDeleteMaterialDto
  }
): Observable<boolean> {

    return this.itemManagementDeleteMaterial$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteMaterial()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteMaterial$Response(params?: {
    context?: HttpContext
    body?: InputDeleteMaterialDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementDeleteMaterialPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteMaterial$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteMaterial(params?: {
    context?: HttpContext
    body?: InputDeleteMaterialDto
  }
): Observable<boolean> {

    return this.itemManagementDeleteMaterial$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation itemManagementUpdateMaterial
   */
  static readonly ItemManagementUpdateMaterialPath = '/api/admin/ItemManagement/UpdateMaterial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateMaterial$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateMaterial$Plain$Response(params?: {
    context?: HttpContext
    body?: InputUpdateMaterialDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementUpdateMaterialPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateMaterial$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateMaterial$Plain(params?: {
    context?: HttpContext
    body?: InputUpdateMaterialDto
  }
): Observable<boolean> {

    return this.itemManagementUpdateMaterial$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateMaterial()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateMaterial$Response(params?: {
    context?: HttpContext
    body?: InputUpdateMaterialDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementUpdateMaterialPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateMaterial$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateMaterial(params?: {
    context?: HttpContext
    body?: InputUpdateMaterialDto
  }
): Observable<boolean> {

    return this.itemManagementUpdateMaterial$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation itemManagementGetAllMaterial
   */
  static readonly ItemManagementGetAllMaterialPath = '/api/admin/ItemManagement/GetAllMaterial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllMaterial$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllMaterial$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllMaterialDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementGetAllMaterialPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllMaterialDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllMaterial$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllMaterial$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllMaterialDto>> {

    return this.itemManagementGetAllMaterial$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllMaterialDto>>) => r.body as Array<OutputGetAllMaterialDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllMaterial()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllMaterial$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllMaterialDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementGetAllMaterialPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllMaterialDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllMaterial$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllMaterial(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllMaterialDto>> {

    return this.itemManagementGetAllMaterial$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllMaterialDto>>) => r.body as Array<OutputGetAllMaterialDto>)
    );
  }

  /**
   * Path part for operation itemManagementCreateSize
   */
  static readonly ItemManagementCreateSizePath = '/api/admin/ItemManagement/CreateSize';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateSize$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateSize$Plain$Response(params?: {
    context?: HttpContext
    body?: InputCreateSizeDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementCreateSizePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateSize$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateSize$Plain(params?: {
    context?: HttpContext
    body?: InputCreateSizeDto
  }
): Observable<boolean> {

    return this.itemManagementCreateSize$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateSize()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateSize$Response(params?: {
    context?: HttpContext
    body?: InputCreateSizeDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementCreateSizePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateSize$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateSize(params?: {
    context?: HttpContext
    body?: InputCreateSizeDto
  }
): Observable<boolean> {

    return this.itemManagementCreateSize$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation itemManagementDeleteSize
   */
  static readonly ItemManagementDeleteSizePath = '/api/admin/ItemManagement/DeleteSize';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteSize$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteSize$Plain$Response(params?: {
    context?: HttpContext
    body?: InputDeleteSizeDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementDeleteSizePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteSize$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteSize$Plain(params?: {
    context?: HttpContext
    body?: InputDeleteSizeDto
  }
): Observable<boolean> {

    return this.itemManagementDeleteSize$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteSize()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteSize$Response(params?: {
    context?: HttpContext
    body?: InputDeleteSizeDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementDeleteSizePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteSize$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteSize(params?: {
    context?: HttpContext
    body?: InputDeleteSizeDto
  }
): Observable<boolean> {

    return this.itemManagementDeleteSize$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation itemManagementUpdateSize
   */
  static readonly ItemManagementUpdateSizePath = '/api/admin/ItemManagement/UpdateSize';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateSize$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateSize$Plain$Response(params?: {
    context?: HttpContext
    body?: InputUpdateSizeDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementUpdateSizePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateSize$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateSize$Plain(params?: {
    context?: HttpContext
    body?: InputUpdateSizeDto
  }
): Observable<boolean> {

    return this.itemManagementUpdateSize$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateSize()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateSize$Response(params?: {
    context?: HttpContext
    body?: InputUpdateSizeDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementUpdateSizePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateSize$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateSize(params?: {
    context?: HttpContext
    body?: InputUpdateSizeDto
  }
): Observable<boolean> {

    return this.itemManagementUpdateSize$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation itemManagementGetAllSize
   */
  static readonly ItemManagementGetAllSizePath = '/api/admin/ItemManagement/GetAllSize';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllSize$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSize$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllSizeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementGetAllSizePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllSizeDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllSize$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSize$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllSizeDto>> {

    return this.itemManagementGetAllSize$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllSizeDto>>) => r.body as Array<OutputGetAllSizeDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllSize()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSize$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllSizeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementGetAllSizePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllSizeDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllSize$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSize(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllSizeDto>> {

    return this.itemManagementGetAllSize$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllSizeDto>>) => r.body as Array<OutputGetAllSizeDto>)
    );
  }

  /**
   * Path part for operation itemManagementCreateSizeName
   */
  static readonly ItemManagementCreateSizeNamePath = '/api/admin/ItemManagement/CreateSizeName';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateSizeName$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateSizeName$Plain$Response(params?: {
    context?: HttpContext
    body?: InputCreateSizeNameDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementCreateSizeNamePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateSizeName$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateSizeName$Plain(params?: {
    context?: HttpContext
    body?: InputCreateSizeNameDto
  }
): Observable<boolean> {

    return this.itemManagementCreateSizeName$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateSizeName()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateSizeName$Response(params?: {
    context?: HttpContext
    body?: InputCreateSizeNameDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementCreateSizeNamePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateSizeName$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateSizeName(params?: {
    context?: HttpContext
    body?: InputCreateSizeNameDto
  }
): Observable<boolean> {

    return this.itemManagementCreateSizeName$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation itemManagementDeleteSizeName
   */
  static readonly ItemManagementDeleteSizeNamePath = '/api/admin/ItemManagement/DeleteSizeName';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteSizeName$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteSizeName$Plain$Response(params?: {
    context?: HttpContext
    body?: InputDeleteSizeNameDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementDeleteSizeNamePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteSizeName$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteSizeName$Plain(params?: {
    context?: HttpContext
    body?: InputDeleteSizeNameDto
  }
): Observable<boolean> {

    return this.itemManagementDeleteSizeName$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteSizeName()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteSizeName$Response(params?: {
    context?: HttpContext
    body?: InputDeleteSizeNameDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementDeleteSizeNamePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteSizeName$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteSizeName(params?: {
    context?: HttpContext
    body?: InputDeleteSizeNameDto
  }
): Observable<boolean> {

    return this.itemManagementDeleteSizeName$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation itemManagementUpdateSizeName
   */
  static readonly ItemManagementUpdateSizeNamePath = '/api/admin/ItemManagement/UpdateSizeName';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateSizeName$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateSizeName$Plain$Response(params?: {
    context?: HttpContext
    body?: InputUpdateSizeNameDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementUpdateSizeNamePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateSizeName$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateSizeName$Plain(params?: {
    context?: HttpContext
    body?: InputUpdateSizeNameDto
  }
): Observable<boolean> {

    return this.itemManagementUpdateSizeName$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateSizeName()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateSizeName$Response(params?: {
    context?: HttpContext
    body?: InputUpdateSizeNameDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementUpdateSizeNamePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateSizeName$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateSizeName(params?: {
    context?: HttpContext
    body?: InputUpdateSizeNameDto
  }
): Observable<boolean> {

    return this.itemManagementUpdateSizeName$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation itemManagementGetAllSizeName
   */
  static readonly ItemManagementGetAllSizeNamePath = '/api/admin/ItemManagement/GetAllSizeName';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllSizeName$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSizeName$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllSizeNameDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementGetAllSizeNamePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllSizeNameDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllSizeName$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSizeName$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllSizeNameDto>> {

    return this.itemManagementGetAllSizeName$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllSizeNameDto>>) => r.body as Array<OutputGetAllSizeNameDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllSizeName()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSizeName$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllSizeNameDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementGetAllSizeNamePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllSizeNameDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllSizeName$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSizeName(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllSizeNameDto>> {

    return this.itemManagementGetAllSizeName$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllSizeNameDto>>) => r.body as Array<OutputGetAllSizeNameDto>)
    );
  }

  /**
   * Path part for operation itemManagementCreateSizeCategory
   */
  static readonly ItemManagementCreateSizeCategoryPath = '/api/admin/ItemManagement/CreateSizeCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateSizeCategory$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateSizeCategory$Plain$Response(params?: {
    context?: HttpContext
    body?: InputCreateSizeCategoryDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementCreateSizeCategoryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateSizeCategory$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateSizeCategory$Plain(params?: {
    context?: HttpContext
    body?: InputCreateSizeCategoryDto
  }
): Observable<boolean> {

    return this.itemManagementCreateSizeCategory$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateSizeCategory()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateSizeCategory$Response(params?: {
    context?: HttpContext
    body?: InputCreateSizeCategoryDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementCreateSizeCategoryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateSizeCategory$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateSizeCategory(params?: {
    context?: HttpContext
    body?: InputCreateSizeCategoryDto
  }
): Observable<boolean> {

    return this.itemManagementCreateSizeCategory$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation itemManagementDeleteSizeCategory
   */
  static readonly ItemManagementDeleteSizeCategoryPath = '/api/admin/ItemManagement/DeleteSizeCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteSizeCategory$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteSizeCategory$Plain$Response(params?: {
    context?: HttpContext
    body?: InputDeleteSizeCategoryDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementDeleteSizeCategoryPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteSizeCategory$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteSizeCategory$Plain(params?: {
    context?: HttpContext
    body?: InputDeleteSizeCategoryDto
  }
): Observable<boolean> {

    return this.itemManagementDeleteSizeCategory$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteSizeCategory()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteSizeCategory$Response(params?: {
    context?: HttpContext
    body?: InputDeleteSizeCategoryDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementDeleteSizeCategoryPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteSizeCategory$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementDeleteSizeCategory(params?: {
    context?: HttpContext
    body?: InputDeleteSizeCategoryDto
  }
): Observable<boolean> {

    return this.itemManagementDeleteSizeCategory$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation itemManagementUpdateSizeCategory
   */
  static readonly ItemManagementUpdateSizeCategoryPath = '/api/admin/ItemManagement/UpdateSizeCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateSizeCategory$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateSizeCategory$Plain$Response(params?: {
    context?: HttpContext
    body?: InputUpdateSizeCategoryDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementUpdateSizeCategoryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateSizeCategory$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateSizeCategory$Plain(params?: {
    context?: HttpContext
    body?: InputUpdateSizeCategoryDto
  }
): Observable<boolean> {

    return this.itemManagementUpdateSizeCategory$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateSizeCategory()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateSizeCategory$Response(params?: {
    context?: HttpContext
    body?: InputUpdateSizeCategoryDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementUpdateSizeCategoryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateSizeCategory$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateSizeCategory(params?: {
    context?: HttpContext
    body?: InputUpdateSizeCategoryDto
  }
): Observable<boolean> {

    return this.itemManagementUpdateSizeCategory$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation itemManagementGetAllSizeCategory
   */
  static readonly ItemManagementGetAllSizeCategoryPath = '/api/admin/ItemManagement/GetAllSizeCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllSizeCategory$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSizeCategory$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllSizeCategoryDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementGetAllSizeCategoryPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllSizeCategoryDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllSizeCategory$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSizeCategory$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllSizeCategoryDto>> {

    return this.itemManagementGetAllSizeCategory$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllSizeCategoryDto>>) => r.body as Array<OutputGetAllSizeCategoryDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllSizeCategory()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSizeCategory$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllSizeCategoryDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementGetAllSizeCategoryPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllSizeCategoryDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllSizeCategory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllSizeCategory(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllSizeCategoryDto>> {

    return this.itemManagementGetAllSizeCategory$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllSizeCategoryDto>>) => r.body as Array<OutputGetAllSizeCategoryDto>)
    );
  }

  /**
   * Path part for operation itemManagementCreateStyle
   */
  static readonly ItemManagementCreateStylePath = '/api/admin/ItemManagement/CreateStyle';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateStyle$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateStyle$Plain$Response(params?: {
    context?: HttpContext
    body?: InputCreateStyleDto
  }
): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementCreateStylePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateStyle$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateStyle$Plain(params?: {
    context?: HttpContext
    body?: InputCreateStyleDto
  }
): Observable<number> {

    return this.itemManagementCreateStyle$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateStyle()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateStyle$Response(params?: {
    context?: HttpContext
    body?: InputCreateStyleDto
  }
): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementCreateStylePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateStyle$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateStyle(params?: {
    context?: HttpContext
    body?: InputCreateStyleDto
  }
): Observable<number> {

    return this.itemManagementCreateStyle$Response(params).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation itemManagementDeleteStyle
   */
  static readonly ItemManagementDeleteStylePath = '/api/admin/ItemManagement/DeleteStyle';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteStyle$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementDeleteStyle$Plain$Response(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementDeleteStylePath, 'delete');
    if (params) {
      rb.query('id', params.id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteStyle$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementDeleteStyle$Plain(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.itemManagementDeleteStyle$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteStyle()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementDeleteStyle$Response(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementDeleteStylePath, 'delete');
    if (params) {
      rb.query('id', params.id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteStyle$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementDeleteStyle(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.itemManagementDeleteStyle$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation itemManagementUpdateStyle
   */
  static readonly ItemManagementUpdateStylePath = '/api/admin/ItemManagement/UpdateStyle';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateStyle$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateStyle$Plain$Response(params?: {
    context?: HttpContext
    body?: InputUpdateStyleDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementUpdateStylePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateStyle$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateStyle$Plain(params?: {
    context?: HttpContext
    body?: InputUpdateStyleDto
  }
): Observable<boolean> {

    return this.itemManagementUpdateStyle$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateStyle()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateStyle$Response(params?: {
    context?: HttpContext
    body?: InputUpdateStyleDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementUpdateStylePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateStyle$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateStyle(params?: {
    context?: HttpContext
    body?: InputUpdateStyleDto
  }
): Observable<boolean> {

    return this.itemManagementUpdateStyle$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation itemManagementGetAllStyles
   */
  static readonly ItemManagementGetAllStylesPath = '/api/admin/ItemManagement/GetAllStyles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllStyles$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllStyles$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllStylesDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementGetAllStylesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllStylesDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllStyles$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllStyles$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllStylesDto>> {

    return this.itemManagementGetAllStyles$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllStylesDto>>) => r.body as Array<OutputGetAllStylesDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllStyles()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllStyles$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllStylesDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementGetAllStylesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllStylesDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllStyles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllStyles(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllStylesDto>> {

    return this.itemManagementGetAllStyles$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllStylesDto>>) => r.body as Array<OutputGetAllStylesDto>)
    );
  }

  /**
   * Path part for operation itemManagementCreateGenderLink
   */
  static readonly ItemManagementCreateGenderLinkPath = '/api/admin/ItemManagement/CreateGenderLink';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateGenderLink$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateGenderLink$Plain$Response(params?: {
    context?: HttpContext
    body?: InputCreateGenderLinkDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementCreateGenderLinkPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateGenderLink$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateGenderLink$Plain(params?: {
    context?: HttpContext
    body?: InputCreateGenderLinkDto
  }
): Observable<boolean> {

    return this.itemManagementCreateGenderLink$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateGenderLink()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateGenderLink$Response(params?: {
    context?: HttpContext
    body?: InputCreateGenderLinkDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementCreateGenderLinkPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateGenderLink$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateGenderLink(params?: {
    context?: HttpContext
    body?: InputCreateGenderLinkDto
  }
): Observable<boolean> {

    return this.itemManagementCreateGenderLink$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation itemManagementDeleteGenderLink
   */
  static readonly ItemManagementDeleteGenderLinkPath = '/api/admin/ItemManagement/DeleteGenderLink';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteGenderLink$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementDeleteGenderLink$Plain$Response(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementDeleteGenderLinkPath, 'delete');
    if (params) {
      rb.query('id', params.id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteGenderLink$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementDeleteGenderLink$Plain(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.itemManagementDeleteGenderLink$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteGenderLink()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementDeleteGenderLink$Response(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementDeleteGenderLinkPath, 'delete');
    if (params) {
      rb.query('id', params.id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteGenderLink$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementDeleteGenderLink(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.itemManagementDeleteGenderLink$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation itemManagementUpdateGenderLink
   */
  static readonly ItemManagementUpdateGenderLinkPath = '/api/admin/ItemManagement/UpdateGenderLink';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateGenderLink$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateGenderLink$Plain$Response(params?: {
    context?: HttpContext
    body?: InputUpdateGenderLinkDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementUpdateGenderLinkPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateGenderLink$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateGenderLink$Plain(params?: {
    context?: HttpContext
    body?: InputUpdateGenderLinkDto
  }
): Observable<boolean> {

    return this.itemManagementUpdateGenderLink$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateGenderLink()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateGenderLink$Response(params?: {
    context?: HttpContext
    body?: InputUpdateGenderLinkDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementUpdateGenderLinkPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateGenderLink$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateGenderLink(params?: {
    context?: HttpContext
    body?: InputUpdateGenderLinkDto
  }
): Observable<boolean> {

    return this.itemManagementUpdateGenderLink$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation itemManagementGetAllGenderLink
   */
  static readonly ItemManagementGetAllGenderLinkPath = '/api/admin/ItemManagement/GetAllGenderLink';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllGenderLink$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllGenderLink$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllGenderLinkDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementGetAllGenderLinkPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllGenderLinkDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllGenderLink$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllGenderLink$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllGenderLinkDto>> {

    return this.itemManagementGetAllGenderLink$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllGenderLinkDto>>) => r.body as Array<OutputGetAllGenderLinkDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllGenderLink()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllGenderLink$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllGenderLinkDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementGetAllGenderLinkPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllGenderLinkDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllGenderLink$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllGenderLink(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllGenderLinkDto>> {

    return this.itemManagementGetAllGenderLink$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllGenderLinkDto>>) => r.body as Array<OutputGetAllGenderLinkDto>)
    );
  }

  /**
   * Path part for operation itemManagementGetAllDataRequestsForAdmin
   */
  static readonly ItemManagementGetAllDataRequestsForAdminPath = '/api/admin/ItemManagement/GetAllDataRequestsForAdmin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllDataRequestsForAdmin$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllDataRequestsForAdmin$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllDataRequestsForAdminDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementGetAllDataRequestsForAdminPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllDataRequestsForAdminDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllDataRequestsForAdmin$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllDataRequestsForAdmin$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllDataRequestsForAdminDto>> {

    return this.itemManagementGetAllDataRequestsForAdmin$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllDataRequestsForAdminDto>>) => r.body as Array<OutputGetAllDataRequestsForAdminDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllDataRequestsForAdmin()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllDataRequestsForAdmin$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllDataRequestsForAdminDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementGetAllDataRequestsForAdminPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllDataRequestsForAdminDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllDataRequestsForAdmin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllDataRequestsForAdmin(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllDataRequestsForAdminDto>> {

    return this.itemManagementGetAllDataRequestsForAdmin$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllDataRequestsForAdminDto>>) => r.body as Array<OutputGetAllDataRequestsForAdminDto>)
    );
  }

  /**
   * Path part for operation itemManagementInputUpdateDataRequestById
   */
  static readonly ItemManagementInputUpdateDataRequestByIdPath = '/api/admin/ItemManagement/InputUpdateDataRequestById';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementInputUpdateDataRequestById$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementInputUpdateDataRequestById$Plain$Response(params?: {
    context?: HttpContext
    body?: InputUpdateDataRequestByIdDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementInputUpdateDataRequestByIdPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementInputUpdateDataRequestById$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementInputUpdateDataRequestById$Plain(params?: {
    context?: HttpContext
    body?: InputUpdateDataRequestByIdDto
  }
): Observable<boolean> {

    return this.itemManagementInputUpdateDataRequestById$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementInputUpdateDataRequestById()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementInputUpdateDataRequestById$Response(params?: {
    context?: HttpContext
    body?: InputUpdateDataRequestByIdDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementInputUpdateDataRequestByIdPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementInputUpdateDataRequestById$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementInputUpdateDataRequestById(params?: {
    context?: HttpContext
    body?: InputUpdateDataRequestByIdDto
  }
): Observable<boolean> {

    return this.itemManagementInputUpdateDataRequestById$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation itemManagementCreateFilterFeature
   */
  static readonly ItemManagementCreateFilterFeaturePath = '/api/admin/ItemManagement/CreateFilterFeature';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateFilterFeature$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateFilterFeature$Plain$Response(params?: {
    context?: HttpContext
    body?: InputCreateFilterFeatureDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementCreateFilterFeaturePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateFilterFeature$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateFilterFeature$Plain(params?: {
    context?: HttpContext
    body?: InputCreateFilterFeatureDto
  }
): Observable<boolean> {

    return this.itemManagementCreateFilterFeature$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateFilterFeature()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateFilterFeature$Response(params?: {
    context?: HttpContext
    body?: InputCreateFilterFeatureDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementCreateFilterFeaturePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateFilterFeature$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateFilterFeature(params?: {
    context?: HttpContext
    body?: InputCreateFilterFeatureDto
  }
): Observable<boolean> {

    return this.itemManagementCreateFilterFeature$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation itemManagementDeleteFilterFeature
   */
  static readonly ItemManagementDeleteFilterFeaturePath = '/api/admin/ItemManagement/DeleteFilterFeature';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteFilterFeature$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementDeleteFilterFeature$Plain$Response(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementDeleteFilterFeaturePath, 'delete');
    if (params) {
      rb.query('id', params.id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteFilterFeature$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementDeleteFilterFeature$Plain(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.itemManagementDeleteFilterFeature$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteFilterFeature()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementDeleteFilterFeature$Response(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementDeleteFilterFeaturePath, 'delete');
    if (params) {
      rb.query('id', params.id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteFilterFeature$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementDeleteFilterFeature(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.itemManagementDeleteFilterFeature$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation itemManagementUpdateFilterFeature
   */
  static readonly ItemManagementUpdateFilterFeaturePath = '/api/admin/ItemManagement/UpdateFilterFeature';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateFilterFeature$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateFilterFeature$Plain$Response(params?: {
    context?: HttpContext
    body?: InputUpdateFilterFeatureDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementUpdateFilterFeaturePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateFilterFeature$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateFilterFeature$Plain(params?: {
    context?: HttpContext
    body?: InputUpdateFilterFeatureDto
  }
): Observable<boolean> {

    return this.itemManagementUpdateFilterFeature$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateFilterFeature()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateFilterFeature$Response(params?: {
    context?: HttpContext
    body?: InputUpdateFilterFeatureDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementUpdateFilterFeaturePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateFilterFeature$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateFilterFeature(params?: {
    context?: HttpContext
    body?: InputUpdateFilterFeatureDto
  }
): Observable<boolean> {

    return this.itemManagementUpdateFilterFeature$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation itemManagementGetAllFilterFeature
   */
  static readonly ItemManagementGetAllFilterFeaturePath = '/api/admin/ItemManagement/GetAllFilterFeature';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllFilterFeature$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllFilterFeature$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllFilterFeatureDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementGetAllFilterFeaturePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllFilterFeatureDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllFilterFeature$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllFilterFeature$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllFilterFeatureDto>> {

    return this.itemManagementGetAllFilterFeature$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllFilterFeatureDto>>) => r.body as Array<OutputGetAllFilterFeatureDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllFilterFeature()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllFilterFeature$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllFilterFeatureDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementGetAllFilterFeaturePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllFilterFeatureDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllFilterFeature$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllFilterFeature(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllFilterFeatureDto>> {

    return this.itemManagementGetAllFilterFeature$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllFilterFeatureDto>>) => r.body as Array<OutputGetAllFilterFeatureDto>)
    );
  }

  /**
   * Path part for operation itemManagementCreateFilterFeatureContent
   */
  static readonly ItemManagementCreateFilterFeatureContentPath = '/api/admin/ItemManagement/CreateFilterFeatureContent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateFilterFeatureContent$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateFilterFeatureContent$Plain$Response(params?: {
    context?: HttpContext
    body?: InputCreateFilterFeatureContentDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementCreateFilterFeatureContentPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateFilterFeatureContent$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateFilterFeatureContent$Plain(params?: {
    context?: HttpContext
    body?: InputCreateFilterFeatureContentDto
  }
): Observable<boolean> {

    return this.itemManagementCreateFilterFeatureContent$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementCreateFilterFeatureContent()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateFilterFeatureContent$Response(params?: {
    context?: HttpContext
    body?: InputCreateFilterFeatureContentDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementCreateFilterFeatureContentPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementCreateFilterFeatureContent$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementCreateFilterFeatureContent(params?: {
    context?: HttpContext
    body?: InputCreateFilterFeatureContentDto
  }
): Observable<boolean> {

    return this.itemManagementCreateFilterFeatureContent$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation itemManagementDeleteFilterFeatureContent
   */
  static readonly ItemManagementDeleteFilterFeatureContentPath = '/api/admin/ItemManagement/DeleteFilterFeatureContent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteFilterFeatureContent$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementDeleteFilterFeatureContent$Plain$Response(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementDeleteFilterFeatureContentPath, 'delete');
    if (params) {
      rb.query('id', params.id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteFilterFeatureContent$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementDeleteFilterFeatureContent$Plain(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.itemManagementDeleteFilterFeatureContent$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementDeleteFilterFeatureContent()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementDeleteFilterFeatureContent$Response(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementDeleteFilterFeatureContentPath, 'delete');
    if (params) {
      rb.query('id', params.id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementDeleteFilterFeatureContent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementDeleteFilterFeatureContent(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.itemManagementDeleteFilterFeatureContent$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation itemManagementUpdateFilterFeatureContent
   */
  static readonly ItemManagementUpdateFilterFeatureContentPath = '/api/admin/ItemManagement/UpdateFilterFeatureContent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateFilterFeatureContent$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateFilterFeatureContent$Plain$Response(params?: {
    context?: HttpContext
    body?: InputUpdateFilterFeatureContentDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementUpdateFilterFeatureContentPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateFilterFeatureContent$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateFilterFeatureContent$Plain(params?: {
    context?: HttpContext
    body?: InputUpdateFilterFeatureContentDto
  }
): Observable<boolean> {

    return this.itemManagementUpdateFilterFeatureContent$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementUpdateFilterFeatureContent()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateFilterFeatureContent$Response(params?: {
    context?: HttpContext
    body?: InputUpdateFilterFeatureContentDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementUpdateFilterFeatureContentPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementUpdateFilterFeatureContent$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  itemManagementUpdateFilterFeatureContent(params?: {
    context?: HttpContext
    body?: InputUpdateFilterFeatureContentDto
  }
): Observable<boolean> {

    return this.itemManagementUpdateFilterFeatureContent$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation itemManagementGetAllFilterFeatureContent
   */
  static readonly ItemManagementGetAllFilterFeatureContentPath = '/api/admin/ItemManagement/GetAllFilterFeatureContent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllFilterFeatureContent$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllFilterFeatureContent$Plain$Response(params?: {
    filterFeatureId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllFilterFeatureContentDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementGetAllFilterFeatureContentPath, 'get');
    if (params) {
      rb.query('filterFeatureId', params.filterFeatureId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllFilterFeatureContentDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllFilterFeatureContent$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllFilterFeatureContent$Plain(params?: {
    filterFeatureId?: number;
    context?: HttpContext
  }
): Observable<Array<OutputGetAllFilterFeatureContentDto>> {

    return this.itemManagementGetAllFilterFeatureContent$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllFilterFeatureContentDto>>) => r.body as Array<OutputGetAllFilterFeatureContentDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllFilterFeatureContent()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllFilterFeatureContent$Response(params?: {
    filterFeatureId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllFilterFeatureContentDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementGetAllFilterFeatureContentPath, 'get');
    if (params) {
      rb.query('filterFeatureId', params.filterFeatureId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllFilterFeatureContentDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllFilterFeatureContent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllFilterFeatureContent(params?: {
    filterFeatureId?: number;
    context?: HttpContext
  }
): Observable<Array<OutputGetAllFilterFeatureContentDto>> {

    return this.itemManagementGetAllFilterFeatureContent$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllFilterFeatureContentDto>>) => r.body as Array<OutputGetAllFilterFeatureContentDto>)
    );
  }

  /**
   * Path part for operation itemManagementGetAllFeatureWithContent
   */
  static readonly ItemManagementGetAllFeatureWithContentPath = '/api/admin/ItemManagement/GetAllFeatureWithContent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllFeatureWithContent$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllFeatureWithContent$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllFeatureWithContent>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementGetAllFeatureWithContentPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllFeatureWithContent>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllFeatureWithContent$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllFeatureWithContent$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllFeatureWithContent>> {

    return this.itemManagementGetAllFeatureWithContent$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllFeatureWithContent>>) => r.body as Array<OutputGetAllFeatureWithContent>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itemManagementGetAllFeatureWithContent()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllFeatureWithContent$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllFeatureWithContent>>> {

    const rb = new RequestBuilder(this.rootUrl, ItemManagementService.ItemManagementGetAllFeatureWithContentPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllFeatureWithContent>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itemManagementGetAllFeatureWithContent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itemManagementGetAllFeatureWithContent(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllFeatureWithContent>> {

    return this.itemManagementGetAllFeatureWithContent$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllFeatureWithContent>>) => r.body as Array<OutputGetAllFeatureWithContent>)
    );
  }

}
