/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { OutputGetAllChartDataDto } from '../models/output-get-all-chart-data-dto';

@Injectable({
  providedIn: 'root',
})
export class DashboardService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation dashboardGetChartData
   */
  static readonly DashboardGetChartDataPath = '/api/Merchant/Dashboard/GetChartData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dashboardGetChartData$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  dashboardGetChartData$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetAllChartDataDto>> {

    const rb = new RequestBuilder(this.rootUrl, DashboardService.DashboardGetChartDataPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetAllChartDataDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `dashboardGetChartData$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  dashboardGetChartData$Plain(params?: {
    context?: HttpContext
  }
): Observable<OutputGetAllChartDataDto> {

    return this.dashboardGetChartData$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetAllChartDataDto>) => r.body as OutputGetAllChartDataDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dashboardGetChartData()` instead.
   *
   * This method doesn't expect any request body.
   */
  dashboardGetChartData$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetAllChartDataDto>> {

    const rb = new RequestBuilder(this.rootUrl, DashboardService.DashboardGetChartDataPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetAllChartDataDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `dashboardGetChartData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  dashboardGetChartData(params?: {
    context?: HttpContext
  }
): Observable<OutputGetAllChartDataDto> {

    return this.dashboardGetChartData$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetAllChartDataDto>) => r.body as OutputGetAllChartDataDto)
    );
  }

  /**
   * Path part for operation dashboardGetChartDataForAdmin
   */
  static readonly DashboardGetChartDataForAdminPath = '/api/admin/Dashboard/GetChartDataForAdmin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dashboardGetChartDataForAdmin$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  dashboardGetChartDataForAdmin$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetAllChartDataDto>> {

    const rb = new RequestBuilder(this.rootUrl, DashboardService.DashboardGetChartDataForAdminPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetAllChartDataDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `dashboardGetChartDataForAdmin$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  dashboardGetChartDataForAdmin$Plain(params?: {
    context?: HttpContext
  }
): Observable<OutputGetAllChartDataDto> {

    return this.dashboardGetChartDataForAdmin$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetAllChartDataDto>) => r.body as OutputGetAllChartDataDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dashboardGetChartDataForAdmin()` instead.
   *
   * This method doesn't expect any request body.
   */
  dashboardGetChartDataForAdmin$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetAllChartDataDto>> {

    const rb = new RequestBuilder(this.rootUrl, DashboardService.DashboardGetChartDataForAdminPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetAllChartDataDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `dashboardGetChartDataForAdmin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  dashboardGetChartDataForAdmin(params?: {
    context?: HttpContext
  }
): Observable<OutputGetAllChartDataDto> {

    return this.dashboardGetChartDataForAdmin$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetAllChartDataDto>) => r.body as OutputGetAllChartDataDto)
    );
  }

}
