/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { InputCreateBundleDto } from '../models/input-create-bundle-dto';
import { InputCreateOfferDto } from '../models/input-create-offer-dto';
import { InputCreateTagsDto } from '../models/input-create-tags-dto';
import { InputDeleteBundleDto } from '../models/input-delete-bundle-dto';
import { InputDeleteOfferDto } from '../models/input-delete-offer-dto';
import { InputDeleteTagsDto } from '../models/input-delete-tags-dto';
import { InputUpdateBundleDto } from '../models/input-update-bundle-dto';
import { InputUpdateOfferDto } from '../models/input-update-offer-dto';
import { InputUpdateTagsDto } from '../models/input-update-tags-dto';
import { OutputGetAllBundleDto } from '../models/output-get-all-bundle-dto';
import { OutputGetAllOfferDto } from '../models/output-get-all-offer-dto';
import { OutputGetAllTagsDto } from '../models/output-get-all-tags-dto';
import { OutputGetBundleDetailDto } from '../models/output-get-bundle-detail-dto';

@Injectable({
  providedIn: 'root',
})
export class OfferManagementService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation offerManagementGetAllBundleWithItem
   */
  static readonly OfferManagementGetAllBundleWithItemPath = '/api/admin/OfferManagement/GetAllBundleWithItem';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementGetAllBundleWithItem$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  offerManagementGetAllBundleWithItem$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetBundleDetailDto>>> {

    const rb = new RequestBuilder(this.rootUrl, OfferManagementService.OfferManagementGetAllBundleWithItemPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetBundleDetailDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `offerManagementGetAllBundleWithItem$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offerManagementGetAllBundleWithItem$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetBundleDetailDto>> {

    return this.offerManagementGetAllBundleWithItem$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetBundleDetailDto>>) => r.body as Array<OutputGetBundleDetailDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementGetAllBundleWithItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  offerManagementGetAllBundleWithItem$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetBundleDetailDto>>> {

    const rb = new RequestBuilder(this.rootUrl, OfferManagementService.OfferManagementGetAllBundleWithItemPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetBundleDetailDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `offerManagementGetAllBundleWithItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offerManagementGetAllBundleWithItem(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetBundleDetailDto>> {

    return this.offerManagementGetAllBundleWithItem$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetBundleDetailDto>>) => r.body as Array<OutputGetBundleDetailDto>)
    );
  }

  /**
   * Path part for operation offerManagementCreateBundle
   */
  static readonly OfferManagementCreateBundlePath = '/api/admin/OfferManagement/CreateBundle';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementCreateBundle$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementCreateBundle$Plain$Response(params?: {
    context?: HttpContext
    body?: InputCreateBundleDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, OfferManagementService.OfferManagementCreateBundlePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `offerManagementCreateBundle$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementCreateBundle$Plain(params?: {
    context?: HttpContext
    body?: InputCreateBundleDto
  }
): Observable<boolean> {

    return this.offerManagementCreateBundle$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementCreateBundle()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementCreateBundle$Response(params?: {
    context?: HttpContext
    body?: InputCreateBundleDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, OfferManagementService.OfferManagementCreateBundlePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `offerManagementCreateBundle$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementCreateBundle(params?: {
    context?: HttpContext
    body?: InputCreateBundleDto
  }
): Observable<boolean> {

    return this.offerManagementCreateBundle$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation offerManagementUpdateBundle
   */
  static readonly OfferManagementUpdateBundlePath = '/api/admin/OfferManagement/UpdateBundle';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementUpdateBundle$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementUpdateBundle$Plain$Response(params?: {
    context?: HttpContext
    body?: InputUpdateBundleDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, OfferManagementService.OfferManagementUpdateBundlePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `offerManagementUpdateBundle$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementUpdateBundle$Plain(params?: {
    context?: HttpContext
    body?: InputUpdateBundleDto
  }
): Observable<boolean> {

    return this.offerManagementUpdateBundle$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementUpdateBundle()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementUpdateBundle$Response(params?: {
    context?: HttpContext
    body?: InputUpdateBundleDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, OfferManagementService.OfferManagementUpdateBundlePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `offerManagementUpdateBundle$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementUpdateBundle(params?: {
    context?: HttpContext
    body?: InputUpdateBundleDto
  }
): Observable<boolean> {

    return this.offerManagementUpdateBundle$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation offerManagementGetAllBundles
   */
  static readonly OfferManagementGetAllBundlesPath = '/api/admin/OfferManagement/GetAllBundles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementGetAllBundles$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  offerManagementGetAllBundles$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllBundleDto>>> {

    const rb = new RequestBuilder(this.rootUrl, OfferManagementService.OfferManagementGetAllBundlesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllBundleDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `offerManagementGetAllBundles$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offerManagementGetAllBundles$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllBundleDto>> {

    return this.offerManagementGetAllBundles$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllBundleDto>>) => r.body as Array<OutputGetAllBundleDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementGetAllBundles()` instead.
   *
   * This method doesn't expect any request body.
   */
  offerManagementGetAllBundles$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllBundleDto>>> {

    const rb = new RequestBuilder(this.rootUrl, OfferManagementService.OfferManagementGetAllBundlesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllBundleDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `offerManagementGetAllBundles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offerManagementGetAllBundles(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllBundleDto>> {

    return this.offerManagementGetAllBundles$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllBundleDto>>) => r.body as Array<OutputGetAllBundleDto>)
    );
  }

  /**
   * Path part for operation offerManagementDeleteBundle
   */
  static readonly OfferManagementDeleteBundlePath = '/api/admin/OfferManagement/DeleteBundle';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementDeleteBundle$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementDeleteBundle$Plain$Response(params?: {
    context?: HttpContext
    body?: InputDeleteBundleDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, OfferManagementService.OfferManagementDeleteBundlePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `offerManagementDeleteBundle$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementDeleteBundle$Plain(params?: {
    context?: HttpContext
    body?: InputDeleteBundleDto
  }
): Observable<boolean> {

    return this.offerManagementDeleteBundle$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementDeleteBundle()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementDeleteBundle$Response(params?: {
    context?: HttpContext
    body?: InputDeleteBundleDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, OfferManagementService.OfferManagementDeleteBundlePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `offerManagementDeleteBundle$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementDeleteBundle(params?: {
    context?: HttpContext
    body?: InputDeleteBundleDto
  }
): Observable<boolean> {

    return this.offerManagementDeleteBundle$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation offerManagementCreateOffer
   */
  static readonly OfferManagementCreateOfferPath = '/api/admin/OfferManagement/CreateOffer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementCreateOffer$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementCreateOffer$Plain$Response(params?: {
    context?: HttpContext
    body?: InputCreateOfferDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, OfferManagementService.OfferManagementCreateOfferPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `offerManagementCreateOffer$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementCreateOffer$Plain(params?: {
    context?: HttpContext
    body?: InputCreateOfferDto
  }
): Observable<boolean> {

    return this.offerManagementCreateOffer$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementCreateOffer()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementCreateOffer$Response(params?: {
    context?: HttpContext
    body?: InputCreateOfferDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, OfferManagementService.OfferManagementCreateOfferPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `offerManagementCreateOffer$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementCreateOffer(params?: {
    context?: HttpContext
    body?: InputCreateOfferDto
  }
): Observable<boolean> {

    return this.offerManagementCreateOffer$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation offerManagementUpdateOffer
   */
  static readonly OfferManagementUpdateOfferPath = '/api/admin/OfferManagement/UpdateOffer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementUpdateOffer$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementUpdateOffer$Plain$Response(params?: {
    context?: HttpContext
    body?: InputUpdateOfferDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, OfferManagementService.OfferManagementUpdateOfferPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `offerManagementUpdateOffer$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementUpdateOffer$Plain(params?: {
    context?: HttpContext
    body?: InputUpdateOfferDto
  }
): Observable<boolean> {

    return this.offerManagementUpdateOffer$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementUpdateOffer()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementUpdateOffer$Response(params?: {
    context?: HttpContext
    body?: InputUpdateOfferDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, OfferManagementService.OfferManagementUpdateOfferPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `offerManagementUpdateOffer$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementUpdateOffer(params?: {
    context?: HttpContext
    body?: InputUpdateOfferDto
  }
): Observable<boolean> {

    return this.offerManagementUpdateOffer$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation offerManagementGetAllOffers
   */
  static readonly OfferManagementGetAllOffersPath = '/api/admin/OfferManagement/GetAllOffers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementGetAllOffers$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  offerManagementGetAllOffers$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllOfferDto>>> {

    const rb = new RequestBuilder(this.rootUrl, OfferManagementService.OfferManagementGetAllOffersPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllOfferDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `offerManagementGetAllOffers$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offerManagementGetAllOffers$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllOfferDto>> {

    return this.offerManagementGetAllOffers$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllOfferDto>>) => r.body as Array<OutputGetAllOfferDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementGetAllOffers()` instead.
   *
   * This method doesn't expect any request body.
   */
  offerManagementGetAllOffers$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllOfferDto>>> {

    const rb = new RequestBuilder(this.rootUrl, OfferManagementService.OfferManagementGetAllOffersPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllOfferDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `offerManagementGetAllOffers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offerManagementGetAllOffers(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllOfferDto>> {

    return this.offerManagementGetAllOffers$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllOfferDto>>) => r.body as Array<OutputGetAllOfferDto>)
    );
  }

  /**
   * Path part for operation offerManagementDeleteOffer
   */
  static readonly OfferManagementDeleteOfferPath = '/api/admin/OfferManagement/DeleteOffer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementDeleteOffer$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementDeleteOffer$Plain$Response(params?: {
    context?: HttpContext
    body?: InputDeleteOfferDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, OfferManagementService.OfferManagementDeleteOfferPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `offerManagementDeleteOffer$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementDeleteOffer$Plain(params?: {
    context?: HttpContext
    body?: InputDeleteOfferDto
  }
): Observable<boolean> {

    return this.offerManagementDeleteOffer$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementDeleteOffer()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementDeleteOffer$Response(params?: {
    context?: HttpContext
    body?: InputDeleteOfferDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, OfferManagementService.OfferManagementDeleteOfferPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `offerManagementDeleteOffer$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementDeleteOffer(params?: {
    context?: HttpContext
    body?: InputDeleteOfferDto
  }
): Observable<boolean> {

    return this.offerManagementDeleteOffer$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation offerManagementCreateTags
   */
  static readonly OfferManagementCreateTagsPath = '/api/admin/OfferManagement/CreateTags';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementCreateTags$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementCreateTags$Plain$Response(params?: {
    context?: HttpContext
    body?: InputCreateTagsDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, OfferManagementService.OfferManagementCreateTagsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `offerManagementCreateTags$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementCreateTags$Plain(params?: {
    context?: HttpContext
    body?: InputCreateTagsDto
  }
): Observable<boolean> {

    return this.offerManagementCreateTags$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementCreateTags()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementCreateTags$Response(params?: {
    context?: HttpContext
    body?: InputCreateTagsDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, OfferManagementService.OfferManagementCreateTagsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `offerManagementCreateTags$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementCreateTags(params?: {
    context?: HttpContext
    body?: InputCreateTagsDto
  }
): Observable<boolean> {

    return this.offerManagementCreateTags$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation offerManagementDeleteTags
   */
  static readonly OfferManagementDeleteTagsPath = '/api/admin/OfferManagement/DeleteTags';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementDeleteTags$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementDeleteTags$Plain$Response(params?: {
    context?: HttpContext
    body?: InputDeleteTagsDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, OfferManagementService.OfferManagementDeleteTagsPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `offerManagementDeleteTags$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementDeleteTags$Plain(params?: {
    context?: HttpContext
    body?: InputDeleteTagsDto
  }
): Observable<boolean> {

    return this.offerManagementDeleteTags$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementDeleteTags()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementDeleteTags$Response(params?: {
    context?: HttpContext
    body?: InputDeleteTagsDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, OfferManagementService.OfferManagementDeleteTagsPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `offerManagementDeleteTags$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementDeleteTags(params?: {
    context?: HttpContext
    body?: InputDeleteTagsDto
  }
): Observable<boolean> {

    return this.offerManagementDeleteTags$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation offerManagementUpdateTags
   */
  static readonly OfferManagementUpdateTagsPath = '/api/admin/OfferManagement/UpdateTags';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementUpdateTags$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementUpdateTags$Plain$Response(params?: {
    context?: HttpContext
    body?: InputUpdateTagsDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, OfferManagementService.OfferManagementUpdateTagsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `offerManagementUpdateTags$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementUpdateTags$Plain(params?: {
    context?: HttpContext
    body?: InputUpdateTagsDto
  }
): Observable<boolean> {

    return this.offerManagementUpdateTags$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementUpdateTags()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementUpdateTags$Response(params?: {
    context?: HttpContext
    body?: InputUpdateTagsDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, OfferManagementService.OfferManagementUpdateTagsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `offerManagementUpdateTags$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offerManagementUpdateTags(params?: {
    context?: HttpContext
    body?: InputUpdateTagsDto
  }
): Observable<boolean> {

    return this.offerManagementUpdateTags$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation offerManagementGetAllTags
   */
  static readonly OfferManagementGetAllTagsPath = '/api/admin/OfferManagement/GetAllTags';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementGetAllTags$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  offerManagementGetAllTags$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllTagsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, OfferManagementService.OfferManagementGetAllTagsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllTagsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `offerManagementGetAllTags$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offerManagementGetAllTags$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllTagsDto>> {

    return this.offerManagementGetAllTags$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllTagsDto>>) => r.body as Array<OutputGetAllTagsDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerManagementGetAllTags()` instead.
   *
   * This method doesn't expect any request body.
   */
  offerManagementGetAllTags$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllTagsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, OfferManagementService.OfferManagementGetAllTagsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllTagsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `offerManagementGetAllTags$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offerManagementGetAllTags(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllTagsDto>> {

    return this.offerManagementGetAllTags$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllTagsDto>>) => r.body as Array<OutputGetAllTagsDto>)
    );
  }

}
