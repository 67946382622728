/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { InputCreateFreelanceServiceDto } from '../models/input-create-freelance-service-dto';
import { InputCreateServiceCategoryDto } from '../models/input-create-service-category-dto';
import { InputUpdateFreelanceServiceDto } from '../models/input-update-freelance-service-dto';
import { InputUpdateServiceCategoryDto } from '../models/input-update-service-category-dto';
import { OutputGetAllFreelanceServiceDto } from '../models/output-get-all-freelance-service-dto';
import { OutputGetAllServiceCategoryDto } from '../models/output-get-all-service-category-dto';

@Injectable({
  providedIn: 'root',
})
export class ServiceManagementService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation serviceManagementCreateServiceCategory
   */
  static readonly ServiceManagementCreateServiceCategoryPath = '/api/admin/ServiceManagement/CreateServiceCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceManagementCreateServiceCategory$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceManagementCreateServiceCategory$Plain$Response(params?: {
    context?: HttpContext
    body?: InputCreateServiceCategoryDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ServiceManagementService.ServiceManagementCreateServiceCategoryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `serviceManagementCreateServiceCategory$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceManagementCreateServiceCategory$Plain(params?: {
    context?: HttpContext
    body?: InputCreateServiceCategoryDto
  }
): Observable<boolean> {

    return this.serviceManagementCreateServiceCategory$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceManagementCreateServiceCategory()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceManagementCreateServiceCategory$Response(params?: {
    context?: HttpContext
    body?: InputCreateServiceCategoryDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ServiceManagementService.ServiceManagementCreateServiceCategoryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `serviceManagementCreateServiceCategory$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceManagementCreateServiceCategory(params?: {
    context?: HttpContext
    body?: InputCreateServiceCategoryDto
  }
): Observable<boolean> {

    return this.serviceManagementCreateServiceCategory$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation serviceManagementDeleteServiceCategory
   */
  static readonly ServiceManagementDeleteServiceCategoryPath = '/api/admin/ServiceManagement/DeleteServiceCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceManagementDeleteServiceCategory$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceManagementDeleteServiceCategory$Plain$Response(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ServiceManagementService.ServiceManagementDeleteServiceCategoryPath, 'delete');
    if (params) {
      rb.query('id', params.id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `serviceManagementDeleteServiceCategory$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceManagementDeleteServiceCategory$Plain(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.serviceManagementDeleteServiceCategory$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceManagementDeleteServiceCategory()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceManagementDeleteServiceCategory$Response(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ServiceManagementService.ServiceManagementDeleteServiceCategoryPath, 'delete');
    if (params) {
      rb.query('id', params.id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `serviceManagementDeleteServiceCategory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceManagementDeleteServiceCategory(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.serviceManagementDeleteServiceCategory$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation serviceManagementUpdateServiceCategory
   */
  static readonly ServiceManagementUpdateServiceCategoryPath = '/api/admin/ServiceManagement/UpdateServiceCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceManagementUpdateServiceCategory$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceManagementUpdateServiceCategory$Plain$Response(params?: {
    context?: HttpContext
    body?: InputUpdateServiceCategoryDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ServiceManagementService.ServiceManagementUpdateServiceCategoryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `serviceManagementUpdateServiceCategory$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceManagementUpdateServiceCategory$Plain(params?: {
    context?: HttpContext
    body?: InputUpdateServiceCategoryDto
  }
): Observable<boolean> {

    return this.serviceManagementUpdateServiceCategory$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceManagementUpdateServiceCategory()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceManagementUpdateServiceCategory$Response(params?: {
    context?: HttpContext
    body?: InputUpdateServiceCategoryDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ServiceManagementService.ServiceManagementUpdateServiceCategoryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `serviceManagementUpdateServiceCategory$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceManagementUpdateServiceCategory(params?: {
    context?: HttpContext
    body?: InputUpdateServiceCategoryDto
  }
): Observable<boolean> {

    return this.serviceManagementUpdateServiceCategory$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation serviceManagementGetAllServiceCategory
   */
  static readonly ServiceManagementGetAllServiceCategoryPath = '/api/admin/ServiceManagement/GetAllServiceCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceManagementGetAllServiceCategory$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceManagementGetAllServiceCategory$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllServiceCategoryDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ServiceManagementService.ServiceManagementGetAllServiceCategoryPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllServiceCategoryDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `serviceManagementGetAllServiceCategory$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceManagementGetAllServiceCategory$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllServiceCategoryDto>> {

    return this.serviceManagementGetAllServiceCategory$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllServiceCategoryDto>>) => r.body as Array<OutputGetAllServiceCategoryDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceManagementGetAllServiceCategory()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceManagementGetAllServiceCategory$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllServiceCategoryDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ServiceManagementService.ServiceManagementGetAllServiceCategoryPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllServiceCategoryDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `serviceManagementGetAllServiceCategory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceManagementGetAllServiceCategory(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllServiceCategoryDto>> {

    return this.serviceManagementGetAllServiceCategory$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllServiceCategoryDto>>) => r.body as Array<OutputGetAllServiceCategoryDto>)
    );
  }

  /**
   * Path part for operation serviceManagementCreateFreelanceService
   */
  static readonly ServiceManagementCreateFreelanceServicePath = '/api/admin/ServiceManagement/CreateFreelanceService';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceManagementCreateFreelanceService$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceManagementCreateFreelanceService$Plain$Response(params?: {
    context?: HttpContext
    body?: InputCreateFreelanceServiceDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ServiceManagementService.ServiceManagementCreateFreelanceServicePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `serviceManagementCreateFreelanceService$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceManagementCreateFreelanceService$Plain(params?: {
    context?: HttpContext
    body?: InputCreateFreelanceServiceDto
  }
): Observable<boolean> {

    return this.serviceManagementCreateFreelanceService$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceManagementCreateFreelanceService()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceManagementCreateFreelanceService$Response(params?: {
    context?: HttpContext
    body?: InputCreateFreelanceServiceDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ServiceManagementService.ServiceManagementCreateFreelanceServicePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `serviceManagementCreateFreelanceService$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceManagementCreateFreelanceService(params?: {
    context?: HttpContext
    body?: InputCreateFreelanceServiceDto
  }
): Observable<boolean> {

    return this.serviceManagementCreateFreelanceService$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation serviceManagementDeleteFreelanceService
   */
  static readonly ServiceManagementDeleteFreelanceServicePath = '/api/admin/ServiceManagement/DeleteFreelanceService';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceManagementDeleteFreelanceService$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceManagementDeleteFreelanceService$Plain$Response(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ServiceManagementService.ServiceManagementDeleteFreelanceServicePath, 'delete');
    if (params) {
      rb.query('id', params.id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `serviceManagementDeleteFreelanceService$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceManagementDeleteFreelanceService$Plain(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.serviceManagementDeleteFreelanceService$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceManagementDeleteFreelanceService()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceManagementDeleteFreelanceService$Response(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ServiceManagementService.ServiceManagementDeleteFreelanceServicePath, 'delete');
    if (params) {
      rb.query('id', params.id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `serviceManagementDeleteFreelanceService$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceManagementDeleteFreelanceService(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.serviceManagementDeleteFreelanceService$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation serviceManagementUpdateFreelanceService
   */
  static readonly ServiceManagementUpdateFreelanceServicePath = '/api/admin/ServiceManagement/UpdateFreelanceService';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceManagementUpdateFreelanceService$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceManagementUpdateFreelanceService$Plain$Response(params?: {
    context?: HttpContext
    body?: InputUpdateFreelanceServiceDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ServiceManagementService.ServiceManagementUpdateFreelanceServicePath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `serviceManagementUpdateFreelanceService$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceManagementUpdateFreelanceService$Plain(params?: {
    context?: HttpContext
    body?: InputUpdateFreelanceServiceDto
  }
): Observable<boolean> {

    return this.serviceManagementUpdateFreelanceService$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceManagementUpdateFreelanceService()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceManagementUpdateFreelanceService$Response(params?: {
    context?: HttpContext
    body?: InputUpdateFreelanceServiceDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ServiceManagementService.ServiceManagementUpdateFreelanceServicePath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `serviceManagementUpdateFreelanceService$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  serviceManagementUpdateFreelanceService(params?: {
    context?: HttpContext
    body?: InputUpdateFreelanceServiceDto
  }
): Observable<boolean> {

    return this.serviceManagementUpdateFreelanceService$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation serviceManagementGetAllFreelanceService
   */
  static readonly ServiceManagementGetAllFreelanceServicePath = '/api/admin/ServiceManagement/GetAllFreelanceService';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceManagementGetAllFreelanceService$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceManagementGetAllFreelanceService$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllFreelanceServiceDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ServiceManagementService.ServiceManagementGetAllFreelanceServicePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllFreelanceServiceDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `serviceManagementGetAllFreelanceService$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceManagementGetAllFreelanceService$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllFreelanceServiceDto>> {

    return this.serviceManagementGetAllFreelanceService$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllFreelanceServiceDto>>) => r.body as Array<OutputGetAllFreelanceServiceDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceManagementGetAllFreelanceService()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceManagementGetAllFreelanceService$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllFreelanceServiceDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ServiceManagementService.ServiceManagementGetAllFreelanceServicePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllFreelanceServiceDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `serviceManagementGetAllFreelanceService$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceManagementGetAllFreelanceService(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllFreelanceServiceDto>> {

    return this.serviceManagementGetAllFreelanceService$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllFreelanceServiceDto>>) => r.body as Array<OutputGetAllFreelanceServiceDto>)
    );
  }

}
