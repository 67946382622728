/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GetAllStylistWithdrawalForAdminDto } from '../models/get-all-stylist-withdrawal-for-admin-dto';
import { InputCreateStylistFeatureDto } from '../models/input-create-stylist-feature-dto';
import { InputCreateStylistPackageDto } from '../models/input-create-stylist-package-dto';
import { InputCreateStylistPersonQuestionDto } from '../models/input-create-stylist-person-question-dto';
import { InputCreateStylistServiceQuestionDto } from '../models/input-create-stylist-service-question-dto';
import { InputDeleteStylistFeatureDto } from '../models/input-delete-stylist-feature-dto';
import { InputDeleteStylistPackageDto } from '../models/input-delete-stylist-package-dto';
import { InputStylistChangeStatues } from '../models/input-stylist-change-statues';
import { InputUpdateStylistEarningSettingDto } from '../models/input-update-stylist-earning-setting-dto';
import { InputUpdateStylistFeatureDto } from '../models/input-update-stylist-feature-dto';
import { InputUpdateStylistPackageDto } from '../models/input-update-stylist-package-dto';
import { InputUpdateStylistPersonQuestionDto } from '../models/input-update-stylist-person-question-dto';
import { InputUpdateStylistServiceQuestionDto } from '../models/input-update-stylist-service-question-dto';
import { InputUpdateStylistWithdrawalStatusDto } from '../models/input-update-stylist-withdrawal-status-dto';
import { OutputAllStylistsDto } from '../models/output-all-stylists-dto';
import { OutputGetAllStylistEarningSettingDto } from '../models/output-get-all-stylist-earning-setting-dto';
import { OutputGetAllStylistFeatureDto } from '../models/output-get-all-stylist-feature-dto';
import { OutputGetAllStylistPackageDto } from '../models/output-get-all-stylist-package-dto';
import { OutputGetAllStylistPersonQuestionDto } from '../models/output-get-all-stylist-person-question-dto';
import { OutputGetAllStylistServiceQuestionDto } from '../models/output-get-all-stylist-service-question-dto';
import { StylistDashboardDto } from '../models/stylist-dashboard-dto';

@Injectable({
  providedIn: 'root',
})
export class StylistManagementService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation stylistManagementCreateStylistRequestQuestion
   */
  static readonly StylistManagementCreateStylistRequestQuestionPath = '/api/admin/StylistManagement/CreateStylistRequestQuestion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementCreateStylistRequestQuestion()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementCreateStylistRequestQuestion$Response(params?: {
    context?: HttpContext
    body?: InputCreateStylistServiceQuestionDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, StylistManagementService.StylistManagementCreateStylistRequestQuestionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistManagementCreateStylistRequestQuestion$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementCreateStylistRequestQuestion(params?: {
    context?: HttpContext
    body?: InputCreateStylistServiceQuestionDto
  }
): Observable<void> {

    return this.stylistManagementCreateStylistRequestQuestion$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation stylistManagementDeleteStylistRequestQuestion
   */
  static readonly StylistManagementDeleteStylistRequestQuestionPath = '/api/admin/StylistManagement/DeleteStylistRequestQuestion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementDeleteStylistRequestQuestion()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementDeleteStylistRequestQuestion$Response(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, StylistManagementService.StylistManagementDeleteStylistRequestQuestionPath, 'delete');
    if (params) {
      rb.query('Id', params.Id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistManagementDeleteStylistRequestQuestion$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementDeleteStylistRequestQuestion(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<void> {

    return this.stylistManagementDeleteStylistRequestQuestion$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation stylistManagementUpdateStylistServiceQuestion
   */
  static readonly StylistManagementUpdateStylistServiceQuestionPath = '/api/admin/StylistManagement/UpdateStylistServiceQuestion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementUpdateStylistServiceQuestion()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementUpdateStylistServiceQuestion$Response(params?: {
    context?: HttpContext
    body?: InputUpdateStylistServiceQuestionDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, StylistManagementService.StylistManagementUpdateStylistServiceQuestionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistManagementUpdateStylistServiceQuestion$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementUpdateStylistServiceQuestion(params?: {
    context?: HttpContext
    body?: InputUpdateStylistServiceQuestionDto
  }
): Observable<void> {

    return this.stylistManagementUpdateStylistServiceQuestion$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation stylistManagementGetAllStylistRequestQuestion
   */
  static readonly StylistManagementGetAllStylistRequestQuestionPath = '/api/admin/StylistManagement/GetAllStylistRequestQuestion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementGetAllStylistRequestQuestion$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistRequestQuestion$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllStylistServiceQuestionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StylistManagementService.StylistManagementGetAllStylistRequestQuestionPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllStylistServiceQuestionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistManagementGetAllStylistRequestQuestion$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistRequestQuestion$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllStylistServiceQuestionDto>> {

    return this.stylistManagementGetAllStylistRequestQuestion$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllStylistServiceQuestionDto>>) => r.body as Array<OutputGetAllStylistServiceQuestionDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementGetAllStylistRequestQuestion()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistRequestQuestion$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllStylistServiceQuestionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StylistManagementService.StylistManagementGetAllStylistRequestQuestionPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllStylistServiceQuestionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistManagementGetAllStylistRequestQuestion$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistRequestQuestion(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllStylistServiceQuestionDto>> {

    return this.stylistManagementGetAllStylistRequestQuestion$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllStylistServiceQuestionDto>>) => r.body as Array<OutputGetAllStylistServiceQuestionDto>)
    );
  }

  /**
   * Path part for operation stylistManagementCreateStylistRequestPersonQuestion
   */
  static readonly StylistManagementCreateStylistRequestPersonQuestionPath = '/api/admin/StylistManagement/CreateStylistRequestPersonQuestion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementCreateStylistRequestPersonQuestion()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementCreateStylistRequestPersonQuestion$Response(params?: {
    context?: HttpContext
    body?: InputCreateStylistPersonQuestionDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, StylistManagementService.StylistManagementCreateStylistRequestPersonQuestionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistManagementCreateStylistRequestPersonQuestion$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementCreateStylistRequestPersonQuestion(params?: {
    context?: HttpContext
    body?: InputCreateStylistPersonQuestionDto
  }
): Observable<void> {

    return this.stylistManagementCreateStylistRequestPersonQuestion$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation stylistManagementDeleteStylistRequestPersonQuestion
   */
  static readonly StylistManagementDeleteStylistRequestPersonQuestionPath = '/api/admin/StylistManagement/DeleteStylistRequestPersonQuestion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementDeleteStylistRequestPersonQuestion()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementDeleteStylistRequestPersonQuestion$Response(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, StylistManagementService.StylistManagementDeleteStylistRequestPersonQuestionPath, 'delete');
    if (params) {
      rb.query('Id', params.Id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistManagementDeleteStylistRequestPersonQuestion$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementDeleteStylistRequestPersonQuestion(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<void> {

    return this.stylistManagementDeleteStylistRequestPersonQuestion$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation stylistManagementUpdateStylistRequestPersonQuestion
   */
  static readonly StylistManagementUpdateStylistRequestPersonQuestionPath = '/api/admin/StylistManagement/UpdateStylistRequestPersonQuestion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementUpdateStylistRequestPersonQuestion()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementUpdateStylistRequestPersonQuestion$Response(params?: {
    context?: HttpContext
    body?: InputUpdateStylistPersonQuestionDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, StylistManagementService.StylistManagementUpdateStylistRequestPersonQuestionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistManagementUpdateStylistRequestPersonQuestion$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementUpdateStylistRequestPersonQuestion(params?: {
    context?: HttpContext
    body?: InputUpdateStylistPersonQuestionDto
  }
): Observable<void> {

    return this.stylistManagementUpdateStylistRequestPersonQuestion$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation stylistManagementGetAllStylistRequestPersonQuestion
   */
  static readonly StylistManagementGetAllStylistRequestPersonQuestionPath = '/api/admin/StylistManagement/GetAllStylistRequestPersonQuestion';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementGetAllStylistRequestPersonQuestion$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistRequestPersonQuestion$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllStylistPersonQuestionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StylistManagementService.StylistManagementGetAllStylistRequestPersonQuestionPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllStylistPersonQuestionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistManagementGetAllStylistRequestPersonQuestion$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistRequestPersonQuestion$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllStylistPersonQuestionDto>> {

    return this.stylistManagementGetAllStylistRequestPersonQuestion$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllStylistPersonQuestionDto>>) => r.body as Array<OutputGetAllStylistPersonQuestionDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementGetAllStylistRequestPersonQuestion()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistRequestPersonQuestion$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllStylistPersonQuestionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StylistManagementService.StylistManagementGetAllStylistRequestPersonQuestionPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllStylistPersonQuestionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistManagementGetAllStylistRequestPersonQuestion$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistRequestPersonQuestion(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllStylistPersonQuestionDto>> {

    return this.stylistManagementGetAllStylistRequestPersonQuestion$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllStylistPersonQuestionDto>>) => r.body as Array<OutputGetAllStylistPersonQuestionDto>)
    );
  }

  /**
   * Path part for operation stylistManagementCreateStylistPackage
   */
  static readonly StylistManagementCreateStylistPackagePath = '/api/admin/StylistManagement/CreateStylistPackage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementCreateStylistPackage$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementCreateStylistPackage$Plain$Response(params?: {
    context?: HttpContext
    body?: InputCreateStylistPackageDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, StylistManagementService.StylistManagementCreateStylistPackagePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistManagementCreateStylistPackage$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementCreateStylistPackage$Plain(params?: {
    context?: HttpContext
    body?: InputCreateStylistPackageDto
  }
): Observable<boolean> {

    return this.stylistManagementCreateStylistPackage$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementCreateStylistPackage()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementCreateStylistPackage$Response(params?: {
    context?: HttpContext
    body?: InputCreateStylistPackageDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, StylistManagementService.StylistManagementCreateStylistPackagePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistManagementCreateStylistPackage$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementCreateStylistPackage(params?: {
    context?: HttpContext
    body?: InputCreateStylistPackageDto
  }
): Observable<boolean> {

    return this.stylistManagementCreateStylistPackage$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation stylistManagementDeleteStylistPackage
   */
  static readonly StylistManagementDeleteStylistPackagePath = '/api/admin/StylistManagement/DeleteStylistPackage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementDeleteStylistPackage$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementDeleteStylistPackage$Plain$Response(params?: {
    context?: HttpContext
    body?: InputDeleteStylistPackageDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, StylistManagementService.StylistManagementDeleteStylistPackagePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistManagementDeleteStylistPackage$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementDeleteStylistPackage$Plain(params?: {
    context?: HttpContext
    body?: InputDeleteStylistPackageDto
  }
): Observable<boolean> {

    return this.stylistManagementDeleteStylistPackage$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementDeleteStylistPackage()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementDeleteStylistPackage$Response(params?: {
    context?: HttpContext
    body?: InputDeleteStylistPackageDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, StylistManagementService.StylistManagementDeleteStylistPackagePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistManagementDeleteStylistPackage$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementDeleteStylistPackage(params?: {
    context?: HttpContext
    body?: InputDeleteStylistPackageDto
  }
): Observable<boolean> {

    return this.stylistManagementDeleteStylistPackage$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation stylistManagementUpdateStylistPackage
   */
  static readonly StylistManagementUpdateStylistPackagePath = '/api/admin/StylistManagement/UpdateStylistPackage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementUpdateStylistPackage$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementUpdateStylistPackage$Plain$Response(params?: {
    context?: HttpContext
    body?: InputUpdateStylistPackageDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, StylistManagementService.StylistManagementUpdateStylistPackagePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistManagementUpdateStylistPackage$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementUpdateStylistPackage$Plain(params?: {
    context?: HttpContext
    body?: InputUpdateStylistPackageDto
  }
): Observable<boolean> {

    return this.stylistManagementUpdateStylistPackage$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementUpdateStylistPackage()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementUpdateStylistPackage$Response(params?: {
    context?: HttpContext
    body?: InputUpdateStylistPackageDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, StylistManagementService.StylistManagementUpdateStylistPackagePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistManagementUpdateStylistPackage$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementUpdateStylistPackage(params?: {
    context?: HttpContext
    body?: InputUpdateStylistPackageDto
  }
): Observable<boolean> {

    return this.stylistManagementUpdateStylistPackage$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation stylistManagementGetAllStylistPackage
   */
  static readonly StylistManagementGetAllStylistPackagePath = '/api/admin/StylistManagement/GetAllStylistPackage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementGetAllStylistPackage$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistPackage$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllStylistPackageDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StylistManagementService.StylistManagementGetAllStylistPackagePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllStylistPackageDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistManagementGetAllStylistPackage$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistPackage$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllStylistPackageDto>> {

    return this.stylistManagementGetAllStylistPackage$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllStylistPackageDto>>) => r.body as Array<OutputGetAllStylistPackageDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementGetAllStylistPackage()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistPackage$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllStylistPackageDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StylistManagementService.StylistManagementGetAllStylistPackagePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllStylistPackageDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistManagementGetAllStylistPackage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistPackage(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllStylistPackageDto>> {

    return this.stylistManagementGetAllStylistPackage$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllStylistPackageDto>>) => r.body as Array<OutputGetAllStylistPackageDto>)
    );
  }

  /**
   * Path part for operation stylistManagementCreateStylistFeature
   */
  static readonly StylistManagementCreateStylistFeaturePath = '/api/admin/StylistManagement/CreateStylistFeature';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementCreateStylistFeature()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementCreateStylistFeature$Response(params?: {
    context?: HttpContext
    body?: InputCreateStylistFeatureDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, StylistManagementService.StylistManagementCreateStylistFeaturePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistManagementCreateStylistFeature$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementCreateStylistFeature(params?: {
    context?: HttpContext
    body?: InputCreateStylistFeatureDto
  }
): Observable<void> {

    return this.stylistManagementCreateStylistFeature$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation stylistManagementGetAllStylistFeature
   */
  static readonly StylistManagementGetAllStylistFeaturePath = '/api/admin/StylistManagement/GetAllStylistFeature';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementGetAllStylistFeature$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistFeature$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllStylistFeatureDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StylistManagementService.StylistManagementGetAllStylistFeaturePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllStylistFeatureDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistManagementGetAllStylistFeature$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistFeature$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllStylistFeatureDto>> {

    return this.stylistManagementGetAllStylistFeature$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllStylistFeatureDto>>) => r.body as Array<OutputGetAllStylistFeatureDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementGetAllStylistFeature()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistFeature$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllStylistFeatureDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StylistManagementService.StylistManagementGetAllStylistFeaturePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllStylistFeatureDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistManagementGetAllStylistFeature$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistFeature(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllStylistFeatureDto>> {

    return this.stylistManagementGetAllStylistFeature$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllStylistFeatureDto>>) => r.body as Array<OutputGetAllStylistFeatureDto>)
    );
  }

  /**
   * Path part for operation stylistManagementDeleteStylistFeature
   */
  static readonly StylistManagementDeleteStylistFeaturePath = '/api/admin/StylistManagement/DeleteStylistFeature';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementDeleteStylistFeature()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementDeleteStylistFeature$Response(params?: {
    context?: HttpContext
    body?: InputDeleteStylistFeatureDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, StylistManagementService.StylistManagementDeleteStylistFeaturePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistManagementDeleteStylistFeature$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementDeleteStylistFeature(params?: {
    context?: HttpContext
    body?: InputDeleteStylistFeatureDto
  }
): Observable<void> {

    return this.stylistManagementDeleteStylistFeature$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation stylistManagementUpdateStylistFeature
   */
  static readonly StylistManagementUpdateStylistFeaturePath = '/api/admin/StylistManagement/UpdateStylistFeature';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementUpdateStylistFeature()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementUpdateStylistFeature$Response(params?: {
    context?: HttpContext
    body?: InputUpdateStylistFeatureDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, StylistManagementService.StylistManagementUpdateStylistFeaturePath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistManagementUpdateStylistFeature$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementUpdateStylistFeature(params?: {
    context?: HttpContext
    body?: InputUpdateStylistFeatureDto
  }
): Observable<void> {

    return this.stylistManagementUpdateStylistFeature$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation stylistManagementGetAllStylists
   */
  static readonly StylistManagementGetAllStylistsPath = '/api/admin/StylistManagement/GetAllStylists';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementGetAllStylists$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylists$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputAllStylistsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StylistManagementService.StylistManagementGetAllStylistsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputAllStylistsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistManagementGetAllStylists$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylists$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputAllStylistsDto>> {

    return this.stylistManagementGetAllStylists$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputAllStylistsDto>>) => r.body as Array<OutputAllStylistsDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementGetAllStylists()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylists$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputAllStylistsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StylistManagementService.StylistManagementGetAllStylistsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputAllStylistsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistManagementGetAllStylists$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylists(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputAllStylistsDto>> {

    return this.stylistManagementGetAllStylists$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputAllStylistsDto>>) => r.body as Array<OutputAllStylistsDto>)
    );
  }

  /**
   * Path part for operation stylistManagementGetAllPendingStylists
   */
  static readonly StylistManagementGetAllPendingStylistsPath = '/api/admin/StylistManagement/GetAllPendingStylists';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementGetAllPendingStylists$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllPendingStylists$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputAllStylistsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StylistManagementService.StylistManagementGetAllPendingStylistsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputAllStylistsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistManagementGetAllPendingStylists$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllPendingStylists$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputAllStylistsDto>> {

    return this.stylistManagementGetAllPendingStylists$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputAllStylistsDto>>) => r.body as Array<OutputAllStylistsDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementGetAllPendingStylists()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllPendingStylists$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputAllStylistsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StylistManagementService.StylistManagementGetAllPendingStylistsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputAllStylistsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistManagementGetAllPendingStylists$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllPendingStylists(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputAllStylistsDto>> {

    return this.stylistManagementGetAllPendingStylists$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputAllStylistsDto>>) => r.body as Array<OutputAllStylistsDto>)
    );
  }

  /**
   * Path part for operation stylistManagementGetAllRejectedStylists
   */
  static readonly StylistManagementGetAllRejectedStylistsPath = '/api/admin/StylistManagement/GetAllRejectedStylists';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementGetAllRejectedStylists$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllRejectedStylists$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputAllStylistsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StylistManagementService.StylistManagementGetAllRejectedStylistsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputAllStylistsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistManagementGetAllRejectedStylists$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllRejectedStylists$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputAllStylistsDto>> {

    return this.stylistManagementGetAllRejectedStylists$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputAllStylistsDto>>) => r.body as Array<OutputAllStylistsDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementGetAllRejectedStylists()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllRejectedStylists$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputAllStylistsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StylistManagementService.StylistManagementGetAllRejectedStylistsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputAllStylistsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistManagementGetAllRejectedStylists$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllRejectedStylists(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputAllStylistsDto>> {

    return this.stylistManagementGetAllRejectedStylists$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputAllStylistsDto>>) => r.body as Array<OutputAllStylistsDto>)
    );
  }

  /**
   * Path part for operation stylistManagementStylistChangeStatues
   */
  static readonly StylistManagementStylistChangeStatuesPath = '/api/admin/StylistManagement/StylistChangeStatues';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementStylistChangeStatues$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementStylistChangeStatues$Plain$Response(params?: {
    context?: HttpContext
    body?: InputStylistChangeStatues
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, StylistManagementService.StylistManagementStylistChangeStatuesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistManagementStylistChangeStatues$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementStylistChangeStatues$Plain(params?: {
    context?: HttpContext
    body?: InputStylistChangeStatues
  }
): Observable<boolean> {

    return this.stylistManagementStylistChangeStatues$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementStylistChangeStatues()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementStylistChangeStatues$Response(params?: {
    context?: HttpContext
    body?: InputStylistChangeStatues
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, StylistManagementService.StylistManagementStylistChangeStatuesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistManagementStylistChangeStatues$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementStylistChangeStatues(params?: {
    context?: HttpContext
    body?: InputStylistChangeStatues
  }
): Observable<boolean> {

    return this.stylistManagementStylistChangeStatues$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation stylistManagementCreateStylistEarningSetting
   */
  static readonly StylistManagementCreateStylistEarningSettingPath = '/api/admin/StylistManagement/CreateStylistEarningSetting';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementCreateStylistEarningSetting$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementCreateStylistEarningSetting$Plain$Response(params?: {
    context?: HttpContext
    body?: Array<InputUpdateStylistEarningSettingDto>
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, StylistManagementService.StylistManagementCreateStylistEarningSettingPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistManagementCreateStylistEarningSetting$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementCreateStylistEarningSetting$Plain(params?: {
    context?: HttpContext
    body?: Array<InputUpdateStylistEarningSettingDto>
  }
): Observable<boolean> {

    return this.stylistManagementCreateStylistEarningSetting$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementCreateStylistEarningSetting()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementCreateStylistEarningSetting$Response(params?: {
    context?: HttpContext
    body?: Array<InputUpdateStylistEarningSettingDto>
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, StylistManagementService.StylistManagementCreateStylistEarningSettingPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistManagementCreateStylistEarningSetting$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementCreateStylistEarningSetting(params?: {
    context?: HttpContext
    body?: Array<InputUpdateStylistEarningSettingDto>
  }
): Observable<boolean> {

    return this.stylistManagementCreateStylistEarningSetting$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation stylistManagementGetAllStylistEarningSetting
   */
  static readonly StylistManagementGetAllStylistEarningSettingPath = '/api/admin/StylistManagement/GetAllStylistEarningSetting';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementGetAllStylistEarningSetting$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistEarningSetting$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllStylistEarningSettingDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StylistManagementService.StylistManagementGetAllStylistEarningSettingPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllStylistEarningSettingDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistManagementGetAllStylistEarningSetting$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistEarningSetting$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllStylistEarningSettingDto>> {

    return this.stylistManagementGetAllStylistEarningSetting$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllStylistEarningSettingDto>>) => r.body as Array<OutputGetAllStylistEarningSettingDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementGetAllStylistEarningSetting()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistEarningSetting$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllStylistEarningSettingDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StylistManagementService.StylistManagementGetAllStylistEarningSettingPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllStylistEarningSettingDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistManagementGetAllStylistEarningSetting$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistEarningSetting(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllStylistEarningSettingDto>> {

    return this.stylistManagementGetAllStylistEarningSetting$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllStylistEarningSettingDto>>) => r.body as Array<OutputGetAllStylistEarningSettingDto>)
    );
  }

  /**
   * Path part for operation stylistManagementUpdateStylistDashboard
   */
  static readonly StylistManagementUpdateStylistDashboardPath = '/api/admin/StylistManagement/UpdateStylistDashboard';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementUpdateStylistDashboard$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementUpdateStylistDashboard$Plain$Response(params?: {
    context?: HttpContext
    body?: StylistDashboardDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, StylistManagementService.StylistManagementUpdateStylistDashboardPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistManagementUpdateStylistDashboard$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementUpdateStylistDashboard$Plain(params?: {
    context?: HttpContext
    body?: StylistDashboardDto
  }
): Observable<boolean> {

    return this.stylistManagementUpdateStylistDashboard$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementUpdateStylistDashboard()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementUpdateStylistDashboard$Response(params?: {
    context?: HttpContext
    body?: StylistDashboardDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, StylistManagementService.StylistManagementUpdateStylistDashboardPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistManagementUpdateStylistDashboard$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementUpdateStylistDashboard(params?: {
    context?: HttpContext
    body?: StylistDashboardDto
  }
): Observable<boolean> {

    return this.stylistManagementUpdateStylistDashboard$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation stylistManagementGetStylistDashboard
   */
  static readonly StylistManagementGetStylistDashboardPath = '/api/admin/StylistManagement/GetStylistDashboard';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementGetStylistDashboard$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetStylistDashboard$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<StylistDashboardDto>> {

    const rb = new RequestBuilder(this.rootUrl, StylistManagementService.StylistManagementGetStylistDashboardPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StylistDashboardDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistManagementGetStylistDashboard$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetStylistDashboard$Plain(params?: {
    context?: HttpContext
  }
): Observable<StylistDashboardDto> {

    return this.stylistManagementGetStylistDashboard$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StylistDashboardDto>) => r.body as StylistDashboardDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementGetStylistDashboard()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetStylistDashboard$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<StylistDashboardDto>> {

    const rb = new RequestBuilder(this.rootUrl, StylistManagementService.StylistManagementGetStylistDashboardPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StylistDashboardDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistManagementGetStylistDashboard$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetStylistDashboard(params?: {
    context?: HttpContext
  }
): Observable<StylistDashboardDto> {

    return this.stylistManagementGetStylistDashboard$Response(params).pipe(
      map((r: StrictHttpResponse<StylistDashboardDto>) => r.body as StylistDashboardDto)
    );
  }

  /**
   * Path part for operation stylistManagementGetAllStylistWithdrawalForAdmin
   */
  static readonly StylistManagementGetAllStylistWithdrawalForAdminPath = '/api/admin/StylistManagement/GetAllStylistWithdrawalForAdmin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementGetAllStylistWithdrawalForAdmin$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistWithdrawalForAdmin$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<GetAllStylistWithdrawalForAdminDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StylistManagementService.StylistManagementGetAllStylistWithdrawalForAdminPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetAllStylistWithdrawalForAdminDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistManagementGetAllStylistWithdrawalForAdmin$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistWithdrawalForAdmin$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<GetAllStylistWithdrawalForAdminDto>> {

    return this.stylistManagementGetAllStylistWithdrawalForAdmin$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GetAllStylistWithdrawalForAdminDto>>) => r.body as Array<GetAllStylistWithdrawalForAdminDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementGetAllStylistWithdrawalForAdmin()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistWithdrawalForAdmin$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<GetAllStylistWithdrawalForAdminDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StylistManagementService.StylistManagementGetAllStylistWithdrawalForAdminPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetAllStylistWithdrawalForAdminDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistManagementGetAllStylistWithdrawalForAdmin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistManagementGetAllStylistWithdrawalForAdmin(params?: {
    context?: HttpContext
  }
): Observable<Array<GetAllStylistWithdrawalForAdminDto>> {

    return this.stylistManagementGetAllStylistWithdrawalForAdmin$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GetAllStylistWithdrawalForAdminDto>>) => r.body as Array<GetAllStylistWithdrawalForAdminDto>)
    );
  }

  /**
   * Path part for operation stylistManagementUpdateStylistWithdrawalForAdmin
   */
  static readonly StylistManagementUpdateStylistWithdrawalForAdminPath = '/api/admin/StylistManagement/UpdateStylistWithdrawalForAdmin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementUpdateStylistWithdrawalForAdmin$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementUpdateStylistWithdrawalForAdmin$Plain$Response(params?: {
    context?: HttpContext
    body?: InputUpdateStylistWithdrawalStatusDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, StylistManagementService.StylistManagementUpdateStylistWithdrawalForAdminPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistManagementUpdateStylistWithdrawalForAdmin$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementUpdateStylistWithdrawalForAdmin$Plain(params?: {
    context?: HttpContext
    body?: InputUpdateStylistWithdrawalStatusDto
  }
): Observable<boolean> {

    return this.stylistManagementUpdateStylistWithdrawalForAdmin$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistManagementUpdateStylistWithdrawalForAdmin()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementUpdateStylistWithdrawalForAdmin$Response(params?: {
    context?: HttpContext
    body?: InputUpdateStylistWithdrawalStatusDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, StylistManagementService.StylistManagementUpdateStylistWithdrawalForAdminPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistManagementUpdateStylistWithdrawalForAdmin$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistManagementUpdateStylistWithdrawalForAdmin(params?: {
    context?: HttpContext
    body?: InputUpdateStylistWithdrawalStatusDto
  }
): Observable<boolean> {

    return this.stylistManagementUpdateStylistWithdrawalForAdmin$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
