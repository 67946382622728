/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CheckMerchantHaveValidCompanyDto } from '../models/check-merchant-have-valid-company-dto';
import { InputCreateCompanyDto } from '../models/input-create-company-dto';

@Injectable({
  providedIn: 'root',
})
export class MerchantCompanyService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation merchantCompanyCreateCompany
   */
  static readonly MerchantCompanyCreateCompanyPath = '/api/Merchant/MerchantCompany/CreateCompany';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantCompanyCreateCompany$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantCompanyCreateCompany$Plain$Response(params?: {
    context?: HttpContext
    body?: InputCreateCompanyDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantCompanyService.MerchantCompanyCreateCompanyPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantCompanyCreateCompany$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantCompanyCreateCompany$Plain(params?: {
    context?: HttpContext
    body?: InputCreateCompanyDto
  }
): Observable<boolean> {

    return this.merchantCompanyCreateCompany$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantCompanyCreateCompany()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantCompanyCreateCompany$Response(params?: {
    context?: HttpContext
    body?: InputCreateCompanyDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantCompanyService.MerchantCompanyCreateCompanyPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantCompanyCreateCompany$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantCompanyCreateCompany(params?: {
    context?: HttpContext
    body?: InputCreateCompanyDto
  }
): Observable<boolean> {

    return this.merchantCompanyCreateCompany$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation merchantCompanyCheckMerchantHaveValidCompany
   */
  static readonly MerchantCompanyCheckMerchantHaveValidCompanyPath = '/api/Merchant/MerchantCompany/CheckMerchantHaveValidCompany';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantCompanyCheckMerchantHaveValidCompany$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantCompanyCheckMerchantHaveValidCompany$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<CheckMerchantHaveValidCompanyDto>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantCompanyService.MerchantCompanyCheckMerchantHaveValidCompanyPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CheckMerchantHaveValidCompanyDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantCompanyCheckMerchantHaveValidCompany$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantCompanyCheckMerchantHaveValidCompany$Plain(params?: {
    context?: HttpContext
  }
): Observable<CheckMerchantHaveValidCompanyDto> {

    return this.merchantCompanyCheckMerchantHaveValidCompany$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CheckMerchantHaveValidCompanyDto>) => r.body as CheckMerchantHaveValidCompanyDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantCompanyCheckMerchantHaveValidCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantCompanyCheckMerchantHaveValidCompany$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<CheckMerchantHaveValidCompanyDto>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantCompanyService.MerchantCompanyCheckMerchantHaveValidCompanyPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CheckMerchantHaveValidCompanyDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantCompanyCheckMerchantHaveValidCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantCompanyCheckMerchantHaveValidCompany(params?: {
    context?: HttpContext
  }
): Observable<CheckMerchantHaveValidCompanyDto> {

    return this.merchantCompanyCheckMerchantHaveValidCompany$Response(params).pipe(
      map((r: StrictHttpResponse<CheckMerchantHaveValidCompanyDto>) => r.body as CheckMerchantHaveValidCompanyDto)
    );
  }

}
