/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { OutputGetAllRequestsDto } from '../models/output-get-all-requests-dto';
import { OutputGetOrderReturnDetailsDto } from '../models/output-get-order-return-details-dto';
import { OutputGetPagedOrderReturnsDtoListOutputPagedResponseDto } from '../models/output-get-paged-order-returns-dto-list-output-paged-response-dto';
import { OutputGetRequestDetailsDto } from '../models/output-get-request-details-dto';

@Injectable({
  providedIn: 'root',
})
export class TrackingManagementService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation trackingManagementGetRequests
   */
  static readonly TrackingManagementGetRequestsPath = '/api/admin/TrackingManagement/GetRequests';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trackingManagementGetRequests$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementGetRequests$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllRequestsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, TrackingManagementService.TrackingManagementGetRequestsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllRequestsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `trackingManagementGetRequests$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementGetRequests$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllRequestsDto>> {

    return this.trackingManagementGetRequests$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllRequestsDto>>) => r.body as Array<OutputGetAllRequestsDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trackingManagementGetRequests()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementGetRequests$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllRequestsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, TrackingManagementService.TrackingManagementGetRequestsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllRequestsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `trackingManagementGetRequests$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementGetRequests(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllRequestsDto>> {

    return this.trackingManagementGetRequests$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllRequestsDto>>) => r.body as Array<OutputGetAllRequestsDto>)
    );
  }

  /**
   * Path part for operation trackingManagementGetRequestDetails
   */
  static readonly TrackingManagementGetRequestDetailsPath = '/api/admin/TrackingManagement/GetRequestDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trackingManagementGetRequestDetails$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementGetRequestDetails$Plain$Response(params?: {
    RequestId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetRequestDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, TrackingManagementService.TrackingManagementGetRequestDetailsPath, 'get');
    if (params) {
      rb.query('RequestId', params.RequestId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetRequestDetailsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `trackingManagementGetRequestDetails$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementGetRequestDetails$Plain(params?: {
    RequestId?: number;
    context?: HttpContext
  }
): Observable<OutputGetRequestDetailsDto> {

    return this.trackingManagementGetRequestDetails$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetRequestDetailsDto>) => r.body as OutputGetRequestDetailsDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trackingManagementGetRequestDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementGetRequestDetails$Response(params?: {
    RequestId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetRequestDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, TrackingManagementService.TrackingManagementGetRequestDetailsPath, 'get');
    if (params) {
      rb.query('RequestId', params.RequestId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetRequestDetailsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `trackingManagementGetRequestDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementGetRequestDetails(params?: {
    RequestId?: number;
    context?: HttpContext
  }
): Observable<OutputGetRequestDetailsDto> {

    return this.trackingManagementGetRequestDetails$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetRequestDetailsDto>) => r.body as OutputGetRequestDetailsDto)
    );
  }

  /**
   * Path part for operation trackingManagementUpdateRequestStatues
   */
  static readonly TrackingManagementUpdateRequestStatuesPath = '/api/admin/TrackingManagement/UpdateRequestStatues';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trackingManagementUpdateRequestStatues$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementUpdateRequestStatues$Plain$Response(params?: {
    Id?: number;
    Statues?: number;
    note?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, TrackingManagementService.TrackingManagementUpdateRequestStatuesPath, 'get');
    if (params) {
      rb.query('Id', params.Id, {"style":"form"});
      rb.query('Statues', params.Statues, {"style":"form"});
      rb.query('note', params.note, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `trackingManagementUpdateRequestStatues$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementUpdateRequestStatues$Plain(params?: {
    Id?: number;
    Statues?: number;
    note?: string;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.trackingManagementUpdateRequestStatues$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trackingManagementUpdateRequestStatues()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementUpdateRequestStatues$Response(params?: {
    Id?: number;
    Statues?: number;
    note?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, TrackingManagementService.TrackingManagementUpdateRequestStatuesPath, 'get');
    if (params) {
      rb.query('Id', params.Id, {"style":"form"});
      rb.query('Statues', params.Statues, {"style":"form"});
      rb.query('note', params.note, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `trackingManagementUpdateRequestStatues$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementUpdateRequestStatues(params?: {
    Id?: number;
    Statues?: number;
    note?: string;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.trackingManagementUpdateRequestStatues$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation trackingManagementGetPagedOrderReturns
   */
  static readonly TrackingManagementGetPagedOrderReturnsPath = '/api/admin/TrackingManagement/GetPagedOrderReturns';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trackingManagementGetPagedOrderReturns$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementGetPagedOrderReturns$Plain$Response(params?: {
    input?: string;
    pageNumber?: number;
    pagesize?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetPagedOrderReturnsDtoListOutputPagedResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, TrackingManagementService.TrackingManagementGetPagedOrderReturnsPath, 'get');
    if (params) {
      rb.query('input', params.input, {"style":"form"});
      rb.query('pageNumber', params.pageNumber, {"style":"form"});
      rb.query('pagesize', params.pagesize, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetPagedOrderReturnsDtoListOutputPagedResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `trackingManagementGetPagedOrderReturns$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementGetPagedOrderReturns$Plain(params?: {
    input?: string;
    pageNumber?: number;
    pagesize?: number;
    context?: HttpContext
  }
): Observable<OutputGetPagedOrderReturnsDtoListOutputPagedResponseDto> {

    return this.trackingManagementGetPagedOrderReturns$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetPagedOrderReturnsDtoListOutputPagedResponseDto>) => r.body as OutputGetPagedOrderReturnsDtoListOutputPagedResponseDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trackingManagementGetPagedOrderReturns()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementGetPagedOrderReturns$Response(params?: {
    input?: string;
    pageNumber?: number;
    pagesize?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetPagedOrderReturnsDtoListOutputPagedResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, TrackingManagementService.TrackingManagementGetPagedOrderReturnsPath, 'get');
    if (params) {
      rb.query('input', params.input, {"style":"form"});
      rb.query('pageNumber', params.pageNumber, {"style":"form"});
      rb.query('pagesize', params.pagesize, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetPagedOrderReturnsDtoListOutputPagedResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `trackingManagementGetPagedOrderReturns$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementGetPagedOrderReturns(params?: {
    input?: string;
    pageNumber?: number;
    pagesize?: number;
    context?: HttpContext
  }
): Observable<OutputGetPagedOrderReturnsDtoListOutputPagedResponseDto> {

    return this.trackingManagementGetPagedOrderReturns$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetPagedOrderReturnsDtoListOutputPagedResponseDto>) => r.body as OutputGetPagedOrderReturnsDtoListOutputPagedResponseDto)
    );
  }

  /**
   * Path part for operation trackingManagementGetOrderReturnDetails
   */
  static readonly TrackingManagementGetOrderReturnDetailsPath = '/api/admin/TrackingManagement/GetOrderReturnDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trackingManagementGetOrderReturnDetails$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementGetOrderReturnDetails$Plain$Response(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetOrderReturnDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, TrackingManagementService.TrackingManagementGetOrderReturnDetailsPath, 'get');
    if (params) {
      rb.query('id', params.id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetOrderReturnDetailsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `trackingManagementGetOrderReturnDetails$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementGetOrderReturnDetails$Plain(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<OutputGetOrderReturnDetailsDto> {

    return this.trackingManagementGetOrderReturnDetails$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetOrderReturnDetailsDto>) => r.body as OutputGetOrderReturnDetailsDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trackingManagementGetOrderReturnDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementGetOrderReturnDetails$Response(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetOrderReturnDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, TrackingManagementService.TrackingManagementGetOrderReturnDetailsPath, 'get');
    if (params) {
      rb.query('id', params.id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetOrderReturnDetailsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `trackingManagementGetOrderReturnDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementGetOrderReturnDetails(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<OutputGetOrderReturnDetailsDto> {

    return this.trackingManagementGetOrderReturnDetails$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetOrderReturnDetailsDto>) => r.body as OutputGetOrderReturnDetailsDto)
    );
  }

  /**
   * Path part for operation trackingManagementChangeOrderReturnStatus
   */
  static readonly TrackingManagementChangeOrderReturnStatusPath = '/api/admin/TrackingManagement/ChangeOrderReturnStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trackingManagementChangeOrderReturnStatus$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementChangeOrderReturnStatus$Plain$Response(params?: {
    orderReturnId?: number;
    status?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, TrackingManagementService.TrackingManagementChangeOrderReturnStatusPath, 'get');
    if (params) {
      rb.query('orderReturnId', params.orderReturnId, {"style":"form"});
      rb.query('status', params.status, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `trackingManagementChangeOrderReturnStatus$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementChangeOrderReturnStatus$Plain(params?: {
    orderReturnId?: number;
    status?: number;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.trackingManagementChangeOrderReturnStatus$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trackingManagementChangeOrderReturnStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementChangeOrderReturnStatus$Response(params?: {
    orderReturnId?: number;
    status?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, TrackingManagementService.TrackingManagementChangeOrderReturnStatusPath, 'get');
    if (params) {
      rb.query('orderReturnId', params.orderReturnId, {"style":"form"});
      rb.query('status', params.status, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `trackingManagementChangeOrderReturnStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementChangeOrderReturnStatus(params?: {
    orderReturnId?: number;
    status?: number;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.trackingManagementChangeOrderReturnStatus$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
