/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { InputCreateBlogDto } from '../models/input-create-blog-dto';
import { InputCreateHeaderAddsDto } from '../models/input-create-header-adds-dto';
import { InputCreateHeaderLinkDto } from '../models/input-create-header-link-dto';
import { InputCreateHomeSectionDto } from '../models/input-create-home-section-dto';
import { InputCreateSliderDto } from '../models/input-create-slider-dto';
import { InputDeleteSliderDto } from '../models/input-delete-slider-dto';
import { InputUpdateAboutUsDto } from '../models/input-update-about-us-dto';
import { InputUpdateBlogDto } from '../models/input-update-blog-dto';
import { InputUpdateFaqDto } from '../models/input-update-faq-dto';
import { InputUpdateGenderAdsDto } from '../models/input-update-gender-ads-dto';
import { InputUpdateHeaderAddsDto } from '../models/input-update-header-adds-dto';
import { InputUpdateHeaderLinkDto } from '../models/input-update-header-link-dto';
import { InputUpdateHomeContentDto } from '../models/input-update-home-content-dto';
import { InputUpdateHomeSectionDto } from '../models/input-update-home-section-dto';
import { InputUpdatePrivacyPolicyDto } from '../models/input-update-privacy-policy-dto';
import { InputUpdateSiteContentDto } from '../models/input-update-site-content-dto';
import { InputUpdateSliderDto } from '../models/input-update-slider-dto';
import { InputUpdateTermsDto } from '../models/input-update-terms-dto';
import { OutPutGetAllHeaderAddsDto } from '../models/out-put-get-all-header-adds-dto';
import { OutPutGetHeaderLinkCategoryDto } from '../models/out-put-get-header-link-category-dto';
import { OutPutGetHeaderLinkDto } from '../models/out-put-get-header-link-dto';
import { OutputGetAboutUsDto } from '../models/output-get-about-us-dto';
import { OutputGetAllBlogsDto } from '../models/output-get-all-blogs-dto';
import { OutputGetAllHomeSectionsDto } from '../models/output-get-all-home-sections-dto';
import { OutputGetAllSliderDto } from '../models/output-get-all-slider-dto';
import { OutputGetBlogDetailsDto } from '../models/output-get-blog-details-dto';
import { OutputGetFaqDto } from '../models/output-get-faq-dto';
import { OutputGetGenderAdsDto } from '../models/output-get-gender-ads-dto';
import { OutputGetGeneralContent } from '../models/output-get-general-content';
import { OutputGetPrivacyPolicyDto } from '../models/output-get-privacy-policy-dto';
import { OutputGetTermsDto } from '../models/output-get-terms-dto';

@Injectable({
  providedIn: 'root',
})
export class SiteContentManagementService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation siteContentManagementGetGenderAds
   */
  static readonly SiteContentManagementGetGenderAdsPath = '/api/admin/SiteContentManagement/GetGenderAds';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetGenderAds$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetGenderAds$Plain$Response(params?: {
    Name?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetGenderAdsDto>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementGetGenderAdsPath, 'get');
    if (params) {
      rb.query('Name', params.Name, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetGenderAdsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetGenderAds$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetGenderAds$Plain(params?: {
    Name?: string;
    context?: HttpContext
  }
): Observable<OutputGetGenderAdsDto> {

    return this.siteContentManagementGetGenderAds$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetGenderAdsDto>) => r.body as OutputGetGenderAdsDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetGenderAds()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetGenderAds$Response(params?: {
    Name?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetGenderAdsDto>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementGetGenderAdsPath, 'get');
    if (params) {
      rb.query('Name', params.Name, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetGenderAdsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetGenderAds$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetGenderAds(params?: {
    Name?: string;
    context?: HttpContext
  }
): Observable<OutputGetGenderAdsDto> {

    return this.siteContentManagementGetGenderAds$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetGenderAdsDto>) => r.body as OutputGetGenderAdsDto)
    );
  }

  /**
   * Path part for operation siteContentManagementUpdateGenderAds
   */
  static readonly SiteContentManagementUpdateGenderAdsPath = '/api/admin/SiteContentManagement/UpdateGenderAds';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdateGenderAds$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateGenderAds$Plain$Response(params?: {
    context?: HttpContext
    body?: InputUpdateGenderAdsDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementUpdateGenderAdsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdateGenderAds$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateGenderAds$Plain(params?: {
    context?: HttpContext
    body?: InputUpdateGenderAdsDto
  }
): Observable<boolean> {

    return this.siteContentManagementUpdateGenderAds$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdateGenderAds()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateGenderAds$Response(params?: {
    context?: HttpContext
    body?: InputUpdateGenderAdsDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementUpdateGenderAdsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdateGenderAds$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateGenderAds(params?: {
    context?: HttpContext
    body?: InputUpdateGenderAdsDto
  }
): Observable<boolean> {

    return this.siteContentManagementUpdateGenderAds$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation siteContentManagementUpdateAboutUs
   */
  static readonly SiteContentManagementUpdateAboutUsPath = '/api/admin/SiteContentManagement/UpdateAboutUs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdateAboutUs$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateAboutUs$Plain$Response(params?: {
    context?: HttpContext
    body?: InputUpdateAboutUsDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementUpdateAboutUsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdateAboutUs$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateAboutUs$Plain(params?: {
    context?: HttpContext
    body?: InputUpdateAboutUsDto
  }
): Observable<boolean> {

    return this.siteContentManagementUpdateAboutUs$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdateAboutUs()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateAboutUs$Response(params?: {
    context?: HttpContext
    body?: InputUpdateAboutUsDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementUpdateAboutUsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdateAboutUs$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateAboutUs(params?: {
    context?: HttpContext
    body?: InputUpdateAboutUsDto
  }
): Observable<boolean> {

    return this.siteContentManagementUpdateAboutUs$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation siteContentManagementGetAboutUs
   */
  static readonly SiteContentManagementGetAboutUsPath = '/api/admin/SiteContentManagement/GetAboutUs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetAboutUs$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAboutUs$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetAboutUsDto>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementGetAboutUsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetAboutUsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetAboutUs$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAboutUs$Plain(params?: {
    context?: HttpContext
  }
): Observable<OutputGetAboutUsDto> {

    return this.siteContentManagementGetAboutUs$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetAboutUsDto>) => r.body as OutputGetAboutUsDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetAboutUs()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAboutUs$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetAboutUsDto>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementGetAboutUsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetAboutUsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetAboutUs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAboutUs(params?: {
    context?: HttpContext
  }
): Observable<OutputGetAboutUsDto> {

    return this.siteContentManagementGetAboutUs$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetAboutUsDto>) => r.body as OutputGetAboutUsDto)
    );
  }

  /**
   * Path part for operation siteContentManagementUodateTerms
   */
  static readonly SiteContentManagementUodateTermsPath = '/api/admin/SiteContentManagement/UodateTerms';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUodateTerms$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUodateTerms$Plain$Response(params?: {
    context?: HttpContext
    body?: InputUpdateTermsDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementUodateTermsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUodateTerms$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUodateTerms$Plain(params?: {
    context?: HttpContext
    body?: InputUpdateTermsDto
  }
): Observable<boolean> {

    return this.siteContentManagementUodateTerms$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUodateTerms()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUodateTerms$Response(params?: {
    context?: HttpContext
    body?: InputUpdateTermsDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementUodateTermsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUodateTerms$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUodateTerms(params?: {
    context?: HttpContext
    body?: InputUpdateTermsDto
  }
): Observable<boolean> {

    return this.siteContentManagementUodateTerms$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation siteContentManagementGetTerms
   */
  static readonly SiteContentManagementGetTermsPath = '/api/admin/SiteContentManagement/GetTerms';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetTerms$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetTerms$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetTermsDto>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementGetTermsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetTermsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetTerms$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetTerms$Plain(params?: {
    context?: HttpContext
  }
): Observable<OutputGetTermsDto> {

    return this.siteContentManagementGetTerms$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetTermsDto>) => r.body as OutputGetTermsDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetTerms()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetTerms$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetTermsDto>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementGetTermsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetTermsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetTerms$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetTerms(params?: {
    context?: HttpContext
  }
): Observable<OutputGetTermsDto> {

    return this.siteContentManagementGetTerms$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetTermsDto>) => r.body as OutputGetTermsDto)
    );
  }

  /**
   * Path part for operation siteContentManagementUpdatePrivacy
   */
  static readonly SiteContentManagementUpdatePrivacyPath = '/api/admin/SiteContentManagement/UpdatePrivacy';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdatePrivacy$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdatePrivacy$Plain$Response(params?: {
    context?: HttpContext
    body?: InputUpdatePrivacyPolicyDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementUpdatePrivacyPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdatePrivacy$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdatePrivacy$Plain(params?: {
    context?: HttpContext
    body?: InputUpdatePrivacyPolicyDto
  }
): Observable<boolean> {

    return this.siteContentManagementUpdatePrivacy$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdatePrivacy()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdatePrivacy$Response(params?: {
    context?: HttpContext
    body?: InputUpdatePrivacyPolicyDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementUpdatePrivacyPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdatePrivacy$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdatePrivacy(params?: {
    context?: HttpContext
    body?: InputUpdatePrivacyPolicyDto
  }
): Observable<boolean> {

    return this.siteContentManagementUpdatePrivacy$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation siteContentManagementGetPrivacyPolicy
   */
  static readonly SiteContentManagementGetPrivacyPolicyPath = '/api/admin/SiteContentManagement/GetPrivacyPolicy';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetPrivacyPolicy$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetPrivacyPolicy$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetPrivacyPolicyDto>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementGetPrivacyPolicyPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetPrivacyPolicyDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetPrivacyPolicy$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetPrivacyPolicy$Plain(params?: {
    context?: HttpContext
  }
): Observable<OutputGetPrivacyPolicyDto> {

    return this.siteContentManagementGetPrivacyPolicy$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetPrivacyPolicyDto>) => r.body as OutputGetPrivacyPolicyDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetPrivacyPolicy()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetPrivacyPolicy$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetPrivacyPolicyDto>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementGetPrivacyPolicyPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetPrivacyPolicyDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetPrivacyPolicy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetPrivacyPolicy(params?: {
    context?: HttpContext
  }
): Observable<OutputGetPrivacyPolicyDto> {

    return this.siteContentManagementGetPrivacyPolicy$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetPrivacyPolicyDto>) => r.body as OutputGetPrivacyPolicyDto)
    );
  }

  /**
   * Path part for operation siteContentManagementUpdateFaq
   */
  static readonly SiteContentManagementUpdateFaqPath = '/api/admin/SiteContentManagement/UpdateFAQ';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdateFaq$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateFaq$Plain$Response(params?: {
    context?: HttpContext
    body?: InputUpdateFaqDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementUpdateFaqPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdateFaq$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateFaq$Plain(params?: {
    context?: HttpContext
    body?: InputUpdateFaqDto
  }
): Observable<boolean> {

    return this.siteContentManagementUpdateFaq$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdateFaq()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateFaq$Response(params?: {
    context?: HttpContext
    body?: InputUpdateFaqDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementUpdateFaqPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdateFaq$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateFaq(params?: {
    context?: HttpContext
    body?: InputUpdateFaqDto
  }
): Observable<boolean> {

    return this.siteContentManagementUpdateFaq$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation siteContentManagementGetFaq
   */
  static readonly SiteContentManagementGetFaqPath = '/api/admin/SiteContentManagement/GetFAQ';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetFaq$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetFaq$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetFaqDto>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementGetFaqPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetFaqDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetFaq$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetFaq$Plain(params?: {
    context?: HttpContext
  }
): Observable<OutputGetFaqDto> {

    return this.siteContentManagementGetFaq$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetFaqDto>) => r.body as OutputGetFaqDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetFaq()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetFaq$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetFaqDto>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementGetFaqPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetFaqDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetFaq$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetFaq(params?: {
    context?: HttpContext
  }
): Observable<OutputGetFaqDto> {

    return this.siteContentManagementGetFaq$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetFaqDto>) => r.body as OutputGetFaqDto)
    );
  }

  /**
   * Path part for operation siteContentManagementGetSiteContent
   */
  static readonly SiteContentManagementGetSiteContentPath = '/api/admin/SiteContentManagement/GetSiteContent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetSiteContent$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetSiteContent$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetGeneralContent>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementGetSiteContentPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetGeneralContent>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetSiteContent$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetSiteContent$Plain(params?: {
    context?: HttpContext
  }
): Observable<OutputGetGeneralContent> {

    return this.siteContentManagementGetSiteContent$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetGeneralContent>) => r.body as OutputGetGeneralContent)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetSiteContent()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetSiteContent$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetGeneralContent>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementGetSiteContentPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetGeneralContent>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetSiteContent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetSiteContent(params?: {
    context?: HttpContext
  }
): Observable<OutputGetGeneralContent> {

    return this.siteContentManagementGetSiteContent$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetGeneralContent>) => r.body as OutputGetGeneralContent)
    );
  }

  /**
   * Path part for operation siteContentManagementUpdateSiteContent
   */
  static readonly SiteContentManagementUpdateSiteContentPath = '/api/admin/SiteContentManagement/UpdateSiteContent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdateSiteContent$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateSiteContent$Plain$Response(params?: {
    context?: HttpContext
    body?: InputUpdateSiteContentDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementUpdateSiteContentPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdateSiteContent$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateSiteContent$Plain(params?: {
    context?: HttpContext
    body?: InputUpdateSiteContentDto
  }
): Observable<boolean> {

    return this.siteContentManagementUpdateSiteContent$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdateSiteContent()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateSiteContent$Response(params?: {
    context?: HttpContext
    body?: InputUpdateSiteContentDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementUpdateSiteContentPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdateSiteContent$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateSiteContent(params?: {
    context?: HttpContext
    body?: InputUpdateSiteContentDto
  }
): Observable<boolean> {

    return this.siteContentManagementUpdateSiteContent$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation siteContentManagementUpdateHomeContent
   */
  static readonly SiteContentManagementUpdateHomeContentPath = '/api/admin/SiteContentManagement/UpdateHomeContent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdateHomeContent$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateHomeContent$Plain$Response(params?: {
    context?: HttpContext
    body?: InputUpdateHomeContentDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementUpdateHomeContentPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdateHomeContent$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateHomeContent$Plain(params?: {
    context?: HttpContext
    body?: InputUpdateHomeContentDto
  }
): Observable<boolean> {

    return this.siteContentManagementUpdateHomeContent$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdateHomeContent()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateHomeContent$Response(params?: {
    context?: HttpContext
    body?: InputUpdateHomeContentDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementUpdateHomeContentPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdateHomeContent$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateHomeContent(params?: {
    context?: HttpContext
    body?: InputUpdateHomeContentDto
  }
): Observable<boolean> {

    return this.siteContentManagementUpdateHomeContent$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation siteContentManagementGetHomeContent
   */
  static readonly SiteContentManagementGetHomeContentPath = '/api/admin/SiteContentManagement/GetHomeContent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetHomeContent$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetHomeContent$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<InputUpdateHomeContentDto>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementGetHomeContentPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InputUpdateHomeContentDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetHomeContent$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetHomeContent$Plain(params?: {
    context?: HttpContext
  }
): Observable<InputUpdateHomeContentDto> {

    return this.siteContentManagementGetHomeContent$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<InputUpdateHomeContentDto>) => r.body as InputUpdateHomeContentDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetHomeContent()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetHomeContent$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<InputUpdateHomeContentDto>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementGetHomeContentPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InputUpdateHomeContentDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetHomeContent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetHomeContent(params?: {
    context?: HttpContext
  }
): Observable<InputUpdateHomeContentDto> {

    return this.siteContentManagementGetHomeContent$Response(params).pipe(
      map((r: StrictHttpResponse<InputUpdateHomeContentDto>) => r.body as InputUpdateHomeContentDto)
    );
  }

  /**
   * Path part for operation siteContentManagementCreateHomeSection
   */
  static readonly SiteContentManagementCreateHomeSectionPath = '/api/admin/SiteContentManagement/CreateHomeSection';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementCreateHomeSection$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateHomeSection$Plain$Response(params?: {
    context?: HttpContext
    body?: InputCreateHomeSectionDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementCreateHomeSectionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementCreateHomeSection$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateHomeSection$Plain(params?: {
    context?: HttpContext
    body?: InputCreateHomeSectionDto
  }
): Observable<boolean> {

    return this.siteContentManagementCreateHomeSection$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementCreateHomeSection()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateHomeSection$Response(params?: {
    context?: HttpContext
    body?: InputCreateHomeSectionDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementCreateHomeSectionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementCreateHomeSection$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateHomeSection(params?: {
    context?: HttpContext
    body?: InputCreateHomeSectionDto
  }
): Observable<boolean> {

    return this.siteContentManagementCreateHomeSection$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation siteContentManagementUpdateHomeSection
   */
  static readonly SiteContentManagementUpdateHomeSectionPath = '/api/admin/SiteContentManagement/UpdateHomeSection';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdateHomeSection$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateHomeSection$Plain$Response(params?: {
    context?: HttpContext
    body?: InputUpdateHomeSectionDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementUpdateHomeSectionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdateHomeSection$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateHomeSection$Plain(params?: {
    context?: HttpContext
    body?: InputUpdateHomeSectionDto
  }
): Observable<boolean> {

    return this.siteContentManagementUpdateHomeSection$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdateHomeSection()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateHomeSection$Response(params?: {
    context?: HttpContext
    body?: InputUpdateHomeSectionDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementUpdateHomeSectionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdateHomeSection$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateHomeSection(params?: {
    context?: HttpContext
    body?: InputUpdateHomeSectionDto
  }
): Observable<boolean> {

    return this.siteContentManagementUpdateHomeSection$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation siteContentManagementGetAllHomeSection
   */
  static readonly SiteContentManagementGetAllHomeSectionPath = '/api/admin/SiteContentManagement/GetAllHomeSection';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetAllHomeSection$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllHomeSection$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllHomeSectionsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementGetAllHomeSectionPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllHomeSectionsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetAllHomeSection$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllHomeSection$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllHomeSectionsDto>> {

    return this.siteContentManagementGetAllHomeSection$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllHomeSectionsDto>>) => r.body as Array<OutputGetAllHomeSectionsDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetAllHomeSection()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllHomeSection$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllHomeSectionsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementGetAllHomeSectionPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllHomeSectionsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetAllHomeSection$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllHomeSection(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllHomeSectionsDto>> {

    return this.siteContentManagementGetAllHomeSection$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllHomeSectionsDto>>) => r.body as Array<OutputGetAllHomeSectionsDto>)
    );
  }

  /**
   * Path part for operation siteContentManagementDeleteHomeSection
   */
  static readonly SiteContentManagementDeleteHomeSectionPath = '/api/admin/SiteContentManagement/DeleteHomeSection';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementDeleteHomeSection$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementDeleteHomeSection$Plain$Response(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementDeleteHomeSectionPath, 'delete');
    if (params) {
      rb.query('Id', params.Id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementDeleteHomeSection$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementDeleteHomeSection$Plain(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.siteContentManagementDeleteHomeSection$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementDeleteHomeSection()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementDeleteHomeSection$Response(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementDeleteHomeSectionPath, 'delete');
    if (params) {
      rb.query('Id', params.Id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementDeleteHomeSection$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementDeleteHomeSection(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.siteContentManagementDeleteHomeSection$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation siteContentManagementCreateSlider
   */
  static readonly SiteContentManagementCreateSliderPath = '/api/admin/SiteContentManagement/CreateSlider';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementCreateSlider$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateSlider$Plain$Response(params?: {
    context?: HttpContext
    body?: InputCreateSliderDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementCreateSliderPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementCreateSlider$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateSlider$Plain(params?: {
    context?: HttpContext
    body?: InputCreateSliderDto
  }
): Observable<boolean> {

    return this.siteContentManagementCreateSlider$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementCreateSlider()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateSlider$Response(params?: {
    context?: HttpContext
    body?: InputCreateSliderDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementCreateSliderPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementCreateSlider$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateSlider(params?: {
    context?: HttpContext
    body?: InputCreateSliderDto
  }
): Observable<boolean> {

    return this.siteContentManagementCreateSlider$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation siteContentManagementDeleteSlider
   */
  static readonly SiteContentManagementDeleteSliderPath = '/api/admin/SiteContentManagement/DeleteSlider';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementDeleteSlider$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementDeleteSlider$Plain$Response(params?: {
    context?: HttpContext
    body?: InputDeleteSliderDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementDeleteSliderPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementDeleteSlider$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementDeleteSlider$Plain(params?: {
    context?: HttpContext
    body?: InputDeleteSliderDto
  }
): Observable<boolean> {

    return this.siteContentManagementDeleteSlider$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementDeleteSlider()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementDeleteSlider$Response(params?: {
    context?: HttpContext
    body?: InputDeleteSliderDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementDeleteSliderPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementDeleteSlider$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementDeleteSlider(params?: {
    context?: HttpContext
    body?: InputDeleteSliderDto
  }
): Observable<boolean> {

    return this.siteContentManagementDeleteSlider$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation siteContentManagementUpdateSlider
   */
  static readonly SiteContentManagementUpdateSliderPath = '/api/admin/SiteContentManagement/UpdateSlider';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdateSlider$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateSlider$Plain$Response(params?: {
    context?: HttpContext
    body?: InputUpdateSliderDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementUpdateSliderPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdateSlider$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateSlider$Plain(params?: {
    context?: HttpContext
    body?: InputUpdateSliderDto
  }
): Observable<boolean> {

    return this.siteContentManagementUpdateSlider$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdateSlider()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateSlider$Response(params?: {
    context?: HttpContext
    body?: InputUpdateSliderDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementUpdateSliderPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdateSlider$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateSlider(params?: {
    context?: HttpContext
    body?: InputUpdateSliderDto
  }
): Observable<boolean> {

    return this.siteContentManagementUpdateSlider$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation siteContentManagementGetAllSlider
   */
  static readonly SiteContentManagementGetAllSliderPath = '/api/admin/SiteContentManagement/GetAllSlider';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetAllSlider$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllSlider$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllSliderDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementGetAllSliderPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllSliderDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetAllSlider$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllSlider$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllSliderDto>> {

    return this.siteContentManagementGetAllSlider$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllSliderDto>>) => r.body as Array<OutputGetAllSliderDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetAllSlider()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllSlider$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllSliderDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementGetAllSliderPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllSliderDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetAllSlider$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllSlider(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllSliderDto>> {

    return this.siteContentManagementGetAllSlider$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllSliderDto>>) => r.body as Array<OutputGetAllSliderDto>)
    );
  }

  /**
   * Path part for operation siteContentManagementGetAllBlogs
   */
  static readonly SiteContentManagementGetAllBlogsPath = '/api/admin/SiteContentManagement/GetAllBlogs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetAllBlogs$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllBlogs$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllBlogsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementGetAllBlogsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllBlogsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetAllBlogs$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllBlogs$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllBlogsDto>> {

    return this.siteContentManagementGetAllBlogs$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllBlogsDto>>) => r.body as Array<OutputGetAllBlogsDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetAllBlogs()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllBlogs$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllBlogsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementGetAllBlogsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllBlogsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetAllBlogs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllBlogs(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllBlogsDto>> {

    return this.siteContentManagementGetAllBlogs$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllBlogsDto>>) => r.body as Array<OutputGetAllBlogsDto>)
    );
  }

  /**
   * Path part for operation siteContentManagementGetBlogDetails
   */
  static readonly SiteContentManagementGetBlogDetailsPath = '/api/admin/SiteContentManagement/GetBlogDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetBlogDetails$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetBlogDetails$Plain$Response(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetBlogDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementGetBlogDetailsPath, 'get');
    if (params) {
      rb.query('Id', params.Id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetBlogDetailsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetBlogDetails$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetBlogDetails$Plain(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<OutputGetBlogDetailsDto> {

    return this.siteContentManagementGetBlogDetails$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetBlogDetailsDto>) => r.body as OutputGetBlogDetailsDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetBlogDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetBlogDetails$Response(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetBlogDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementGetBlogDetailsPath, 'get');
    if (params) {
      rb.query('Id', params.Id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetBlogDetailsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetBlogDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetBlogDetails(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<OutputGetBlogDetailsDto> {

    return this.siteContentManagementGetBlogDetails$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetBlogDetailsDto>) => r.body as OutputGetBlogDetailsDto)
    );
  }

  /**
   * Path part for operation siteContentManagementCreateBlog
   */
  static readonly SiteContentManagementCreateBlogPath = '/api/admin/SiteContentManagement/CreateBlog';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementCreateBlog$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateBlog$Plain$Response(params?: {
    context?: HttpContext
    body?: InputCreateBlogDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementCreateBlogPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementCreateBlog$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateBlog$Plain(params?: {
    context?: HttpContext
    body?: InputCreateBlogDto
  }
): Observable<boolean> {

    return this.siteContentManagementCreateBlog$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementCreateBlog()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateBlog$Response(params?: {
    context?: HttpContext
    body?: InputCreateBlogDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementCreateBlogPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementCreateBlog$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateBlog(params?: {
    context?: HttpContext
    body?: InputCreateBlogDto
  }
): Observable<boolean> {

    return this.siteContentManagementCreateBlog$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation siteContentManagementUpdateBlog
   */
  static readonly SiteContentManagementUpdateBlogPath = '/api/admin/SiteContentManagement/UpdateBlog';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdateBlog$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateBlog$Plain$Response(params?: {
    context?: HttpContext
    body?: InputUpdateBlogDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementUpdateBlogPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdateBlog$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateBlog$Plain(params?: {
    context?: HttpContext
    body?: InputUpdateBlogDto
  }
): Observable<boolean> {

    return this.siteContentManagementUpdateBlog$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdateBlog()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateBlog$Response(params?: {
    context?: HttpContext
    body?: InputUpdateBlogDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementUpdateBlogPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdateBlog$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateBlog(params?: {
    context?: HttpContext
    body?: InputUpdateBlogDto
  }
): Observable<boolean> {

    return this.siteContentManagementUpdateBlog$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation siteContentManagementDeleteBlog
   */
  static readonly SiteContentManagementDeleteBlogPath = '/api/admin/SiteContentManagement/DeleteBlog';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementDeleteBlog$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementDeleteBlog$Plain$Response(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementDeleteBlogPath, 'delete');
    if (params) {
      rb.query('Id', params.Id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementDeleteBlog$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementDeleteBlog$Plain(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.siteContentManagementDeleteBlog$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementDeleteBlog()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementDeleteBlog$Response(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementDeleteBlogPath, 'delete');
    if (params) {
      rb.query('Id', params.Id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementDeleteBlog$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementDeleteBlog(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.siteContentManagementDeleteBlog$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation siteContentManagementCreateHeaderAdds
   */
  static readonly SiteContentManagementCreateHeaderAddsPath = '/api/admin/SiteContentManagement/CreateHeaderAdds';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementCreateHeaderAdds$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateHeaderAdds$Plain$Response(params?: {
    context?: HttpContext
    body?: InputCreateHeaderAddsDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementCreateHeaderAddsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementCreateHeaderAdds$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateHeaderAdds$Plain(params?: {
    context?: HttpContext
    body?: InputCreateHeaderAddsDto
  }
): Observable<boolean> {

    return this.siteContentManagementCreateHeaderAdds$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementCreateHeaderAdds()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateHeaderAdds$Response(params?: {
    context?: HttpContext
    body?: InputCreateHeaderAddsDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementCreateHeaderAddsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementCreateHeaderAdds$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateHeaderAdds(params?: {
    context?: HttpContext
    body?: InputCreateHeaderAddsDto
  }
): Observable<boolean> {

    return this.siteContentManagementCreateHeaderAdds$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation siteContentManagementUpdateHeaderAdds
   */
  static readonly SiteContentManagementUpdateHeaderAddsPath = '/api/admin/SiteContentManagement/UpdateHeaderAdds';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdateHeaderAdds$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateHeaderAdds$Plain$Response(params?: {
    context?: HttpContext
    body?: InputUpdateHeaderAddsDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementUpdateHeaderAddsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdateHeaderAdds$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateHeaderAdds$Plain(params?: {
    context?: HttpContext
    body?: InputUpdateHeaderAddsDto
  }
): Observable<boolean> {

    return this.siteContentManagementUpdateHeaderAdds$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdateHeaderAdds()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateHeaderAdds$Response(params?: {
    context?: HttpContext
    body?: InputUpdateHeaderAddsDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementUpdateHeaderAddsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdateHeaderAdds$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateHeaderAdds(params?: {
    context?: HttpContext
    body?: InputUpdateHeaderAddsDto
  }
): Observable<boolean> {

    return this.siteContentManagementUpdateHeaderAdds$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation siteContentManagementGetAllHeaderAdds
   */
  static readonly SiteContentManagementGetAllHeaderAddsPath = '/api/admin/SiteContentManagement/GetAllHeaderAdds';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetAllHeaderAdds$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllHeaderAdds$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutPutGetAllHeaderAddsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementGetAllHeaderAddsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutPutGetAllHeaderAddsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetAllHeaderAdds$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllHeaderAdds$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutPutGetAllHeaderAddsDto>> {

    return this.siteContentManagementGetAllHeaderAdds$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutPutGetAllHeaderAddsDto>>) => r.body as Array<OutPutGetAllHeaderAddsDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetAllHeaderAdds()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllHeaderAdds$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutPutGetAllHeaderAddsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementGetAllHeaderAddsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutPutGetAllHeaderAddsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetAllHeaderAdds$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllHeaderAdds(params?: {
    context?: HttpContext
  }
): Observable<Array<OutPutGetAllHeaderAddsDto>> {

    return this.siteContentManagementGetAllHeaderAdds$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutPutGetAllHeaderAddsDto>>) => r.body as Array<OutPutGetAllHeaderAddsDto>)
    );
  }

  /**
   * Path part for operation siteContentManagementDeleteHeaderAdds
   */
  static readonly SiteContentManagementDeleteHeaderAddsPath = '/api/admin/SiteContentManagement/DeleteHeaderAdds';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementDeleteHeaderAdds$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementDeleteHeaderAdds$Plain$Response(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementDeleteHeaderAddsPath, 'delete');
    if (params) {
      rb.query('Id', params.Id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementDeleteHeaderAdds$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementDeleteHeaderAdds$Plain(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.siteContentManagementDeleteHeaderAdds$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementDeleteHeaderAdds()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementDeleteHeaderAdds$Response(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementDeleteHeaderAddsPath, 'delete');
    if (params) {
      rb.query('Id', params.Id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementDeleteHeaderAdds$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementDeleteHeaderAdds(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.siteContentManagementDeleteHeaderAdds$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation siteContentManagementCreateHeaderLink
   */
  static readonly SiteContentManagementCreateHeaderLinkPath = '/api/admin/SiteContentManagement/CreateHeaderLink';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementCreateHeaderLink$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateHeaderLink$Plain$Response(params?: {
    context?: HttpContext
    body?: InputCreateHeaderLinkDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementCreateHeaderLinkPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementCreateHeaderLink$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateHeaderLink$Plain(params?: {
    context?: HttpContext
    body?: InputCreateHeaderLinkDto
  }
): Observable<boolean> {

    return this.siteContentManagementCreateHeaderLink$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementCreateHeaderLink()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateHeaderLink$Response(params?: {
    context?: HttpContext
    body?: InputCreateHeaderLinkDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementCreateHeaderLinkPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementCreateHeaderLink$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementCreateHeaderLink(params?: {
    context?: HttpContext
    body?: InputCreateHeaderLinkDto
  }
): Observable<boolean> {

    return this.siteContentManagementCreateHeaderLink$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation siteContentManagementGetAllHeaderLinks
   */
  static readonly SiteContentManagementGetAllHeaderLinksPath = '/api/admin/SiteContentManagement/GetAllHeaderLinks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetAllHeaderLinks$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllHeaderLinks$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutPutGetHeaderLinkDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementGetAllHeaderLinksPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutPutGetHeaderLinkDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetAllHeaderLinks$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllHeaderLinks$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutPutGetHeaderLinkDto>> {

    return this.siteContentManagementGetAllHeaderLinks$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutPutGetHeaderLinkDto>>) => r.body as Array<OutPutGetHeaderLinkDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetAllHeaderLinks()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllHeaderLinks$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutPutGetHeaderLinkDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementGetAllHeaderLinksPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutPutGetHeaderLinkDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetAllHeaderLinks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetAllHeaderLinks(params?: {
    context?: HttpContext
  }
): Observable<Array<OutPutGetHeaderLinkDto>> {

    return this.siteContentManagementGetAllHeaderLinks$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutPutGetHeaderLinkDto>>) => r.body as Array<OutPutGetHeaderLinkDto>)
    );
  }

  /**
   * Path part for operation siteContentManagementGetHeaderLinksCategories
   */
  static readonly SiteContentManagementGetHeaderLinksCategoriesPath = '/api/admin/SiteContentManagement/GetHeaderLinksCategories';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetHeaderLinksCategories$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetHeaderLinksCategories$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutPutGetHeaderLinkCategoryDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementGetHeaderLinksCategoriesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutPutGetHeaderLinkCategoryDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetHeaderLinksCategories$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetHeaderLinksCategories$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutPutGetHeaderLinkCategoryDto>> {

    return this.siteContentManagementGetHeaderLinksCategories$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutPutGetHeaderLinkCategoryDto>>) => r.body as Array<OutPutGetHeaderLinkCategoryDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementGetHeaderLinksCategories()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetHeaderLinksCategories$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutPutGetHeaderLinkCategoryDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementGetHeaderLinksCategoriesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutPutGetHeaderLinkCategoryDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementGetHeaderLinksCategories$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementGetHeaderLinksCategories(params?: {
    context?: HttpContext
  }
): Observable<Array<OutPutGetHeaderLinkCategoryDto>> {

    return this.siteContentManagementGetHeaderLinksCategories$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutPutGetHeaderLinkCategoryDto>>) => r.body as Array<OutPutGetHeaderLinkCategoryDto>)
    );
  }

  /**
   * Path part for operation siteContentManagementDeleteHeaderLink
   */
  static readonly SiteContentManagementDeleteHeaderLinkPath = '/api/admin/SiteContentManagement/DeleteHeaderLink';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementDeleteHeaderLink$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementDeleteHeaderLink$Plain$Response(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementDeleteHeaderLinkPath, 'delete');
    if (params) {
      rb.query('id', params.id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementDeleteHeaderLink$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementDeleteHeaderLink$Plain(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.siteContentManagementDeleteHeaderLink$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementDeleteHeaderLink()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementDeleteHeaderLink$Response(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementDeleteHeaderLinkPath, 'delete');
    if (params) {
      rb.query('id', params.id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementDeleteHeaderLink$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  siteContentManagementDeleteHeaderLink(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.siteContentManagementDeleteHeaderLink$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation siteContentManagementUpdateHeaderLink
   */
  static readonly SiteContentManagementUpdateHeaderLinkPath = '/api/admin/SiteContentManagement/UpdateHeaderLink';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdateHeaderLink$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateHeaderLink$Plain$Response(params?: {
    context?: HttpContext
    body?: InputUpdateHeaderLinkDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementUpdateHeaderLinkPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdateHeaderLink$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateHeaderLink$Plain(params?: {
    context?: HttpContext
    body?: InputUpdateHeaderLinkDto
  }
): Observable<boolean> {

    return this.siteContentManagementUpdateHeaderLink$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdateHeaderLink()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateHeaderLink$Response(params?: {
    context?: HttpContext
    body?: InputUpdateHeaderLinkDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementUpdateHeaderLinkPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdateHeaderLink$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateHeaderLink(params?: {
    context?: HttpContext
    body?: InputUpdateHeaderLinkDto
  }
): Observable<boolean> {

    return this.siteContentManagementUpdateHeaderLink$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation siteContentManagementUpdateHeaderLinkIndexes
   */
  static readonly SiteContentManagementUpdateHeaderLinkIndexesPath = '/api/admin/SiteContentManagement/UpdateHeaderLinkIndexes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdateHeaderLinkIndexes$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateHeaderLinkIndexes$Plain$Response(params?: {
    context?: HttpContext
    body?: Array<InputUpdateHeaderLinkDto>
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementUpdateHeaderLinkIndexesPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdateHeaderLinkIndexes$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateHeaderLinkIndexes$Plain(params?: {
    context?: HttpContext
    body?: Array<InputUpdateHeaderLinkDto>
  }
): Observable<boolean> {

    return this.siteContentManagementUpdateHeaderLinkIndexes$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `siteContentManagementUpdateHeaderLinkIndexes()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateHeaderLinkIndexes$Response(params?: {
    context?: HttpContext
    body?: Array<InputUpdateHeaderLinkDto>
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SiteContentManagementService.SiteContentManagementUpdateHeaderLinkIndexesPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `siteContentManagementUpdateHeaderLinkIndexes$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  siteContentManagementUpdateHeaderLinkIndexes(params?: {
    context?: HttpContext
    body?: Array<InputUpdateHeaderLinkDto>
  }
): Observable<boolean> {

    return this.siteContentManagementUpdateHeaderLinkIndexes$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
