/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { InputCreateBranchDto } from '../models/input-create-branch-dto';
import { InputCreateCityDto } from '../models/input-create-city-dto';
import { InputCreateCountryDto } from '../models/input-create-country-dto';
import { InputCreateGovernorateDto } from '../models/input-create-governorate-dto';
import { InputDeleteBranchDto } from '../models/input-delete-branch-dto';
import { InputDeleteCityDto } from '../models/input-delete-city-dto';
import { InputDeleteCountryDto } from '../models/input-delete-country-dto';
import { InputDeleteGovernorateDto } from '../models/input-delete-governorate-dto';
import { InputUpdateBranchDto } from '../models/input-update-branch-dto';
import { InputUpdateCityDto } from '../models/input-update-city-dto';
import { InputUpdateCountryDto } from '../models/input-update-country-dto';
import { InputUpdateGovernorateDto } from '../models/input-update-governorate-dto';
import { OutputGetAllBranchDto } from '../models/output-get-all-branch-dto';
import { OutputGetAllCityDto } from '../models/output-get-all-city-dto';
import { OutputGetAllCountryDto } from '../models/output-get-all-country-dto';
import { OutputGetAllGovernorateDto } from '../models/output-get-all-governorate-dto';

@Injectable({
  providedIn: 'root',
})
export class PlaceManagementService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation placeManagementCreateBranch
   */
  static readonly PlaceManagementCreateBranchPath = '/api/admin/PlaceManagement/CreateBranch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementCreateBranch$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementCreateBranch$Plain$Response(params?: {
    context?: HttpContext
    body?: InputCreateBranchDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceManagementService.PlaceManagementCreateBranchPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `placeManagementCreateBranch$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementCreateBranch$Plain(params?: {
    context?: HttpContext
    body?: InputCreateBranchDto
  }
): Observable<boolean> {

    return this.placeManagementCreateBranch$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementCreateBranch()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementCreateBranch$Response(params?: {
    context?: HttpContext
    body?: InputCreateBranchDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceManagementService.PlaceManagementCreateBranchPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `placeManagementCreateBranch$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementCreateBranch(params?: {
    context?: HttpContext
    body?: InputCreateBranchDto
  }
): Observable<boolean> {

    return this.placeManagementCreateBranch$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation placeManagementDeleteBranch
   */
  static readonly PlaceManagementDeleteBranchPath = '/api/admin/PlaceManagement/DeleteBranch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementDeleteBranch$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementDeleteBranch$Plain$Response(params?: {
    context?: HttpContext
    body?: InputDeleteBranchDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceManagementService.PlaceManagementDeleteBranchPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `placeManagementDeleteBranch$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementDeleteBranch$Plain(params?: {
    context?: HttpContext
    body?: InputDeleteBranchDto
  }
): Observable<boolean> {

    return this.placeManagementDeleteBranch$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementDeleteBranch()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementDeleteBranch$Response(params?: {
    context?: HttpContext
    body?: InputDeleteBranchDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceManagementService.PlaceManagementDeleteBranchPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `placeManagementDeleteBranch$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementDeleteBranch(params?: {
    context?: HttpContext
    body?: InputDeleteBranchDto
  }
): Observable<boolean> {

    return this.placeManagementDeleteBranch$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation placeManagementUpdateBranch
   */
  static readonly PlaceManagementUpdateBranchPath = '/api/admin/PlaceManagement/UpdateBranch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementUpdateBranch$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementUpdateBranch$Plain$Response(params?: {
    context?: HttpContext
    body?: InputUpdateBranchDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceManagementService.PlaceManagementUpdateBranchPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `placeManagementUpdateBranch$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementUpdateBranch$Plain(params?: {
    context?: HttpContext
    body?: InputUpdateBranchDto
  }
): Observable<boolean> {

    return this.placeManagementUpdateBranch$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementUpdateBranch()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementUpdateBranch$Response(params?: {
    context?: HttpContext
    body?: InputUpdateBranchDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceManagementService.PlaceManagementUpdateBranchPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `placeManagementUpdateBranch$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementUpdateBranch(params?: {
    context?: HttpContext
    body?: InputUpdateBranchDto
  }
): Observable<boolean> {

    return this.placeManagementUpdateBranch$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation placeManagementGetAllBranch
   */
  static readonly PlaceManagementGetAllBranchPath = '/api/admin/PlaceManagement/GetAllBranch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementGetAllBranch$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllBranch$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllBranchDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceManagementService.PlaceManagementGetAllBranchPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllBranchDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `placeManagementGetAllBranch$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllBranch$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllBranchDto>> {

    return this.placeManagementGetAllBranch$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllBranchDto>>) => r.body as Array<OutputGetAllBranchDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementGetAllBranch()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllBranch$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllBranchDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceManagementService.PlaceManagementGetAllBranchPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllBranchDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `placeManagementGetAllBranch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllBranch(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllBranchDto>> {

    return this.placeManagementGetAllBranch$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllBranchDto>>) => r.body as Array<OutputGetAllBranchDto>)
    );
  }

  /**
   * Path part for operation placeManagementGetAllBranchByCityId
   */
  static readonly PlaceManagementGetAllBranchByCityIdPath = '/api/admin/PlaceManagement/GetAllBranchByCityId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementGetAllBranchByCityId$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllBranchByCityId$Plain$Response(params?: {
    CityId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllBranchDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceManagementService.PlaceManagementGetAllBranchByCityIdPath, 'get');
    if (params) {
      rb.query('CityId', params.CityId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllBranchDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `placeManagementGetAllBranchByCityId$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllBranchByCityId$Plain(params?: {
    CityId?: number;
    context?: HttpContext
  }
): Observable<Array<OutputGetAllBranchDto>> {

    return this.placeManagementGetAllBranchByCityId$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllBranchDto>>) => r.body as Array<OutputGetAllBranchDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementGetAllBranchByCityId()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllBranchByCityId$Response(params?: {
    CityId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllBranchDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceManagementService.PlaceManagementGetAllBranchByCityIdPath, 'get');
    if (params) {
      rb.query('CityId', params.CityId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllBranchDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `placeManagementGetAllBranchByCityId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllBranchByCityId(params?: {
    CityId?: number;
    context?: HttpContext
  }
): Observable<Array<OutputGetAllBranchDto>> {

    return this.placeManagementGetAllBranchByCityId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllBranchDto>>) => r.body as Array<OutputGetAllBranchDto>)
    );
  }

  /**
   * Path part for operation placeManagementCreateCity
   */
  static readonly PlaceManagementCreateCityPath = '/api/admin/PlaceManagement/CreateCity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementCreateCity$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementCreateCity$Plain$Response(params?: {
    context?: HttpContext
    body?: InputCreateCityDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceManagementService.PlaceManagementCreateCityPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `placeManagementCreateCity$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementCreateCity$Plain(params?: {
    context?: HttpContext
    body?: InputCreateCityDto
  }
): Observable<boolean> {

    return this.placeManagementCreateCity$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementCreateCity()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementCreateCity$Response(params?: {
    context?: HttpContext
    body?: InputCreateCityDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceManagementService.PlaceManagementCreateCityPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `placeManagementCreateCity$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementCreateCity(params?: {
    context?: HttpContext
    body?: InputCreateCityDto
  }
): Observable<boolean> {

    return this.placeManagementCreateCity$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation placeManagementDeleteCity
   */
  static readonly PlaceManagementDeleteCityPath = '/api/admin/PlaceManagement/DeleteCity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementDeleteCity$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementDeleteCity$Plain$Response(params?: {
    context?: HttpContext
    body?: InputDeleteCityDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceManagementService.PlaceManagementDeleteCityPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `placeManagementDeleteCity$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementDeleteCity$Plain(params?: {
    context?: HttpContext
    body?: InputDeleteCityDto
  }
): Observable<boolean> {

    return this.placeManagementDeleteCity$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementDeleteCity()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementDeleteCity$Response(params?: {
    context?: HttpContext
    body?: InputDeleteCityDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceManagementService.PlaceManagementDeleteCityPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `placeManagementDeleteCity$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementDeleteCity(params?: {
    context?: HttpContext
    body?: InputDeleteCityDto
  }
): Observable<boolean> {

    return this.placeManagementDeleteCity$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation placeManagementUpdateCity
   */
  static readonly PlaceManagementUpdateCityPath = '/api/admin/PlaceManagement/UpdateCity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementUpdateCity$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementUpdateCity$Plain$Response(params?: {
    context?: HttpContext
    body?: InputUpdateCityDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceManagementService.PlaceManagementUpdateCityPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `placeManagementUpdateCity$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementUpdateCity$Plain(params?: {
    context?: HttpContext
    body?: InputUpdateCityDto
  }
): Observable<boolean> {

    return this.placeManagementUpdateCity$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementUpdateCity()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementUpdateCity$Response(params?: {
    context?: HttpContext
    body?: InputUpdateCityDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceManagementService.PlaceManagementUpdateCityPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `placeManagementUpdateCity$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementUpdateCity(params?: {
    context?: HttpContext
    body?: InputUpdateCityDto
  }
): Observable<boolean> {

    return this.placeManagementUpdateCity$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation placeManagementGetAllCity
   */
  static readonly PlaceManagementGetAllCityPath = '/api/admin/PlaceManagement/GetAllCity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementGetAllCity$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllCity$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllCityDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceManagementService.PlaceManagementGetAllCityPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllCityDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `placeManagementGetAllCity$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllCity$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllCityDto>> {

    return this.placeManagementGetAllCity$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllCityDto>>) => r.body as Array<OutputGetAllCityDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementGetAllCity()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllCity$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllCityDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceManagementService.PlaceManagementGetAllCityPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllCityDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `placeManagementGetAllCity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllCity(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllCityDto>> {

    return this.placeManagementGetAllCity$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllCityDto>>) => r.body as Array<OutputGetAllCityDto>)
    );
  }

  /**
   * Path part for operation placeManagementGetAllCityByGovenorateId
   */
  static readonly PlaceManagementGetAllCityByGovenorateIdPath = '/api/admin/PlaceManagement/GetAllCityByGovenorateId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementGetAllCityByGovenorateId$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllCityByGovenorateId$Plain$Response(params?: {
    GovenorateId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllCityDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceManagementService.PlaceManagementGetAllCityByGovenorateIdPath, 'get');
    if (params) {
      rb.query('GovenorateId', params.GovenorateId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllCityDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `placeManagementGetAllCityByGovenorateId$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllCityByGovenorateId$Plain(params?: {
    GovenorateId?: number;
    context?: HttpContext
  }
): Observable<Array<OutputGetAllCityDto>> {

    return this.placeManagementGetAllCityByGovenorateId$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllCityDto>>) => r.body as Array<OutputGetAllCityDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementGetAllCityByGovenorateId()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllCityByGovenorateId$Response(params?: {
    GovenorateId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllCityDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceManagementService.PlaceManagementGetAllCityByGovenorateIdPath, 'get');
    if (params) {
      rb.query('GovenorateId', params.GovenorateId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllCityDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `placeManagementGetAllCityByGovenorateId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllCityByGovenorateId(params?: {
    GovenorateId?: number;
    context?: HttpContext
  }
): Observable<Array<OutputGetAllCityDto>> {

    return this.placeManagementGetAllCityByGovenorateId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllCityDto>>) => r.body as Array<OutputGetAllCityDto>)
    );
  }

  /**
   * Path part for operation placeManagementCreateGovernorate
   */
  static readonly PlaceManagementCreateGovernoratePath = '/api/admin/PlaceManagement/CreateGovernorate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementCreateGovernorate$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementCreateGovernorate$Plain$Response(params?: {
    context?: HttpContext
    body?: InputCreateGovernorateDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceManagementService.PlaceManagementCreateGovernoratePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `placeManagementCreateGovernorate$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementCreateGovernorate$Plain(params?: {
    context?: HttpContext
    body?: InputCreateGovernorateDto
  }
): Observable<boolean> {

    return this.placeManagementCreateGovernorate$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementCreateGovernorate()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementCreateGovernorate$Response(params?: {
    context?: HttpContext
    body?: InputCreateGovernorateDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceManagementService.PlaceManagementCreateGovernoratePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `placeManagementCreateGovernorate$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementCreateGovernorate(params?: {
    context?: HttpContext
    body?: InputCreateGovernorateDto
  }
): Observable<boolean> {

    return this.placeManagementCreateGovernorate$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation placeManagementDeleteGovernorate
   */
  static readonly PlaceManagementDeleteGovernoratePath = '/api/admin/PlaceManagement/DeleteGovernorate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementDeleteGovernorate$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementDeleteGovernorate$Plain$Response(params?: {
    context?: HttpContext
    body?: InputDeleteGovernorateDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceManagementService.PlaceManagementDeleteGovernoratePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `placeManagementDeleteGovernorate$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementDeleteGovernorate$Plain(params?: {
    context?: HttpContext
    body?: InputDeleteGovernorateDto
  }
): Observable<boolean> {

    return this.placeManagementDeleteGovernorate$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementDeleteGovernorate()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementDeleteGovernorate$Response(params?: {
    context?: HttpContext
    body?: InputDeleteGovernorateDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceManagementService.PlaceManagementDeleteGovernoratePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `placeManagementDeleteGovernorate$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementDeleteGovernorate(params?: {
    context?: HttpContext
    body?: InputDeleteGovernorateDto
  }
): Observable<boolean> {

    return this.placeManagementDeleteGovernorate$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation placeManagementUpdateGovernorate
   */
  static readonly PlaceManagementUpdateGovernoratePath = '/api/admin/PlaceManagement/UpdateGovernorate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementUpdateGovernorate$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementUpdateGovernorate$Plain$Response(params?: {
    context?: HttpContext
    body?: InputUpdateGovernorateDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceManagementService.PlaceManagementUpdateGovernoratePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `placeManagementUpdateGovernorate$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementUpdateGovernorate$Plain(params?: {
    context?: HttpContext
    body?: InputUpdateGovernorateDto
  }
): Observable<boolean> {

    return this.placeManagementUpdateGovernorate$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementUpdateGovernorate()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementUpdateGovernorate$Response(params?: {
    context?: HttpContext
    body?: InputUpdateGovernorateDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceManagementService.PlaceManagementUpdateGovernoratePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `placeManagementUpdateGovernorate$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementUpdateGovernorate(params?: {
    context?: HttpContext
    body?: InputUpdateGovernorateDto
  }
): Observable<boolean> {

    return this.placeManagementUpdateGovernorate$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation placeManagementGetAllGovernorate
   */
  static readonly PlaceManagementGetAllGovernoratePath = '/api/admin/PlaceManagement/GetAllGovernorate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementGetAllGovernorate$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllGovernorate$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllGovernorateDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceManagementService.PlaceManagementGetAllGovernoratePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllGovernorateDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `placeManagementGetAllGovernorate$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllGovernorate$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllGovernorateDto>> {

    return this.placeManagementGetAllGovernorate$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllGovernorateDto>>) => r.body as Array<OutputGetAllGovernorateDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementGetAllGovernorate()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllGovernorate$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllGovernorateDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceManagementService.PlaceManagementGetAllGovernoratePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllGovernorateDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `placeManagementGetAllGovernorate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllGovernorate(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllGovernorateDto>> {

    return this.placeManagementGetAllGovernorate$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllGovernorateDto>>) => r.body as Array<OutputGetAllGovernorateDto>)
    );
  }

  /**
   * Path part for operation placeManagementCreateCountry
   */
  static readonly PlaceManagementCreateCountryPath = '/api/admin/PlaceManagement/CreateCountry';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementCreateCountry$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementCreateCountry$Plain$Response(params?: {
    context?: HttpContext
    body?: InputCreateCountryDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceManagementService.PlaceManagementCreateCountryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `placeManagementCreateCountry$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementCreateCountry$Plain(params?: {
    context?: HttpContext
    body?: InputCreateCountryDto
  }
): Observable<boolean> {

    return this.placeManagementCreateCountry$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementCreateCountry()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementCreateCountry$Response(params?: {
    context?: HttpContext
    body?: InputCreateCountryDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceManagementService.PlaceManagementCreateCountryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `placeManagementCreateCountry$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementCreateCountry(params?: {
    context?: HttpContext
    body?: InputCreateCountryDto
  }
): Observable<boolean> {

    return this.placeManagementCreateCountry$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation placeManagementDeleteCountry
   */
  static readonly PlaceManagementDeleteCountryPath = '/api/admin/PlaceManagement/DeleteCountry';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementDeleteCountry$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementDeleteCountry$Plain$Response(params?: {
    context?: HttpContext
    body?: InputDeleteCountryDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceManagementService.PlaceManagementDeleteCountryPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `placeManagementDeleteCountry$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementDeleteCountry$Plain(params?: {
    context?: HttpContext
    body?: InputDeleteCountryDto
  }
): Observable<boolean> {

    return this.placeManagementDeleteCountry$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementDeleteCountry()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementDeleteCountry$Response(params?: {
    context?: HttpContext
    body?: InputDeleteCountryDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceManagementService.PlaceManagementDeleteCountryPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `placeManagementDeleteCountry$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementDeleteCountry(params?: {
    context?: HttpContext
    body?: InputDeleteCountryDto
  }
): Observable<boolean> {

    return this.placeManagementDeleteCountry$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation placeManagementUpdateCountry
   */
  static readonly PlaceManagementUpdateCountryPath = '/api/admin/PlaceManagement/UpdateCountry';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementUpdateCountry$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementUpdateCountry$Plain$Response(params?: {
    context?: HttpContext
    body?: InputUpdateCountryDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceManagementService.PlaceManagementUpdateCountryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `placeManagementUpdateCountry$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementUpdateCountry$Plain(params?: {
    context?: HttpContext
    body?: InputUpdateCountryDto
  }
): Observable<boolean> {

    return this.placeManagementUpdateCountry$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementUpdateCountry()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementUpdateCountry$Response(params?: {
    context?: HttpContext
    body?: InputUpdateCountryDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceManagementService.PlaceManagementUpdateCountryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `placeManagementUpdateCountry$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  placeManagementUpdateCountry(params?: {
    context?: HttpContext
    body?: InputUpdateCountryDto
  }
): Observable<boolean> {

    return this.placeManagementUpdateCountry$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation placeManagementGetAllCountry
   */
  static readonly PlaceManagementGetAllCountryPath = '/api/admin/PlaceManagement/GetAllCountry';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementGetAllCountry$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllCountry$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllCountryDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceManagementService.PlaceManagementGetAllCountryPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllCountryDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `placeManagementGetAllCountry$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllCountry$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllCountryDto>> {

    return this.placeManagementGetAllCountry$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllCountryDto>>) => r.body as Array<OutputGetAllCountryDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeManagementGetAllCountry()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllCountry$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllCountryDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceManagementService.PlaceManagementGetAllCountryPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllCountryDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `placeManagementGetAllCountry$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  placeManagementGetAllCountry(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllCountryDto>> {

    return this.placeManagementGetAllCountry$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllCountryDto>>) => r.body as Array<OutputGetAllCountryDto>)
    );
  }

}
