/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GetAllStylistWithdrawalDto } from '../models/get-all-stylist-withdrawal-dto';
import { InputAddStylistWithdrawalRequestDto } from '../models/input-add-stylist-withdrawal-request-dto';
import { InputCompeleteStylistInformation } from '../models/input-compelete-stylist-information';
import { InputCreateStylistBankAccountDto } from '../models/input-create-stylist-bank-account-dto';
import { InputCreateStylistCollectionDto } from '../models/input-create-stylist-collection-dto';
import { InputCreateStylistWorkDto } from '../models/input-create-stylist-work-dto';
import { InputDeleteStylistWorkDto } from '../models/input-delete-stylist-work-dto';
import { InputUpdateCoverImageDto } from '../models/input-update-cover-image-dto';
import { InputUpdateProfileImageDto } from '../models/input-update-profile-image-dto';
import { InputUpdateStylistInformationDto } from '../models/input-update-stylist-information-dto';
import { InputUpdateStylistWorkDto } from '../models/input-update-stylist-work-dto';
import { OutputGetAllAgeDto } from '../models/output-get-all-age-dto';
import { OutputGetAllStylesDto } from '../models/output-get-all-styles-dto';
import { OutputGetAllStylistCollectionDto } from '../models/output-get-all-stylist-collection-dto';
import { OutputGetStylistBankAccountDto } from '../models/output-get-stylist-bank-account-dto';
import { OutputGetStylistProfileDto } from '../models/output-get-stylist-profile-dto';
import { OutputGetStylistUpdateDto } from '../models/output-get-stylist-update-dto';
import { OutputGetStylistWorkDto } from '../models/output-get-stylist-work-dto';
import { OutputStylistAnalytics } from '../models/output-stylist-analytics';
import { OutputStylistEarning } from '../models/output-stylist-earning';
import { StylistDashboardDto } from '../models/stylist-dashboard-dto';

@Injectable({
  providedIn: 'root',
})
export class StylistProfileManagementService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation stylistProfileManagementGetStylistForUpdate
   */
  static readonly StylistProfileManagementGetStylistForUpdatePath = '/api/customer/StylistProfileManagement/GetStylistForUpdate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetStylistForUpdate$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistForUpdate$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetStylistUpdateDto>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementGetStylistForUpdatePath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetStylistUpdateDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetStylistForUpdate$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistForUpdate$Plain(params?: {
    context?: HttpContext
  }
): Observable<OutputGetStylistUpdateDto> {

    return this.stylistProfileManagementGetStylistForUpdate$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetStylistUpdateDto>) => r.body as OutputGetStylistUpdateDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetStylistForUpdate()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistForUpdate$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetStylistUpdateDto>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementGetStylistForUpdatePath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetStylistUpdateDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetStylistForUpdate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistForUpdate(params?: {
    context?: HttpContext
  }
): Observable<OutputGetStylistUpdateDto> {

    return this.stylistProfileManagementGetStylistForUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetStylistUpdateDto>) => r.body as OutputGetStylistUpdateDto)
    );
  }

  /**
   * Path part for operation stylistProfileManagementUpdateStylist
   */
  static readonly StylistProfileManagementUpdateStylistPath = '/api/customer/StylistProfileManagement/UpdateStylist';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementUpdateStylist()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementUpdateStylist$Response(params?: {
    context?: HttpContext
    body?: OutputGetStylistUpdateDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementUpdateStylistPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementUpdateStylist$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementUpdateStylist(params?: {
    context?: HttpContext
    body?: OutputGetStylistUpdateDto
  }
): Observable<void> {

    return this.stylistProfileManagementUpdateStylist$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation stylistProfileManagementGetStylistAnalytics
   */
  static readonly StylistProfileManagementGetStylistAnalyticsPath = '/api/customer/StylistProfileManagement/GetStylistAnalytics';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetStylistAnalytics$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistAnalytics$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputStylistAnalytics>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementGetStylistAnalyticsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputStylistAnalytics>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetStylistAnalytics$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistAnalytics$Plain(params?: {
    context?: HttpContext
  }
): Observable<OutputStylistAnalytics> {

    return this.stylistProfileManagementGetStylistAnalytics$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputStylistAnalytics>) => r.body as OutputStylistAnalytics)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetStylistAnalytics()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistAnalytics$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputStylistAnalytics>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementGetStylistAnalyticsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputStylistAnalytics>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetStylistAnalytics$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistAnalytics(params?: {
    context?: HttpContext
  }
): Observable<OutputStylistAnalytics> {

    return this.stylistProfileManagementGetStylistAnalytics$Response(params).pipe(
      map((r: StrictHttpResponse<OutputStylistAnalytics>) => r.body as OutputStylistAnalytics)
    );
  }

  /**
   * Path part for operation stylistProfileManagementUpdateProfileImage
   */
  static readonly StylistProfileManagementUpdateProfileImagePath = '/api/customer/StylistProfileManagement/UpdateProfileImage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementUpdateProfileImage()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementUpdateProfileImage$Response(params?: {
    context?: HttpContext
    body?: InputUpdateProfileImageDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementUpdateProfileImagePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementUpdateProfileImage$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementUpdateProfileImage(params?: {
    context?: HttpContext
    body?: InputUpdateProfileImageDto
  }
): Observable<void> {

    return this.stylistProfileManagementUpdateProfileImage$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation stylistProfileManagementCompeleteStylistInformation
   */
  static readonly StylistProfileManagementCompeleteStylistInformationPath = '/api/customer/StylistProfileManagement/CompeleteStylistInformation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementCompeleteStylistInformation()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementCompeleteStylistInformation$Response(params?: {
    context?: HttpContext
    body?: InputCompeleteStylistInformation
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementCompeleteStylistInformationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementCompeleteStylistInformation$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementCompeleteStylistInformation(params?: {
    context?: HttpContext
    body?: InputCompeleteStylistInformation
  }
): Observable<void> {

    return this.stylistProfileManagementCompeleteStylistInformation$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation stylistProfileManagementUpdateCoverImage
   */
  static readonly StylistProfileManagementUpdateCoverImagePath = '/api/customer/StylistProfileManagement/UpdateCoverImage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementUpdateCoverImage()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementUpdateCoverImage$Response(params?: {
    context?: HttpContext
    body?: InputUpdateCoverImageDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementUpdateCoverImagePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementUpdateCoverImage$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementUpdateCoverImage(params?: {
    context?: HttpContext
    body?: InputUpdateCoverImageDto
  }
): Observable<void> {

    return this.stylistProfileManagementUpdateCoverImage$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation stylistProfileManagementUpdateStylistInformation
   */
  static readonly StylistProfileManagementUpdateStylistInformationPath = '/api/customer/StylistProfileManagement/UpdateStylistInformation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementUpdateStylistInformation()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementUpdateStylistInformation$Response(params?: {
    context?: HttpContext
    body?: InputUpdateStylistInformationDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementUpdateStylistInformationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementUpdateStylistInformation$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementUpdateStylistInformation(params?: {
    context?: HttpContext
    body?: InputUpdateStylistInformationDto
  }
): Observable<void> {

    return this.stylistProfileManagementUpdateStylistInformation$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation stylistProfileManagementGetStylistProfile
   */
  static readonly StylistProfileManagementGetStylistProfilePath = '/api/customer/StylistProfileManagement/GetStylistProfile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetStylistProfile$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistProfile$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetStylistProfileDto>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementGetStylistProfilePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetStylistProfileDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetStylistProfile$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistProfile$Plain(params?: {
    context?: HttpContext
  }
): Observable<OutputGetStylistProfileDto> {

    return this.stylistProfileManagementGetStylistProfile$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetStylistProfileDto>) => r.body as OutputGetStylistProfileDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetStylistProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistProfile$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetStylistProfileDto>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementGetStylistProfilePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetStylistProfileDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetStylistProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistProfile(params?: {
    context?: HttpContext
  }
): Observable<OutputGetStylistProfileDto> {

    return this.stylistProfileManagementGetStylistProfile$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetStylistProfileDto>) => r.body as OutputGetStylistProfileDto)
    );
  }

  /**
   * Path part for operation stylistProfileManagementCreateStylistWork
   */
  static readonly StylistProfileManagementCreateStylistWorkPath = '/api/customer/StylistProfileManagement/CreateStylistWork';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementCreateStylistWork()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementCreateStylistWork$Response(params?: {
    context?: HttpContext
    body?: InputCreateStylistWorkDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementCreateStylistWorkPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementCreateStylistWork$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementCreateStylistWork(params?: {
    context?: HttpContext
    body?: InputCreateStylistWorkDto
  }
): Observable<void> {

    return this.stylistProfileManagementCreateStylistWork$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation stylistProfileManagementCreateStylistDraft
   */
  static readonly StylistProfileManagementCreateStylistDraftPath = '/api/customer/StylistProfileManagement/CreateStylistDraft';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementCreateStylistDraft()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementCreateStylistDraft$Response(params?: {
    context?: HttpContext
    body?: InputCreateStylistWorkDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementCreateStylistDraftPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementCreateStylistDraft$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementCreateStylistDraft(params?: {
    context?: HttpContext
    body?: InputCreateStylistWorkDto
  }
): Observable<void> {

    return this.stylistProfileManagementCreateStylistDraft$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation stylistProfileManagementDeleteStylistWork
   */
  static readonly StylistProfileManagementDeleteStylistWorkPath = '/api/customer/StylistProfileManagement/DeleteStylistWork';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementDeleteStylistWork()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementDeleteStylistWork$Response(params?: {
    context?: HttpContext
    body?: InputDeleteStylistWorkDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementDeleteStylistWorkPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementDeleteStylistWork$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementDeleteStylistWork(params?: {
    context?: HttpContext
    body?: InputDeleteStylistWorkDto
  }
): Observable<void> {

    return this.stylistProfileManagementDeleteStylistWork$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation stylistProfileManagementUpdateStylistWork
   */
  static readonly StylistProfileManagementUpdateStylistWorkPath = '/api/customer/StylistProfileManagement/UpdateStylistWork';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementUpdateStylistWork()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementUpdateStylistWork$Response(params?: {
    context?: HttpContext
    body?: InputUpdateStylistWorkDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementUpdateStylistWorkPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementUpdateStylistWork$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementUpdateStylistWork(params?: {
    context?: HttpContext
    body?: InputUpdateStylistWorkDto
  }
): Observable<void> {

    return this.stylistProfileManagementUpdateStylistWork$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation stylistProfileManagementUpdateDraftStatus
   */
  static readonly StylistProfileManagementUpdateDraftStatusPath = '/api/customer/StylistProfileManagement/UpdateDraftStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementUpdateDraftStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementUpdateDraftStatus$Response(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementUpdateDraftStatusPath, 'post');
    if (params) {
      rb.query('Id', params.Id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementUpdateDraftStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementUpdateDraftStatus(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<void> {

    return this.stylistProfileManagementUpdateDraftStatus$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation stylistProfileManagementGetStylistDraft
   */
  static readonly StylistProfileManagementGetStylistDraftPath = '/api/customer/StylistProfileManagement/GetStylistDraft';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetStylistDraft$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistDraft$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetStylistWorkDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementGetStylistDraftPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetStylistWorkDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetStylistDraft$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistDraft$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetStylistWorkDto>> {

    return this.stylistProfileManagementGetStylistDraft$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetStylistWorkDto>>) => r.body as Array<OutputGetStylistWorkDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetStylistDraft()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistDraft$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetStylistWorkDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementGetStylistDraftPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetStylistWorkDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetStylistDraft$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistDraft(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetStylistWorkDto>> {

    return this.stylistProfileManagementGetStylistDraft$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetStylistWorkDto>>) => r.body as Array<OutputGetStylistWorkDto>)
    );
  }

  /**
   * Path part for operation stylistProfileManagementGetOneStylistWork
   */
  static readonly StylistProfileManagementGetOneStylistWorkPath = '/api/customer/StylistProfileManagement/GetOneStylistWork';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetOneStylistWork$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetOneStylistWork$Plain$Response(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetStylistWorkDto>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementGetOneStylistWorkPath, 'get');
    if (params) {
      rb.query('Id', params.Id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetStylistWorkDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetOneStylistWork$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetOneStylistWork$Plain(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<OutputGetStylistWorkDto> {

    return this.stylistProfileManagementGetOneStylistWork$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetStylistWorkDto>) => r.body as OutputGetStylistWorkDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetOneStylistWork()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetOneStylistWork$Response(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetStylistWorkDto>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementGetOneStylistWorkPath, 'get');
    if (params) {
      rb.query('Id', params.Id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetStylistWorkDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetOneStylistWork$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetOneStylistWork(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<OutputGetStylistWorkDto> {

    return this.stylistProfileManagementGetOneStylistWork$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetStylistWorkDto>) => r.body as OutputGetStylistWorkDto)
    );
  }

  /**
   * Path part for operation stylistProfileManagementGetAllStylistWork
   */
  static readonly StylistProfileManagementGetAllStylistWorkPath = '/api/customer/StylistProfileManagement/GetAllStylistWork';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetAllStylistWork$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllStylistWork$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetStylistWorkDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementGetAllStylistWorkPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetStylistWorkDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetAllStylistWork$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllStylistWork$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetStylistWorkDto>> {

    return this.stylistProfileManagementGetAllStylistWork$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetStylistWorkDto>>) => r.body as Array<OutputGetStylistWorkDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetAllStylistWork()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllStylistWork$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetStylistWorkDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementGetAllStylistWorkPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetStylistWorkDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetAllStylistWork$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllStylistWork(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetStylistWorkDto>> {

    return this.stylistProfileManagementGetAllStylistWork$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetStylistWorkDto>>) => r.body as Array<OutputGetStylistWorkDto>)
    );
  }

  /**
   * Path part for operation stylistProfileManagementGetAllStyle
   */
  static readonly StylistProfileManagementGetAllStylePath = '/api/customer/StylistProfileManagement/GetAllStyle';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetAllStyle$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllStyle$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllStylesDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementGetAllStylePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllStylesDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetAllStyle$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllStyle$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllStylesDto>> {

    return this.stylistProfileManagementGetAllStyle$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllStylesDto>>) => r.body as Array<OutputGetAllStylesDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetAllStyle()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllStyle$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllStylesDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementGetAllStylePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllStylesDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetAllStyle$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllStyle(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllStylesDto>> {

    return this.stylistProfileManagementGetAllStyle$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllStylesDto>>) => r.body as Array<OutputGetAllStylesDto>)
    );
  }

  /**
   * Path part for operation stylistProfileManagementGetAllAge
   */
  static readonly StylistProfileManagementGetAllAgePath = '/api/customer/StylistProfileManagement/GetAllAge';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetAllAge$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllAge$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllAgeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementGetAllAgePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllAgeDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetAllAge$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllAge$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllAgeDto>> {

    return this.stylistProfileManagementGetAllAge$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllAgeDto>>) => r.body as Array<OutputGetAllAgeDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetAllAge()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllAge$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllAgeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementGetAllAgePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllAgeDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetAllAge$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllAge(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllAgeDto>> {

    return this.stylistProfileManagementGetAllAge$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllAgeDto>>) => r.body as Array<OutputGetAllAgeDto>)
    );
  }

  /**
   * Path part for operation stylistProfileManagementCreateStylistCollection
   */
  static readonly StylistProfileManagementCreateStylistCollectionPath = '/api/customer/StylistProfileManagement/CreateStylistCollection';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementCreateStylistCollection$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementCreateStylistCollection$Plain$Response(params?: {
    context?: HttpContext
    body?: InputCreateStylistCollectionDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementCreateStylistCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementCreateStylistCollection$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementCreateStylistCollection$Plain(params?: {
    context?: HttpContext
    body?: InputCreateStylistCollectionDto
  }
): Observable<boolean> {

    return this.stylistProfileManagementCreateStylistCollection$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementCreateStylistCollection()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementCreateStylistCollection$Response(params?: {
    context?: HttpContext
    body?: InputCreateStylistCollectionDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementCreateStylistCollectionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementCreateStylistCollection$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementCreateStylistCollection(params?: {
    context?: HttpContext
    body?: InputCreateStylistCollectionDto
  }
): Observable<boolean> {

    return this.stylistProfileManagementCreateStylistCollection$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation stylistProfileManagementDeleteStylistCollection
   */
  static readonly StylistProfileManagementDeleteStylistCollectionPath = '/api/customer/StylistProfileManagement/DeleteStylistCollection';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementDeleteStylistCollection$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementDeleteStylistCollection$Plain$Response(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementDeleteStylistCollectionPath, 'delete');
    if (params) {
      rb.query('id', params.id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementDeleteStylistCollection$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementDeleteStylistCollection$Plain(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.stylistProfileManagementDeleteStylistCollection$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementDeleteStylistCollection()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementDeleteStylistCollection$Response(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementDeleteStylistCollectionPath, 'delete');
    if (params) {
      rb.query('id', params.id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementDeleteStylistCollection$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementDeleteStylistCollection(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.stylistProfileManagementDeleteStylistCollection$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation stylistProfileManagementUpdateStylistCollectionPendingType
   */
  static readonly StylistProfileManagementUpdateStylistCollectionPendingTypePath = '/api/customer/StylistProfileManagement/UpdateStylistCollectionPendingType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementUpdateStylistCollectionPendingType$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementUpdateStylistCollectionPendingType$Plain$Response(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementUpdateStylistCollectionPendingTypePath, 'post');
    if (params) {
      rb.query('id', params.id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementUpdateStylistCollectionPendingType$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementUpdateStylistCollectionPendingType$Plain(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.stylistProfileManagementUpdateStylistCollectionPendingType$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementUpdateStylistCollectionPendingType()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementUpdateStylistCollectionPendingType$Response(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementUpdateStylistCollectionPendingTypePath, 'post');
    if (params) {
      rb.query('id', params.id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementUpdateStylistCollectionPendingType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementUpdateStylistCollectionPendingType(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.stylistProfileManagementUpdateStylistCollectionPendingType$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation stylistProfileManagementGetAllStylistCollection
   */
  static readonly StylistProfileManagementGetAllStylistCollectionPath = '/api/customer/StylistProfileManagement/GetAllStylistCollection';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetAllStylistCollection$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllStylistCollection$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllStylistCollectionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementGetAllStylistCollectionPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllStylistCollectionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetAllStylistCollection$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllStylistCollection$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllStylistCollectionDto>> {

    return this.stylistProfileManagementGetAllStylistCollection$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllStylistCollectionDto>>) => r.body as Array<OutputGetAllStylistCollectionDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetAllStylistCollection()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllStylistCollection$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllStylistCollectionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementGetAllStylistCollectionPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllStylistCollectionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetAllStylistCollection$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllStylistCollection(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllStylistCollectionDto>> {

    return this.stylistProfileManagementGetAllStylistCollection$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllStylistCollectionDto>>) => r.body as Array<OutputGetAllStylistCollectionDto>)
    );
  }

  /**
   * Path part for operation stylistProfileManagementGetAllStylistPendingCollection
   */
  static readonly StylistProfileManagementGetAllStylistPendingCollectionPath = '/api/customer/StylistProfileManagement/GetAllStylistPendingCollection';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetAllStylistPendingCollection$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllStylistPendingCollection$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllStylistCollectionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementGetAllStylistPendingCollectionPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllStylistCollectionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetAllStylistPendingCollection$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllStylistPendingCollection$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllStylistCollectionDto>> {

    return this.stylistProfileManagementGetAllStylistPendingCollection$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllStylistCollectionDto>>) => r.body as Array<OutputGetAllStylistCollectionDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetAllStylistPendingCollection()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllStylistPendingCollection$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllStylistCollectionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementGetAllStylistPendingCollectionPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllStylistCollectionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetAllStylistPendingCollection$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllStylistPendingCollection(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllStylistCollectionDto>> {

    return this.stylistProfileManagementGetAllStylistPendingCollection$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllStylistCollectionDto>>) => r.body as Array<OutputGetAllStylistCollectionDto>)
    );
  }

  /**
   * Path part for operation stylistProfileManagementCreateStylistBankAccount
   */
  static readonly StylistProfileManagementCreateStylistBankAccountPath = '/api/customer/StylistProfileManagement/CreateStylistBankAccount';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementCreateStylistBankAccount$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementCreateStylistBankAccount$Plain$Response(params?: {
    context?: HttpContext
    body?: InputCreateStylistBankAccountDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementCreateStylistBankAccountPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementCreateStylistBankAccount$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementCreateStylistBankAccount$Plain(params?: {
    context?: HttpContext
    body?: InputCreateStylistBankAccountDto
  }
): Observable<boolean> {

    return this.stylistProfileManagementCreateStylistBankAccount$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementCreateStylistBankAccount()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementCreateStylistBankAccount$Response(params?: {
    context?: HttpContext
    body?: InputCreateStylistBankAccountDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementCreateStylistBankAccountPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementCreateStylistBankAccount$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementCreateStylistBankAccount(params?: {
    context?: HttpContext
    body?: InputCreateStylistBankAccountDto
  }
): Observable<boolean> {

    return this.stylistProfileManagementCreateStylistBankAccount$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation stylistProfileManagementGetStylistBankAccount
   */
  static readonly StylistProfileManagementGetStylistBankAccountPath = '/api/customer/StylistProfileManagement/GetStylistBankAccount';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetStylistBankAccount$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistBankAccount$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetStylistBankAccountDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementGetStylistBankAccountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetStylistBankAccountDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetStylistBankAccount$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistBankAccount$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetStylistBankAccountDto>> {

    return this.stylistProfileManagementGetStylistBankAccount$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetStylistBankAccountDto>>) => r.body as Array<OutputGetStylistBankAccountDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetStylistBankAccount()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistBankAccount$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetStylistBankAccountDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementGetStylistBankAccountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetStylistBankAccountDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetStylistBankAccount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistBankAccount(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetStylistBankAccountDto>> {

    return this.stylistProfileManagementGetStylistBankAccount$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetStylistBankAccountDto>>) => r.body as Array<OutputGetStylistBankAccountDto>)
    );
  }

  /**
   * Path part for operation stylistProfileManagementCreateStylistWiithdrawal
   */
  static readonly StylistProfileManagementCreateStylistWiithdrawalPath = '/api/customer/StylistProfileManagement/CreateStylistWiithdrawal';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementCreateStylistWiithdrawal$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementCreateStylistWiithdrawal$Plain$Response(params?: {
    context?: HttpContext
    body?: InputAddStylistWithdrawalRequestDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementCreateStylistWiithdrawalPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementCreateStylistWiithdrawal$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementCreateStylistWiithdrawal$Plain(params?: {
    context?: HttpContext
    body?: InputAddStylistWithdrawalRequestDto
  }
): Observable<boolean> {

    return this.stylistProfileManagementCreateStylistWiithdrawal$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementCreateStylistWiithdrawal()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementCreateStylistWiithdrawal$Response(params?: {
    context?: HttpContext
    body?: InputAddStylistWithdrawalRequestDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementCreateStylistWiithdrawalPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementCreateStylistWiithdrawal$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  stylistProfileManagementCreateStylistWiithdrawal(params?: {
    context?: HttpContext
    body?: InputAddStylistWithdrawalRequestDto
  }
): Observable<boolean> {

    return this.stylistProfileManagementCreateStylistWiithdrawal$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation stylistProfileManagementGetStylistDashboard
   */
  static readonly StylistProfileManagementGetStylistDashboardPath = '/api/customer/StylistProfileManagement/GetStylistDashboard';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetStylistDashboard$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistDashboard$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<StylistDashboardDto>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementGetStylistDashboardPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StylistDashboardDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetStylistDashboard$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistDashboard$Plain(params?: {
    context?: HttpContext
  }
): Observable<StylistDashboardDto> {

    return this.stylistProfileManagementGetStylistDashboard$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StylistDashboardDto>) => r.body as StylistDashboardDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetStylistDashboard()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistDashboard$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<StylistDashboardDto>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementGetStylistDashboardPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StylistDashboardDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetStylistDashboard$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistDashboard(params?: {
    context?: HttpContext
  }
): Observable<StylistDashboardDto> {

    return this.stylistProfileManagementGetStylistDashboard$Response(params).pipe(
      map((r: StrictHttpResponse<StylistDashboardDto>) => r.body as StylistDashboardDto)
    );
  }

  /**
   * Path part for operation stylistProfileManagementGetStylistEarning
   */
  static readonly StylistProfileManagementGetStylistEarningPath = '/api/customer/StylistProfileManagement/GetStylistEarning';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetStylistEarning$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistEarning$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputStylistEarning>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementGetStylistEarningPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputStylistEarning>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetStylistEarning$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistEarning$Plain(params?: {
    context?: HttpContext
  }
): Observable<OutputStylistEarning> {

    return this.stylistProfileManagementGetStylistEarning$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputStylistEarning>) => r.body as OutputStylistEarning)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetStylistEarning()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistEarning$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputStylistEarning>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementGetStylistEarningPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputStylistEarning>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetStylistEarning$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetStylistEarning(params?: {
    context?: HttpContext
  }
): Observable<OutputStylistEarning> {

    return this.stylistProfileManagementGetStylistEarning$Response(params).pipe(
      map((r: StrictHttpResponse<OutputStylistEarning>) => r.body as OutputStylistEarning)
    );
  }

  /**
   * Path part for operation stylistProfileManagementGetAllStylistWithdrawal
   */
  static readonly StylistProfileManagementGetAllStylistWithdrawalPath = '/api/customer/StylistProfileManagement/GetAllStylistWithdrawal';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetAllStylistWithdrawal$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllStylistWithdrawal$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<GetAllStylistWithdrawalDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementGetAllStylistWithdrawalPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetAllStylistWithdrawalDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetAllStylistWithdrawal$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllStylistWithdrawal$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<GetAllStylistWithdrawalDto>> {

    return this.stylistProfileManagementGetAllStylistWithdrawal$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GetAllStylistWithdrawalDto>>) => r.body as Array<GetAllStylistWithdrawalDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stylistProfileManagementGetAllStylistWithdrawal()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllStylistWithdrawal$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<GetAllStylistWithdrawalDto>>> {

    const rb = new RequestBuilder(this.rootUrl, StylistProfileManagementService.StylistProfileManagementGetAllStylistWithdrawalPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetAllStylistWithdrawalDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stylistProfileManagementGetAllStylistWithdrawal$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stylistProfileManagementGetAllStylistWithdrawal(params?: {
    context?: HttpContext
  }
): Observable<Array<GetAllStylistWithdrawalDto>> {

    return this.stylistProfileManagementGetAllStylistWithdrawal$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GetAllStylistWithdrawalDto>>) => r.body as Array<GetAllStylistWithdrawalDto>)
    );
  }

}
