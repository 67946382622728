/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateClientForAdmin } from '../models/create-client-for-admin';
import { CreateUserDto } from '../models/create-user-dto';
import { InputCreateCompanyDto } from '../models/input-create-company-dto';
import { InputCreateMerchantForAdminDto } from '../models/input-create-merchant-for-admin-dto';
import { InputCreateRoleDto } from '../models/input-create-role-dto';
import { InputDeleteCompanyDto } from '../models/input-delete-company-dto';
import { InputUpdateCompanyDto } from '../models/input-update-company-dto';
import { InputUpdateMerchantForAdminDto } from '../models/input-update-merchant-for-admin-dto';
import { InputUpdateRoleDto } from '../models/input-update-role-dto';
import { InputUpdateRoleWithPermissionsDto } from '../models/input-update-role-with-permissions-dto';
import { InputUserUpdatePassword } from '../models/input-user-update-password';
import { OutputAllClientsDto } from '../models/output-all-clients-dto';
import { OutputAllMenuPagesPermissionDto } from '../models/output-all-menu-pages-permission-dto';
import { OutputAllRolesDto } from '../models/output-all-roles-dto';
import { OutputAllUsersDto } from '../models/output-all-users-dto';
import { OutputGetAllCompanysDto } from '../models/output-get-all-companys-dto';
import { OutputGetAllMerchantDto } from '../models/output-get-all-merchant-dto';
import { OutputGetAllMerchantForAdmin } from '../models/output-get-all-merchant-for-admin';
import { UpdateClientForAdminDto } from '../models/update-client-for-admin-dto';
import { UpdateUserDto } from '../models/update-user-dto';

@Injectable({
  providedIn: 'root',
})
export class UserManagementService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation userManagementUpdatePassword
   */
  static readonly UserManagementUpdatePasswordPath = '/api/admin/UserManagement/UpdatePassword';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementUpdatePassword$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementUpdatePassword$Plain$Response(params?: {
    context?: HttpContext
    body?: InputUserUpdatePassword
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementUpdatePasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementUpdatePassword$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementUpdatePassword$Plain(params?: {
    context?: HttpContext
    body?: InputUserUpdatePassword
  }
): Observable<boolean> {

    return this.userManagementUpdatePassword$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementUpdatePassword()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementUpdatePassword$Response(params?: {
    context?: HttpContext
    body?: InputUserUpdatePassword
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementUpdatePasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementUpdatePassword$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementUpdatePassword(params?: {
    context?: HttpContext
    body?: InputUserUpdatePassword
  }
): Observable<boolean> {

    return this.userManagementUpdatePassword$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation userManagementCreateCompany
   */
  static readonly UserManagementCreateCompanyPath = '/api/admin/UserManagement/CreateCompany';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementCreateCompany$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementCreateCompany$Plain$Response(params?: {
    context?: HttpContext
    body?: InputCreateCompanyDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementCreateCompanyPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementCreateCompany$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementCreateCompany$Plain(params?: {
    context?: HttpContext
    body?: InputCreateCompanyDto
  }
): Observable<boolean> {

    return this.userManagementCreateCompany$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementCreateCompany()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementCreateCompany$Response(params?: {
    context?: HttpContext
    body?: InputCreateCompanyDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementCreateCompanyPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementCreateCompany$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementCreateCompany(params?: {
    context?: HttpContext
    body?: InputCreateCompanyDto
  }
): Observable<boolean> {

    return this.userManagementCreateCompany$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation userManagementDeleteCompany
   */
  static readonly UserManagementDeleteCompanyPath = '/api/admin/UserManagement/DeleteCompany';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementDeleteCompany$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementDeleteCompany$Plain$Response(params?: {
    context?: HttpContext
    body?: InputDeleteCompanyDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementDeleteCompanyPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementDeleteCompany$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementDeleteCompany$Plain(params?: {
    context?: HttpContext
    body?: InputDeleteCompanyDto
  }
): Observable<boolean> {

    return this.userManagementDeleteCompany$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementDeleteCompany()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementDeleteCompany$Response(params?: {
    context?: HttpContext
    body?: InputDeleteCompanyDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementDeleteCompanyPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementDeleteCompany$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementDeleteCompany(params?: {
    context?: HttpContext
    body?: InputDeleteCompanyDto
  }
): Observable<boolean> {

    return this.userManagementDeleteCompany$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation userManagementUpdateCompany
   */
  static readonly UserManagementUpdateCompanyPath = '/api/admin/UserManagement/UpdateCompany';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementUpdateCompany$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementUpdateCompany$Plain$Response(params?: {
    context?: HttpContext
    body?: InputUpdateCompanyDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementUpdateCompanyPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementUpdateCompany$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementUpdateCompany$Plain(params?: {
    context?: HttpContext
    body?: InputUpdateCompanyDto
  }
): Observable<boolean> {

    return this.userManagementUpdateCompany$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementUpdateCompany()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementUpdateCompany$Response(params?: {
    context?: HttpContext
    body?: InputUpdateCompanyDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementUpdateCompanyPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementUpdateCompany$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementUpdateCompany(params?: {
    context?: HttpContext
    body?: InputUpdateCompanyDto
  }
): Observable<boolean> {

    return this.userManagementUpdateCompany$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation userManagementGetAllCompany
   */
  static readonly UserManagementGetAllCompanyPath = '/api/admin/UserManagement/GetAllCompany';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementGetAllCompany$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllCompany$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllCompanysDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementGetAllCompanyPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllCompanysDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementGetAllCompany$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllCompany$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllCompanysDto>> {

    return this.userManagementGetAllCompany$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllCompanysDto>>) => r.body as Array<OutputGetAllCompanysDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementGetAllCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllCompany$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllCompanysDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementGetAllCompanyPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllCompanysDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementGetAllCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllCompany(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllCompanysDto>> {

    return this.userManagementGetAllCompany$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllCompanysDto>>) => r.body as Array<OutputGetAllCompanysDto>)
    );
  }

  /**
   * Path part for operation userManagementGetCompanyById
   */
  static readonly UserManagementGetCompanyByIdPath = '/api/admin/UserManagement/GetCompanyById';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementGetCompanyById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetCompanyById$Plain$Response(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetAllCompanysDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementGetCompanyByIdPath, 'get');
    if (params) {
      rb.query('Id', params.Id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetAllCompanysDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementGetCompanyById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetCompanyById$Plain(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<OutputGetAllCompanysDto> {

    return this.userManagementGetCompanyById$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetAllCompanysDto>) => r.body as OutputGetAllCompanysDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementGetCompanyById()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetCompanyById$Response(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetAllCompanysDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementGetCompanyByIdPath, 'get');
    if (params) {
      rb.query('Id', params.Id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetAllCompanysDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementGetCompanyById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetCompanyById(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<OutputGetAllCompanysDto> {

    return this.userManagementGetCompanyById$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetAllCompanysDto>) => r.body as OutputGetAllCompanysDto)
    );
  }

  /**
   * Path part for operation userManagementGetAllCompanyRequests
   */
  static readonly UserManagementGetAllCompanyRequestsPath = '/api/admin/UserManagement/GetAllCompanyRequests';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementGetAllCompanyRequests$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllCompanyRequests$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllCompanysDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementGetAllCompanyRequestsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllCompanysDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementGetAllCompanyRequests$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllCompanyRequests$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllCompanysDto>> {

    return this.userManagementGetAllCompanyRequests$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllCompanysDto>>) => r.body as Array<OutputGetAllCompanysDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementGetAllCompanyRequests()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllCompanyRequests$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllCompanysDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementGetAllCompanyRequestsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllCompanysDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementGetAllCompanyRequests$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllCompanyRequests(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllCompanysDto>> {

    return this.userManagementGetAllCompanyRequests$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllCompanysDto>>) => r.body as Array<OutputGetAllCompanysDto>)
    );
  }

  /**
   * Path part for operation userManagementGetAllRoles
   */
  static readonly UserManagementGetAllRolesPath = '/api/admin/UserManagement/GetAllRoles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementGetAllRoles$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllRoles$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputAllRolesDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementGetAllRolesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputAllRolesDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementGetAllRoles$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllRoles$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputAllRolesDto>> {

    return this.userManagementGetAllRoles$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputAllRolesDto>>) => r.body as Array<OutputAllRolesDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementGetAllRoles()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllRoles$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputAllRolesDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementGetAllRolesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputAllRolesDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementGetAllRoles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllRoles(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputAllRolesDto>> {

    return this.userManagementGetAllRoles$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputAllRolesDto>>) => r.body as Array<OutputAllRolesDto>)
    );
  }

  /**
   * Path part for operation userManagementCreateRole
   */
  static readonly UserManagementCreateRolePath = '/api/admin/UserManagement/CreateRole';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementCreateRole()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementCreateRole$Response(params?: {
    context?: HttpContext
    body?: InputCreateRoleDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementCreateRolePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementCreateRole$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementCreateRole(params?: {
    context?: HttpContext
    body?: InputCreateRoleDto
  }
): Observable<void> {

    return this.userManagementCreateRole$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation userManagementDeleteRole
   */
  static readonly UserManagementDeleteRolePath = '/api/admin/UserManagement/DeleteRole';

  /**
   * DeleteRole.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementDeleteRole$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementDeleteRole$Plain$Response(params?: {
    roleId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementDeleteRolePath, 'post');
    if (params) {
      rb.query('roleId', params.roleId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * DeleteRole.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementDeleteRole$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementDeleteRole$Plain(params?: {
    roleId?: number;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.userManagementDeleteRole$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * DeleteRole.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementDeleteRole()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementDeleteRole$Response(params?: {
    roleId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementDeleteRolePath, 'post');
    if (params) {
      rb.query('roleId', params.roleId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * DeleteRole.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementDeleteRole$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementDeleteRole(params?: {
    roleId?: number;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.userManagementDeleteRole$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation userManagementUpdateRole
   */
  static readonly UserManagementUpdateRolePath = '/api/admin/UserManagement/UpdateRole';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementUpdateRole()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementUpdateRole$Response(params?: {
    context?: HttpContext
    body?: InputUpdateRoleDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementUpdateRolePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementUpdateRole$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementUpdateRole(params?: {
    context?: HttpContext
    body?: InputUpdateRoleDto
  }
): Observable<void> {

    return this.userManagementUpdateRole$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation userManagementUpdateRoleWithPermissions
   */
  static readonly UserManagementUpdateRoleWithPermissionsPath = '/api/admin/UserManagement/UpdateRoleWithPermissions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementUpdateRoleWithPermissions()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementUpdateRoleWithPermissions$Response(params?: {
    context?: HttpContext
    body?: InputUpdateRoleWithPermissionsDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementUpdateRoleWithPermissionsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementUpdateRoleWithPermissions$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementUpdateRoleWithPermissions(params?: {
    context?: HttpContext
    body?: InputUpdateRoleWithPermissionsDto
  }
): Observable<void> {

    return this.userManagementUpdateRoleWithPermissions$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation userManagementGetAllClients
   */
  static readonly UserManagementGetAllClientsPath = '/api/admin/UserManagement/GetAllClients';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementGetAllClients$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllClients$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputAllClientsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementGetAllClientsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputAllClientsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementGetAllClients$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllClients$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputAllClientsDto>> {

    return this.userManagementGetAllClients$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputAllClientsDto>>) => r.body as Array<OutputAllClientsDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementGetAllClients()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllClients$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputAllClientsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementGetAllClientsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputAllClientsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementGetAllClients$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllClients(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputAllClientsDto>> {

    return this.userManagementGetAllClients$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputAllClientsDto>>) => r.body as Array<OutputAllClientsDto>)
    );
  }

  /**
   * Path part for operation userManagementCreateClient
   */
  static readonly UserManagementCreateClientPath = '/api/admin/UserManagement/CreateClient';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementCreateClient$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementCreateClient$Plain$Response(params?: {
    context?: HttpContext
    body?: CreateClientForAdmin
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementCreateClientPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementCreateClient$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementCreateClient$Plain(params?: {
    context?: HttpContext
    body?: CreateClientForAdmin
  }
): Observable<boolean> {

    return this.userManagementCreateClient$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementCreateClient()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementCreateClient$Response(params?: {
    context?: HttpContext
    body?: CreateClientForAdmin
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementCreateClientPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementCreateClient$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementCreateClient(params?: {
    context?: HttpContext
    body?: CreateClientForAdmin
  }
): Observable<boolean> {

    return this.userManagementCreateClient$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation userManagementDeleteClient
   */
  static readonly UserManagementDeleteClientPath = '/api/admin/UserManagement/DeleteClient';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementDeleteClient()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementDeleteClient$Response(params?: {
    ClientId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementDeleteClientPath, 'post');
    if (params) {
      rb.query('ClientId', params.ClientId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementDeleteClient$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementDeleteClient(params?: {
    ClientId?: number;
    context?: HttpContext
  }
): Observable<void> {

    return this.userManagementDeleteClient$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation userManagementUpdateClient
   */
  static readonly UserManagementUpdateClientPath = '/api/admin/UserManagement/UpdateClient';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementUpdateClient()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementUpdateClient$Response(params?: {
    context?: HttpContext
    body?: UpdateClientForAdminDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementUpdateClientPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementUpdateClient$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementUpdateClient(params?: {
    context?: HttpContext
    body?: UpdateClientForAdminDto
  }
): Observable<void> {

    return this.userManagementUpdateClient$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation userManagementGetAllUsers
   */
  static readonly UserManagementGetAllUsersPath = '/api/admin/UserManagement/GetAllUsers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementGetAllUsers$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllUsers$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputAllUsersDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementGetAllUsersPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputAllUsersDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementGetAllUsers$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllUsers$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputAllUsersDto>> {

    return this.userManagementGetAllUsers$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputAllUsersDto>>) => r.body as Array<OutputAllUsersDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementGetAllUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllUsers$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputAllUsersDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementGetAllUsersPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputAllUsersDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementGetAllUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllUsers(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputAllUsersDto>> {

    return this.userManagementGetAllUsers$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputAllUsersDto>>) => r.body as Array<OutputAllUsersDto>)
    );
  }

  /**
   * Path part for operation userManagementCreateUser
   */
  static readonly UserManagementCreateUserPath = '/api/admin/UserManagement/CreateUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementCreateUser()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementCreateUser$Response(params?: {
    context?: HttpContext
    body?: CreateUserDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementCreateUserPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementCreateUser$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementCreateUser(params?: {
    context?: HttpContext
    body?: CreateUserDto
  }
): Observable<void> {

    return this.userManagementCreateUser$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation userManagementDeleteUser
   */
  static readonly UserManagementDeleteUserPath = '/api/admin/UserManagement/DeleteUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementDeleteUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementDeleteUser$Response(params?: {
    userId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementDeleteUserPath, 'post');
    if (params) {
      rb.query('userId', params.userId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementDeleteUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementDeleteUser(params?: {
    userId?: number;
    context?: HttpContext
  }
): Observable<void> {

    return this.userManagementDeleteUser$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation userManagementUpdateUser
   */
  static readonly UserManagementUpdateUserPath = '/api/admin/UserManagement/UpdateUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementUpdateUser()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementUpdateUser$Response(params?: {
    context?: HttpContext
    body?: UpdateUserDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementUpdateUserPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementUpdateUser$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementUpdateUser(params?: {
    context?: HttpContext
    body?: UpdateUserDto
  }
): Observable<void> {

    return this.userManagementUpdateUser$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation userManagementGetMenusPagesPermissionByRoleId
   */
  static readonly UserManagementGetMenusPagesPermissionByRoleIdPath = '/api/admin/UserManagement/GetMenusPagesPermissionByRoleId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementGetMenusPagesPermissionByRoleId$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetMenusPagesPermissionByRoleId$Plain$Response(params?: {
    roleId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputAllMenuPagesPermissionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementGetMenusPagesPermissionByRoleIdPath, 'get');
    if (params) {
      rb.query('roleId', params.roleId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputAllMenuPagesPermissionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementGetMenusPagesPermissionByRoleId$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetMenusPagesPermissionByRoleId$Plain(params?: {
    roleId?: number;
    context?: HttpContext
  }
): Observable<Array<OutputAllMenuPagesPermissionDto>> {

    return this.userManagementGetMenusPagesPermissionByRoleId$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputAllMenuPagesPermissionDto>>) => r.body as Array<OutputAllMenuPagesPermissionDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementGetMenusPagesPermissionByRoleId()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetMenusPagesPermissionByRoleId$Response(params?: {
    roleId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputAllMenuPagesPermissionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementGetMenusPagesPermissionByRoleIdPath, 'get');
    if (params) {
      rb.query('roleId', params.roleId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputAllMenuPagesPermissionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementGetMenusPagesPermissionByRoleId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetMenusPagesPermissionByRoleId(params?: {
    roleId?: number;
    context?: HttpContext
  }
): Observable<Array<OutputAllMenuPagesPermissionDto>> {

    return this.userManagementGetMenusPagesPermissionByRoleId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputAllMenuPagesPermissionDto>>) => r.body as Array<OutputAllMenuPagesPermissionDto>)
    );
  }

  /**
   * Path part for operation userManagementGetMenusPagesPermissions
   */
  static readonly UserManagementGetMenusPagesPermissionsPath = '/api/admin/UserManagement/GetMenusPagesPermissions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementGetMenusPagesPermissions$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetMenusPagesPermissions$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputAllMenuPagesPermissionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementGetMenusPagesPermissionsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputAllMenuPagesPermissionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementGetMenusPagesPermissions$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetMenusPagesPermissions$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputAllMenuPagesPermissionDto>> {

    return this.userManagementGetMenusPagesPermissions$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputAllMenuPagesPermissionDto>>) => r.body as Array<OutputAllMenuPagesPermissionDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementGetMenusPagesPermissions()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetMenusPagesPermissions$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputAllMenuPagesPermissionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementGetMenusPagesPermissionsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputAllMenuPagesPermissionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementGetMenusPagesPermissions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetMenusPagesPermissions(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputAllMenuPagesPermissionDto>> {

    return this.userManagementGetMenusPagesPermissions$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputAllMenuPagesPermissionDto>>) => r.body as Array<OutputAllMenuPagesPermissionDto>)
    );
  }

  /**
   * Path part for operation userManagementCreateMerchantForAdmin
   */
  static readonly UserManagementCreateMerchantForAdminPath = '/api/admin/UserManagement/CreateMerchantForAdmin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementCreateMerchantForAdmin$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementCreateMerchantForAdmin$Plain$Response(params?: {
    context?: HttpContext
    body?: InputCreateMerchantForAdminDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementCreateMerchantForAdminPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementCreateMerchantForAdmin$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementCreateMerchantForAdmin$Plain(params?: {
    context?: HttpContext
    body?: InputCreateMerchantForAdminDto
  }
): Observable<boolean> {

    return this.userManagementCreateMerchantForAdmin$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementCreateMerchantForAdmin()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementCreateMerchantForAdmin$Response(params?: {
    context?: HttpContext
    body?: InputCreateMerchantForAdminDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementCreateMerchantForAdminPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementCreateMerchantForAdmin$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementCreateMerchantForAdmin(params?: {
    context?: HttpContext
    body?: InputCreateMerchantForAdminDto
  }
): Observable<boolean> {

    return this.userManagementCreateMerchantForAdmin$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation userManagementDeleteMerchantForAdmin
   */
  static readonly UserManagementDeleteMerchantForAdminPath = '/api/admin/UserManagement/DeleteMerchantForAdmin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementDeleteMerchantForAdmin$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementDeleteMerchantForAdmin$Plain$Response(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementDeleteMerchantForAdminPath, 'delete');
    if (params) {
      rb.query('Id', params.Id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementDeleteMerchantForAdmin$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementDeleteMerchantForAdmin$Plain(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.userManagementDeleteMerchantForAdmin$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementDeleteMerchantForAdmin()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementDeleteMerchantForAdmin$Response(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementDeleteMerchantForAdminPath, 'delete');
    if (params) {
      rb.query('Id', params.Id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementDeleteMerchantForAdmin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementDeleteMerchantForAdmin(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.userManagementDeleteMerchantForAdmin$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation userManagementUpdateMerchantForAdmin
   */
  static readonly UserManagementUpdateMerchantForAdminPath = '/api/admin/UserManagement/UpdateMerchantForAdmin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementUpdateMerchantForAdmin$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementUpdateMerchantForAdmin$Plain$Response(params?: {
    context?: HttpContext
    body?: InputUpdateMerchantForAdminDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementUpdateMerchantForAdminPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementUpdateMerchantForAdmin$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementUpdateMerchantForAdmin$Plain(params?: {
    context?: HttpContext
    body?: InputUpdateMerchantForAdminDto
  }
): Observable<boolean> {

    return this.userManagementUpdateMerchantForAdmin$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementUpdateMerchantForAdmin()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementUpdateMerchantForAdmin$Response(params?: {
    context?: HttpContext
    body?: InputUpdateMerchantForAdminDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementUpdateMerchantForAdminPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementUpdateMerchantForAdmin$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userManagementUpdateMerchantForAdmin(params?: {
    context?: HttpContext
    body?: InputUpdateMerchantForAdminDto
  }
): Observable<boolean> {

    return this.userManagementUpdateMerchantForAdmin$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation userManagementGetAllMerchantForAdmin
   */
  static readonly UserManagementGetAllMerchantForAdminPath = '/api/admin/UserManagement/GetAllMerchantForAdmin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementGetAllMerchantForAdmin$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllMerchantForAdmin$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllMerchantForAdmin>>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementGetAllMerchantForAdminPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllMerchantForAdmin>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementGetAllMerchantForAdmin$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllMerchantForAdmin$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllMerchantForAdmin>> {

    return this.userManagementGetAllMerchantForAdmin$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllMerchantForAdmin>>) => r.body as Array<OutputGetAllMerchantForAdmin>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementGetAllMerchantForAdmin()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllMerchantForAdmin$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllMerchantForAdmin>>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementGetAllMerchantForAdminPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllMerchantForAdmin>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementGetAllMerchantForAdmin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllMerchantForAdmin(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllMerchantForAdmin>> {

    return this.userManagementGetAllMerchantForAdmin$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllMerchantForAdmin>>) => r.body as Array<OutputGetAllMerchantForAdmin>)
    );
  }

  /**
   * Path part for operation userManagementGetAllMerchantForDropDown
   */
  static readonly UserManagementGetAllMerchantForDropDownPath = '/api/admin/UserManagement/GetAllMerchantForDropDown';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementGetAllMerchantForDropDown$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllMerchantForDropDown$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllMerchantDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementGetAllMerchantForDropDownPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllMerchantDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementGetAllMerchantForDropDown$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllMerchantForDropDown$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllMerchantDto>> {

    return this.userManagementGetAllMerchantForDropDown$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllMerchantDto>>) => r.body as Array<OutputGetAllMerchantDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementGetAllMerchantForDropDown()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllMerchantForDropDown$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllMerchantDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementGetAllMerchantForDropDownPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllMerchantDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementGetAllMerchantForDropDown$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementGetAllMerchantForDropDown(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllMerchantDto>> {

    return this.userManagementGetAllMerchantForDropDown$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllMerchantDto>>) => r.body as Array<OutputGetAllMerchantDto>)
    );
  }

  /**
   * Path part for operation userManagementAcceptMerchantCompany
   */
  static readonly UserManagementAcceptMerchantCompanyPath = '/api/admin/UserManagement/AcceptMerchantCompany';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementAcceptMerchantCompany$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementAcceptMerchantCompany$Plain$Response(params?: {
    CompanyId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementAcceptMerchantCompanyPath, 'post');
    if (params) {
      rb.query('CompanyId', params.CompanyId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementAcceptMerchantCompany$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementAcceptMerchantCompany$Plain(params?: {
    CompanyId?: number;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.userManagementAcceptMerchantCompany$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementAcceptMerchantCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementAcceptMerchantCompany$Response(params?: {
    CompanyId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementAcceptMerchantCompanyPath, 'post');
    if (params) {
      rb.query('CompanyId', params.CompanyId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementAcceptMerchantCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementAcceptMerchantCompany(params?: {
    CompanyId?: number;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.userManagementAcceptMerchantCompany$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation userManagementRejectMerchantCompany
   */
  static readonly UserManagementRejectMerchantCompanyPath = '/api/admin/UserManagement/RejectMerchantCompany';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementRejectMerchantCompany$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementRejectMerchantCompany$Plain$Response(params?: {
    CompanyId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementRejectMerchantCompanyPath, 'post');
    if (params) {
      rb.query('CompanyId', params.CompanyId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementRejectMerchantCompany$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementRejectMerchantCompany$Plain(params?: {
    CompanyId?: number;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.userManagementRejectMerchantCompany$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userManagementRejectMerchantCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementRejectMerchantCompany$Response(params?: {
    CompanyId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.UserManagementRejectMerchantCompanyPath, 'post');
    if (params) {
      rb.query('CompanyId', params.CompanyId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userManagementRejectMerchantCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userManagementRejectMerchantCompany(params?: {
    CompanyId?: number;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.userManagementRejectMerchantCompany$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
