/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { InputForgetPasswordDto } from '../models/input-forget-password-dto';
import { InputRefreshTokenDto } from '../models/input-refresh-token-dto';
import { InputResetPasswordDto } from '../models/input-reset-password-dto';
import { InputSignInUserDto } from '../models/input-sign-in-user-dto';
import { InputVerficationPasswordDto } from '../models/input-verfication-password-dto';
import { OutputForgetPasswordDto } from '../models/output-forget-password-dto';
import { OutputPagePermissionDto } from '../models/output-page-permission-dto';
import { OutputRefreshTokenDto } from '../models/output-refresh-token-dto';
import { OutputSignInUserDto } from '../models/output-sign-in-user-dto';
import { OutputVerificationPasswordDto } from '../models/output-verification-password-dto';

@Injectable({
  providedIn: 'root',
})
export class UserAccountService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation userAccountSignIn
   */
  static readonly UserAccountSignInPath = '/api/admin/UserAccount/SignIn';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userAccountSignIn$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userAccountSignIn$Plain$Response(params?: {
    context?: HttpContext
    body?: InputSignInUserDto
  }
): Observable<StrictHttpResponse<OutputSignInUserDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserAccountService.UserAccountSignInPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputSignInUserDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userAccountSignIn$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userAccountSignIn$Plain(params?: {
    context?: HttpContext
    body?: InputSignInUserDto
  }
): Observable<OutputSignInUserDto> {

    return this.userAccountSignIn$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputSignInUserDto>) => r.body as OutputSignInUserDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userAccountSignIn()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userAccountSignIn$Response(params?: {
    context?: HttpContext
    body?: InputSignInUserDto
  }
): Observable<StrictHttpResponse<OutputSignInUserDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserAccountService.UserAccountSignInPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputSignInUserDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userAccountSignIn$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userAccountSignIn(params?: {
    context?: HttpContext
    body?: InputSignInUserDto
  }
): Observable<OutputSignInUserDto> {

    return this.userAccountSignIn$Response(params).pipe(
      map((r: StrictHttpResponse<OutputSignInUserDto>) => r.body as OutputSignInUserDto)
    );
  }

  /**
   * Path part for operation userAccountToken
   */
  static readonly UserAccountTokenPath = '/api/admin/UserAccount/Token';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userAccountToken$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userAccountToken$Plain$Response(params?: {
    context?: HttpContext
    body?: InputRefreshTokenDto
  }
): Observable<StrictHttpResponse<OutputRefreshTokenDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserAccountService.UserAccountTokenPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputRefreshTokenDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userAccountToken$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userAccountToken$Plain(params?: {
    context?: HttpContext
    body?: InputRefreshTokenDto
  }
): Observable<OutputRefreshTokenDto> {

    return this.userAccountToken$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputRefreshTokenDto>) => r.body as OutputRefreshTokenDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userAccountToken()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userAccountToken$Response(params?: {
    context?: HttpContext
    body?: InputRefreshTokenDto
  }
): Observable<StrictHttpResponse<OutputRefreshTokenDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserAccountService.UserAccountTokenPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputRefreshTokenDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userAccountToken$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userAccountToken(params?: {
    context?: HttpContext
    body?: InputRefreshTokenDto
  }
): Observable<OutputRefreshTokenDto> {

    return this.userAccountToken$Response(params).pipe(
      map((r: StrictHttpResponse<OutputRefreshTokenDto>) => r.body as OutputRefreshTokenDto)
    );
  }

  /**
   * Path part for operation userAccountForgetPassword
   */
  static readonly UserAccountForgetPasswordPath = '/api/admin/UserAccount/ForgetPassword';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userAccountForgetPassword$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userAccountForgetPassword$Plain$Response(params?: {
    context?: HttpContext
    body?: InputForgetPasswordDto
  }
): Observable<StrictHttpResponse<OutputForgetPasswordDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserAccountService.UserAccountForgetPasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputForgetPasswordDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userAccountForgetPassword$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userAccountForgetPassword$Plain(params?: {
    context?: HttpContext
    body?: InputForgetPasswordDto
  }
): Observable<OutputForgetPasswordDto> {

    return this.userAccountForgetPassword$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputForgetPasswordDto>) => r.body as OutputForgetPasswordDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userAccountForgetPassword()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userAccountForgetPassword$Response(params?: {
    context?: HttpContext
    body?: InputForgetPasswordDto
  }
): Observable<StrictHttpResponse<OutputForgetPasswordDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserAccountService.UserAccountForgetPasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputForgetPasswordDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userAccountForgetPassword$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userAccountForgetPassword(params?: {
    context?: HttpContext
    body?: InputForgetPasswordDto
  }
): Observable<OutputForgetPasswordDto> {

    return this.userAccountForgetPassword$Response(params).pipe(
      map((r: StrictHttpResponse<OutputForgetPasswordDto>) => r.body as OutputForgetPasswordDto)
    );
  }

  /**
   * Path part for operation userAccountVerificationPinForgetPassword
   */
  static readonly UserAccountVerificationPinForgetPasswordPath = '/api/admin/UserAccount/VerificationPinForgetPassword';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userAccountVerificationPinForgetPassword$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userAccountVerificationPinForgetPassword$Plain$Response(params?: {
    context?: HttpContext
    body?: InputVerficationPasswordDto
  }
): Observable<StrictHttpResponse<OutputVerificationPasswordDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserAccountService.UserAccountVerificationPinForgetPasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputVerificationPasswordDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userAccountVerificationPinForgetPassword$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userAccountVerificationPinForgetPassword$Plain(params?: {
    context?: HttpContext
    body?: InputVerficationPasswordDto
  }
): Observable<OutputVerificationPasswordDto> {

    return this.userAccountVerificationPinForgetPassword$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputVerificationPasswordDto>) => r.body as OutputVerificationPasswordDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userAccountVerificationPinForgetPassword()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userAccountVerificationPinForgetPassword$Response(params?: {
    context?: HttpContext
    body?: InputVerficationPasswordDto
  }
): Observable<StrictHttpResponse<OutputVerificationPasswordDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserAccountService.UserAccountVerificationPinForgetPasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputVerificationPasswordDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userAccountVerificationPinForgetPassword$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userAccountVerificationPinForgetPassword(params?: {
    context?: HttpContext
    body?: InputVerficationPasswordDto
  }
): Observable<OutputVerificationPasswordDto> {

    return this.userAccountVerificationPinForgetPassword$Response(params).pipe(
      map((r: StrictHttpResponse<OutputVerificationPasswordDto>) => r.body as OutputVerificationPasswordDto)
    );
  }

  /**
   * Path part for operation userAccountResetPassword
   */
  static readonly UserAccountResetPasswordPath = '/api/admin/UserAccount/ResetPassword';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userAccountResetPassword$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userAccountResetPassword$Plain$Response(params?: {
    context?: HttpContext
    body?: InputResetPasswordDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserAccountService.UserAccountResetPasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userAccountResetPassword$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userAccountResetPassword$Plain(params?: {
    context?: HttpContext
    body?: InputResetPasswordDto
  }
): Observable<boolean> {

    return this.userAccountResetPassword$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userAccountResetPassword()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userAccountResetPassword$Response(params?: {
    context?: HttpContext
    body?: InputResetPasswordDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserAccountService.UserAccountResetPasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userAccountResetPassword$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userAccountResetPassword(params?: {
    context?: HttpContext
    body?: InputResetPasswordDto
  }
): Observable<boolean> {

    return this.userAccountResetPassword$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation userAccountGetPagePermission
   */
  static readonly UserAccountGetPagePermissionPath = '/api/admin/UserAccount/GetPagePermission';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userAccountGetPagePermission$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userAccountGetPagePermission$Plain$Response(params?: {
    pageId?: number;
    RoleId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputPagePermissionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserAccountService.UserAccountGetPagePermissionPath, 'post');
    if (params) {
      rb.query('pageId', params.pageId, {"style":"form"});
      rb.query('RoleId', params.RoleId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputPagePermissionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userAccountGetPagePermission$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userAccountGetPagePermission$Plain(params?: {
    pageId?: number;
    RoleId?: number;
    context?: HttpContext
  }
): Observable<Array<OutputPagePermissionDto>> {

    return this.userAccountGetPagePermission$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputPagePermissionDto>>) => r.body as Array<OutputPagePermissionDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userAccountGetPagePermission()` instead.
   *
   * This method doesn't expect any request body.
   */
  userAccountGetPagePermission$Response(params?: {
    pageId?: number;
    RoleId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputPagePermissionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserAccountService.UserAccountGetPagePermissionPath, 'post');
    if (params) {
      rb.query('pageId', params.pageId, {"style":"form"});
      rb.query('RoleId', params.RoleId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputPagePermissionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userAccountGetPagePermission$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userAccountGetPagePermission(params?: {
    pageId?: number;
    RoleId?: number;
    context?: HttpContext
  }
): Observable<Array<OutputPagePermissionDto>> {

    return this.userAccountGetPagePermission$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputPagePermissionDto>>) => r.body as Array<OutputPagePermissionDto>)
    );
  }

}
